import { Service } from "../service/service";
import { ClientsListStore } from "./organisation/ClientsListStore";
import { PlacementStore } from "./PlacementStore";
import { PublishersListStore } from "./PublishersListStore";
import { UiStore } from "./ui/UiStore";

export class RootStore {
  publishersListStore: PublishersListStore;
  placementStore: PlacementStore;
  uiStore: UiStore;
  clientsListStore: ClientsListStore;

  constructor(readonly service: Service) {
    this.publishersListStore = new PublishersListStore(service);
    this.placementStore = new PlacementStore(service);
    this.uiStore = new UiStore(service);

    this.clientsListStore = new ClientsListStore(service);
  }
}
