import React, { forwardRef } from "react";
import {
  SnackbarContent,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar,
} from "notistack";
import { ThemeProvider } from "@mui/material";
import { theme as newTheme } from "src/ui/common/NewTheme";
import { SnackbarBlock, Severity } from "./SnackbarBlock";

interface SnackbarProps {
  id: SnackbarKey;
  message: SnackbarMessage;
  action?: JSX.Element;
  severity?: Severity;
  closable?: boolean;
}

export const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>(
  (props, ref) => {
    const { closeSnackbar } = useSnackbar();
    const onClose =
      props.closable === false ? undefined : () => closeSnackbar(props.id);

    return (
      <SnackbarContent ref={ref}>
        <ThemeProvider theme={newTheme}>
          <SnackbarBlock
            message={props.message}
            action={props.action}
            onClose={onClose}
            severity={props.severity}
          />
        </ThemeProvider>
      </SnackbarContent>
    );
  }
);
