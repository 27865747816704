import { action, makeObservable, observable } from "mobx";

import { Publisher } from "../adl-gen/kachemedia/specsheet/db";
import { Service } from "../service/service";
import { WithDbId } from "../adl-gen/common/db";
import { fetchPublishersByTerm } from "../service/publishers/fetchPublishersByTerm";
import { idFlavor } from "@specsheet-common/shared-types";

export class PublishersListStore {
  isInitiated: boolean = false;
  isLoading: boolean = false;
  publishersList: WithDbId<Publisher>[] = [];
  lastFetchDate: Date = new Date();

  constructor(private readonly service: Service) {
    this.service = service;

    makeObservable(this, {
      publishersList: observable,
      isInitiated: observable,
      isLoading: observable,
      lastFetchDate: observable,
      setPublishersList: action,
      setIsLoading: action,
      fetch: action,
      setCurrentLastFetchDate: action,
    });
  }

  setCurrentLastFetchDate = (): Date => {
    const newDate = new Date();
    this.lastFetchDate = newDate;
    return newDate;
  };

  setIsLoading = (status: boolean) => {
    this.isLoading = status;
  };

  setPublishersList = (publishersList: WithDbId<Publisher>[]) => {
    this.publishersList = publishersList;
  };

  fetch = async ({
    searchTerm,
    withSiteIdPlacementsOnly,
    withPlacementsOnly = true,
    organisationId,
  }: {
    searchTerm?: string;
    withSiteIdPlacementsOnly?: boolean;
    withPlacementsOnly?: boolean;
    organisationId: idFlavor<"Organisation">;
  }): Promise<void> => {
    this.isInitiated = true;

    const startDate = this.setCurrentLastFetchDate();
    this.isLoading = true;

    const data = await fetchPublishersByTerm({
      service: this.service,
      term: searchTerm || "",
      withPlacementsOnly,
      withSiteIdPlacementsOnly: Boolean(withSiteIdPlacementsOnly),
      organisationId,
    });

    if (this.lastFetchDate.getTime() > startDate.getTime()) {
      return;
    }

    this.setPublishersList(data);
    this.isLoading = false;
  };
}
