import { useContext } from "react";
import { OnboardingContext } from "../../contexts/OnboardingContext";

export const useOnboarding = () => {
  const context = useContext(OnboardingContext);

  if (!context) {
    throw new Error("No Onboarding context provided");
  }

  return context;
};
