const fontFamily = [
  "Inter",
  "Helvetica Neue",
  "Helvetica",
  "Arial",
  "system",
  "sans-serif",
].join(",");

const fonts = {
  fontStyle: {
    fontWeightBold: 600,
    fontWeightNormal: 400,
  },
  sizes: {
    microFontSize: 8,
    tinyFontSize: 10,
    smallFontSize: 12,
    baseFontSize: 14,
    mediumFontSize: 16,
    largeFontSize: 20,
    xLargeFontSize: 24,
    xxLargeFontSize: 32,
    xxxLargeFontSize: 40,
    vastFontSize: 48,
    hugeFontSize: 56,
  },
} as const;

export const typography = {
  fontWeightBold: 600,
  fontWeightRegular: 400,
  fontFamily,
  baseBold: {
    fontFamily,
    fontSize: fonts.sizes.baseFontSize,
    fontWeight: fonts.fontStyle.fontWeightBold,
    lineHeight: 1.33,
  },
  h1: {
    fontSize: fonts.sizes.hugeFontSize,
    fontWeight: fonts.fontStyle.fontWeightBold,
  },
  extraLargeIcon: {
    fontSize: fonts.sizes.vastFontSize,
    fontWeight: fonts.fontStyle.fontWeightBold,
  },
  h2: {
    fontSize: fonts.sizes.xxxLargeFontSize,
    fontWeight: fonts.fontStyle.fontWeightBold,
  },
  h3: {
    fontSize: fonts.sizes.xxLargeFontSize,
    fontWeight: fonts.fontStyle.fontWeightBold,
    lineHeight: 1.5,
  },
  h4: {
    fontSize: fonts.sizes.xLargeFontSize,
    fontWeight: fonts.fontStyle.fontWeightBold,
  },
  h5: {
    fontSize: fonts.sizes.largeFontSize,
    fontWeight: fonts.fontStyle.fontWeightBold,
    lineHeight: 1.6,
  },
  h6: {
    fontSize: 18,
    fontWeight: fonts.fontStyle.fontWeightBold,
  },
  subtitle1: {
    fontFamily,
    fontSize: fonts.sizes.mediumFontSize,
    fontWeight: fonts.fontStyle.fontWeightBold,
    lineHeight: 1.5,
  },
  subtitle2: {
    fontFamily,
    fontSize: fonts.sizes.baseFontSize,
    fontWeight: fonts.fontStyle.fontWeightBold,
    lineHeight: 1.71,
  },
  subtitle3: {
    fontFamily,
    fontSize: fonts.sizes.smallFontSize,
    fontWeight: fonts.fontStyle.fontWeightBold,
    lineHeight: 1.33,
  },
  subtitle4: {
    fontFamily,
    fontSize: fonts.sizes.microFontSize,
    fontWeight: fonts.fontStyle.fontWeightBold,
    lineHeight: 1.33,
  },
  body1: {
    fontFamily,
    fontSize: fonts.sizes.mediumFontSize,
    fontWeight: fonts.fontStyle.fontWeightNormal,
  },
  body2: {
    fontFamily,
    fontSize: fonts.sizes.baseFontSize,
    fontWeight: fonts.fontStyle.fontWeightNormal,
    lineHeight: 1.71,
  },
  body3: {
    fontFamily,
    fontSize: fonts.sizes.smallFontSize,
    fontWeight: fonts.fontStyle.fontWeightNormal,
    lineHeight: 1.33,
  },
};
