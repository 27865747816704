import React, { useCallback } from "react";
import { Editor, Element as SlateElement, Transforms } from "slate";
import { BlockFormats, MarkFormats } from "./types";

interface WysiwygProps {
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  editor: Editor;
}

interface UseWysiwyg {
  onKeyDown: React.KeyboardEventHandler<HTMLDivElement>;
  toggleMark(format: MarkFormats): void;
  toggleBlock(format: BlockFormats): void;
  isMarkActive(format: MarkFormats): boolean;
  isBlockActive(format: BlockFormats): boolean;
}

const LIST_TYPES = new Set(["numberedList", "bulletedList"]);

export const useWysiwygEditor = (props: WysiwygProps): UseWysiwyg => {
  const { editor } = props;

  const isMarkActive = useCallback(
    (format: MarkFormats) => {
      const marks = Editor.marks(editor);
      return marks ? marks[format] === true : false;
    },
    [editor]
  );

  const isBlockActive = useCallback(
    (format: BlockFormats) => {
      const { selection } = editor;

      if (!selection) {
        return false;
      }

      const [match] = Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) =>
          !Editor.isEditor(n) && SlateElement.isElement(n) && n.kind === format,
      });

      return Boolean(match);
    },
    [editor]
  );

  const toggleMark = useCallback(
    (format: MarkFormats) => {
      const isActive = isMarkActive(format);

      if (isActive) {
        Editor.removeMark(editor, format);
      } else {
        Editor.addMark(editor, format, true);
      }
    },
    [editor, isMarkActive]
  );

  const toggleBlock = useCallback(
    (format: BlockFormats) => {
      const isActive = isBlockActive(format);
      const isList = LIST_TYPES.has(format);

      Transforms.unwrapNodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          LIST_TYPES.has(n.kind),
        split: true,
      });

      const newProperties: Partial<SlateElement> = {
        // eslint-disable-next-line no-nested-ternary
        kind: isActive ? "paragraph" : isList ? "listItem" : format,
      };

      Transforms.setNodes(editor, newProperties);

      if (!isActive && isList) {
        const block = { kind: format, children: [] };
        Transforms.wrapNodes(editor, block);
      }
    },
    [editor, isBlockActive]
  );

  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (props.onKeyDown) {
        props.onKeyDown(event);
      }

      if (event.ctrlKey || event.metaKey) {
        switch (event.key) {
          case "b": {
            return toggleMark("bold");
          }
          case "i": {
            return toggleMark("italic");
          }
          case "u": {
            return toggleMark("underline");
          }
          default: {
            return;
          }
        }
      }
    },
    [toggleMark, props.onKeyDown]
  );

  return {
    toggleMark,
    toggleBlock,
    isMarkActive,
    isBlockActive,
    onKeyDown,
  };
};
