import { Box, Drawer } from "@mui/material";
import { Observer } from "mobx-react";
import React from "react";

import { theme } from "../common/Theme";
import { SpecsheetMenu } from "../components/Menu/SpecsheetMenu";
import { useSidePanelContext } from "../../hooks/contexts/useSidePanelContext";

type PortalBackgroundColor = "white" | "lightgray";

interface PortalPageStyleProps {
  body?: {
    padding?: string | number;
    hideOverflowY?: boolean;
    hideOverflowX?: boolean;
    maxWidth?: number | "none";
  };
}

/** Props for the portal page layout */
interface PortalPageLayoutProps {
  children?: React.ReactNode;
  backgroundColor?: PortalBackgroundColor;
  styles?: PortalPageStyleProps;
}

export const PortalPageLayoutBasic: React.FC<PortalPageLayoutProps> = (
  props
) => {
  const { isSidePanelExpanded, onSidePanelExpandedChange } =
    useSidePanelContext();

  const toggleExpanded = () => {
    onSidePanelExpandedChange(!isSidePanelExpanded);
  };

  const getBackgroundColor = () => {
    switch (props.backgroundColor) {
      case "lightgray": {
        return "colorLightGray";
      }
      default: {
        return "colorWhite";
      }
    }
  };

  return (
    <Observer>
      {() => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            backgroundColor: theme.colors[getBackgroundColor()],
          }}
        >
          <Drawer
            sx={{ width: isSidePanelExpanded ? "256px" : "64px" }}
            variant="permanent"
            anchor="left"
          >
            <SpecsheetMenu
              isExpanded={isSidePanelExpanded}
              expanderFunction={toggleExpanded}
            />
          </Drawer>

          <Box
            sx={{
              display: "flex",
              // eslint-disable-next-line no-nested-ternary
              width: isSidePanelExpanded
                ? "calc(100% - 256px)"
                : "calc(100% - 64px)",
              flexDirection: "column",
              flex: "1 1 auto",
              overflow: "hidden",
              padding: props.styles?.body?.padding || 2,
              margin: "0 auto",
              // eslint-disable-next-line no-nested-ternary
              marginLeft: isSidePanelExpanded ? "256px" : "64px",

              overflowY: props.styles?.body?.hideOverflowY ? "hidden" : "auto",
              overflowX: props.styles?.body?.hideOverflowX ? "hidden" : "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignSelf: "center",
                flex: "1 1 auto",
                height: "100%",
                overflowY: props.styles?.body?.hideOverflowY
                  ? "hidden"
                  : undefined,
                overflowX: props.styles?.body?.hideOverflowX
                  ? "hidden"
                  : undefined,
                width: "100%",
                maxWidth:
                  props.styles?.body?.maxWidth ||
                  theme.metrics.globals.page.maxPageWidth,
              }}
            >
              {props.children}
            </Box>
          </Box>
        </Box>
      )}
    </Observer>
  );
};
