import { UseQueryOptions } from "react-query";
import {
  coreRoutes,
  idFlavor,
  OrganisationWorkspacesWithStatReq,
  urlBuilder,
  workspaceSubRoute,
  WorkspaceViewWithStatsRes,
} from "@specsheet-common/shared-types";
import { queryKeys } from "src/service/queryHooks/keys";
import { useAuth } from "src/hooks/contexts/useAuth";
import { useQuery } from "src/service/reactQuery";

interface HookProps {
  organisationId: idFlavor<"Organisation">;
  includeArchived: boolean;
  calculateStats: boolean;
  options?: {
    enabled?: UseQueryOptions["enabled"];
    staleTime?: UseQueryOptions["staleTime"];
  };
}

export const useQueryWorkspaces = ({
  organisationId,
  options,
  includeArchived,
  calculateStats,
}: HookProps) => {
  const { isAuthenticated } = useAuth();
  // if options.enabled is not provided, it shsould default to true.
  // Logged out state should overwrite this default.
  const enabled = (options?.enabled ?? true) && isAuthenticated;

  return useQuery<OrganisationWorkspacesWithStatReq, WorkspaceViewWithStatsRes>(
    {
      queryKey: queryKeys.workspaces.fetchWorkspaces({
        organisationId,
        includeArchived,
        calculateStats,
      }),
      url: urlBuilder({
        root: coreRoutes.workspaces,
        sub: workspaceSubRoute.list,
      }),
      method: "POST",
      data: { organisationId, includeArchived, calculateStats },
      responseFormatter(
        data: WorkspaceViewWithStatsRes
      ): WorkspaceViewWithStatsRes {
        return data.sort((a, b) => {
          const lowA = a.workspaceAccess.workspace.name.toLowerCase();
          const lowB = b.workspaceAccess.workspace.name.toLowerCase();
          return lowA.localeCompare(lowB);
        });
      },
      ...options,
      enabled,
    }
  );
};
