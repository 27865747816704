import { Components, Theme } from "@mui/material";

export const MuiMenuItem: Components<Theme>["MuiMenuItem"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ":hover": {
        backgroundColor: theme.newColors.neutral.gray100,
      },
      color: theme.newColors.neutral.primary,
    }),
  },
};
