import React from "react";
import { CustomText } from "../types";

interface LeafProps {
  attributes: unknown;
  leaf: CustomText;
  children: unknown;
}

export const Leaf: React.FC<LeafProps> = ({ attributes, children, leaf }) => {
  let childToRender = children;

  if (leaf.bold) {
    childToRender = <strong>{childToRender}</strong>;
  }

  if (leaf.italic) {
    childToRender = <em css={{ fontStyle: "italic" }}>{childToRender}</em>;
  }

  if (leaf.underline) {
    childToRender = (
      <u css={{ textDecoration: "underline" }}>{childToRender}</u>
    );
  }

  // @ts-expect-error after ts update
  return <span {...attributes}>{childToRender}</span>;
};
