import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { DbKey } from "../../../../../adl-gen/common/db";
import { Organisation } from "../../../../../adl-gen/kachemedia/specsheet/db";
import { useUpdateWorkspace } from "../../../../../service/queryHooks/workspaces/mutations/useUpdateWorkspace";
import { useMaybeNewFileOrClear } from "src/ui/components/shared/helpers/useMaybeNewFileOrClear";
import { LoadingButton } from "src/ui/components/shared/molecules/buttons/LoadingButton";
import { FormImgUploadControl } from "src/ui/components/shared/molecules/hook-form-components/FormImgUploadControl";
import { FormTextField } from "src/ui/components/shared/molecules/hook-form-components/FormTextField";
import { DialogTitleStyled } from "src/ui/components/shared/mui-styled/Dialog";
import { workspaceSchema, WorkspaceSchema } from "./CreateWorkplaceForm";
import { WorkspaceView } from "../../../../../adl-gen/kachemedia/specsheet/users";

interface EditWorkplaceFormProps {
  workspace: WorkspaceView;
  onClose(): void;
  organisationId: DbKey<Organisation>;
  variant: "default" | "newDashboard";
}

export const EditWorkplaceForm = ({
  workspace,
  onClose,
  organisationId,
  variant = "default",
}: EditWorkplaceFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<WorkspaceSchema>({
    mode: "all",
    defaultValues: { name: workspace.name, logoFile: null },
    resolver: zodResolver(workspaceSchema),
  });

  const newFileOrClear = useMaybeNewFileOrClear(
    `workspace-logo-${organisationId}`
  );

  const mutation = useUpdateWorkspace({
    organisationId,
    onSuccess: onClose,
  });

  const onSubmit: SubmitHandler<WorkspaceSchema> = async (fieldValues) => {
    const maybeNewLogo =
      workspace.logo && !fieldValues.logoFile
        ? undefined
        : await newFileOrClear(fieldValues.logoFile);

    await mutation.mutateAsync({
      workspaceId: workspace.workspaceId,
      name: fieldValues.name,
      logo: maybeNewLogo?.file,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {variant === "newDashboard" ? (
        <>
          <DialogTitleStyled onClose={onClose} title="Edit Workspace" />
          <DialogContent dividers>
            <Grid container spacing={8}>
              <Grid item xs pt={1}>
                <Stack spacing={2}>
                  <Typography variant="body2">Workspace Name</Typography>
                  <FormTextField
                    fullWidth
                    name="name"
                    placeholder="Workspace Name"
                    control={control}
                  />
                </Stack>
              </Grid>
              <Grid item>
                <Stack spacing={2}>
                  <Typography variant="body2">Image</Typography>
                  <FormImgUploadControl
                    name="logoFile"
                    control={control}
                    currentSrc={workspace.logo}
                    variant={variant}
                  />
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Button
              onClick={onClose}
              variant="outlined"
              sx={(theme) => ({
                color: theme.newColors.neutral.primary,
                borderColor: theme.newColors.neutral.gray200,
                width: 160,
                height: 32,
              })}
            >
              <Typography variant="subtitle2">Cancel</Typography>
            </Button>
            <LoadingButton
              isLoading={isSubmitting}
              type="submit"
              variant="contained"
              sx={{
                width: 160,
                height: 32,
              }}
            >
              <Typography variant="subtitle2">Save</Typography>
            </LoadingButton>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitleStyled onClose={onClose} title="Edit Workspace" />
          <DialogContent dividers>
            <Grid container>
              <Grid item width={190}>
                <FormImgUploadControl
                  name="logoFile"
                  control={control}
                  currentSrc={workspace.logo}
                />
              </Grid>
              <Grid item xs pt={1}>
                <Stack spacing={1.5}>
                  <FormTextField
                    fullWidth
                    name="name"
                    label="Workspace Name *"
                    placeholder="Workspace Name"
                    control={control}
                  />
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Button onClick={onClose} color="navy" variant="outlined">
              Cancel
            </Button>
            <LoadingButton
              isLoading={isSubmitting}
              type="submit"
              variant="contained"
            >
              Save Changes
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </form>
  );
};
