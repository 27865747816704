import { action, makeObservable, observable } from "mobx";
import { BookingGroupView } from "src/service/queryHooks/campaigns/normalizers/normalizeCampaignWithBookings";
import {
  SpecSheetTab,
  specSheetTabSpecial,
  BookingView,
  idFlavor,
} from "@specsheet-common/shared-types";

export class GenericTableStore {
  currentTab: SpecSheetTab = specSheetTabSpecial.Overview;
  allRows: Set<string> = new Set();
  allGroups: Set<string> = new Set();
  selectedRows: Set<string> = new Set();
  selectedGroups: Set<string> = new Set();
  recentlyAddedRowRefs: Set<string> = new Set();
  closedGroups: Set<string> = new Set();

  constructor() {
    makeObservable(this, {
      allRows: observable,
      allGroups: observable,
      selectedRows: observable,
      selectedGroups: observable,
      closedGroups: observable,
      recentlyAddedRowRefs: observable,
      addRecentlyAddedRowRef: action,
      addRecentlyAddedRowRefs: action,
      toggleRowSelection: action,
      toggleGroupSelection: action,
      toggleGroupOpen: action,
      toggleAllOpened: action,
      unselectAll: action,
      update: action,
      updateSelectedRowsAfterDelete: action,
      currentTab: observable,
      selectTab: action,
    });
  }

  selectTab = (tab: SpecSheetTab) => {
    this.currentTab = tab;
  };

  unselectAll = () => {
    this.selectedRows.clear();
    this.selectedGroups.clear();
  };

  selectOne = (id: string) => {
    this.selectedRows.clear();
    this.selectedGroups.clear();
    this.selectedRows.add(id);
  };

  toggleAllOpened = () => {
    this.closedGroups =
      this.closedGroups.size === this.allGroups.size
        ? new Set()
        : new Set(this.allGroups);
  };

  toggleSetItem = (set: Set<string>, id: string) => {
    if (set.has(id)) {
      set.delete(id);
    } else {
      set.add(id);
    }
  };

  toggleRowSelection = (rowId: string) => {
    this.toggleSetItem(this.selectedRows, rowId);
  };

  toggleGroupSelection = ({
    groupId,
    rowIds,
    groupIds,
  }: {
    groupId: idFlavor<"BookingGroup">;
    rowIds: Set<idFlavor<"Booking">>;
    groupIds: Set<idFlavor<"BookingGroup">>;
  }) => {
    const allGroupIds = groupIds.add(groupId);

    if (this.selectedGroups.has(groupId)) {
      this.selectedRows = new Set(
        [...this.selectedRows].filter((id) => !rowIds.has(id))
      );
      this.selectedGroups = new Set(
        [...this.selectedGroups].filter((gId) => !allGroupIds.has(gId))
      );
    } else {
      this.selectedRows = new Set([...this.selectedRows, ...rowIds]);
      this.selectedGroups = new Set([...this.selectedGroups, ...allGroupIds]);
    }
  };

  toggleGroupOpen = (groupId: string, forceOpen?: boolean) => {
    if (forceOpen) {
      this.closedGroups.delete(groupId);
    } else {
      this.toggleSetItem(this.closedGroups, groupId);
    }
  };

  updateSelectedRowsAfterDelete = (
    rowData: BookingView[],
    groupData: BookingGroupView[]
  ) => {
    const selectedRowIDs = new Set(
      rowData.map((item) => {
        return item.bookingDetails.id;
      })
    );

    const selectedGroupIDs = new Set(
      groupData.map((item) => {
        return item.bookingGroup.id;
      })
    );

    this.selectedRows = new Set(
      [...this.selectedRows].filter((id) => !selectedRowIDs.has(id))
    );
    this.selectedGroups = new Set(
      [...this.selectedGroups].filter((id) => !selectedGroupIDs.has(id))
    );

    this.selectTab(specSheetTabSpecial.Overview);
  };

  openGroup = (groupId: string) => {
    this.closedGroups.delete(groupId);
  };

  addRecentlyAddedRowRefs = (id: idFlavor<"Booking">[]) => {
    this.selectTab(specSheetTabSpecial.Overview);

    this.recentlyAddedRowRefs = new Set([
      ...this.recentlyAddedRowRefs.values(),
      ...id,
    ]);
  };

  addRecentlyAddedRowRef = (id: string) => {
    this.recentlyAddedRowRefs.add(id);
  };

  update = (rows: Set<string>, groups: Set<string>, reset?: boolean) => {
    this.allRows = new Set(rows);
    this.allGroups = new Set(groups);

    if (reset) {
      this.closedGroups.clear();
      this.selectedGroups.clear();
      this.selectedRows.clear();
    }
  };
}
