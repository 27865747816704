import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCreateWorkspace } from "../../../../../service/queryHooks/workspaces/mutations/useCreateWorkspace";
import { useMaybeNewFileOrClear } from "src/ui/components/shared/helpers/useMaybeNewFileOrClear";
import { LoadingButton } from "src/ui/components/shared/molecules/buttons/LoadingButton";
import { FormTextField } from "src/ui/components/shared/molecules/hook-form-components/FormTextField";
import { DialogTitleStyled } from "src/ui/components/shared/mui-styled/Dialog";
import { FormImgUploadControl } from "src/ui/components/shared/molecules/hook-form-components/FormImgUploadControl";
import { z } from "@specsheet-common/shared-tools";
import { idFlavor } from "@specsheet-common/shared-types";

interface CreateWorkplaceFormProps {
  onClose(): void;
  organisationId: idFlavor<"Organisation">;
  variant: "default" | "newDashboard";
}

export const workspaceSchema = z.object({
  name: z.string().trim().min(1, { message: "Workspace name is required" }),
  logoFile: z.instanceof(File).or(z.null()).or(z.literal("empty")),
});

export type WorkspaceSchema = z.infer<typeof workspaceSchema>;

export const CreateWorkplaceForm = ({
  onClose,
  organisationId,
  variant = "default",
}: CreateWorkplaceFormProps) => {
  const mutation = useCreateWorkspace({
    organisationId,
    onSuccess: onClose,
    includeArchived: true,
    calculateStats: true,
  });
  const newFileOrClear = useMaybeNewFileOrClear(
    `workspace-logo-${organisationId}`
  );

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<WorkspaceSchema>({
    mode: "all",
    defaultValues: { name: "", logoFile: null },
    resolver: zodResolver(workspaceSchema),
  });

  const onSubmit: SubmitHandler<WorkspaceSchema> = async (fieldValues) => {
    const maybeLogoUrl = await newFileOrClear(fieldValues.logoFile);

    await mutation.mutateAsync({
      organisationId,
      name: fieldValues.name,
      logo: maybeLogoUrl.file,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form">
      {variant === "newDashboard" ? (
        <>
          <DialogTitleStyled onClose={onClose} title="Create Workspace" />
          <DialogContent sx={{ paddingBottom: 0 }}>
            <Divider sx={{ marginBottom: 4 }} />
            <Grid container spacing={8}>
              <Grid item xs pt={1}>
                <Stack spacing={2}>
                  <Typography variant="body2">Workspace Name</Typography>
                  <FormTextField
                    fullWidth
                    name="name"
                    placeholder="Workspace Name"
                    control={control}
                  />
                </Stack>
              </Grid>
              <Grid item>
                <Stack spacing={2}>
                  <Typography variant="body2">Image</Typography>
                  <FormImgUploadControl
                    name="logoFile"
                    control={control}
                    variant={variant}
                  />
                </Stack>
              </Grid>
            </Grid>

            <Divider sx={{ marginTop: 4 }} />
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Button
              onClick={onClose}
              variant="outlined"
              sx={(theme) => ({
                color: theme.newColors.neutral.primary,
                borderColor: theme.newColors.neutral.gray200,
                width: 160,
                height: 32,
              })}
            >
              <Typography variant="subtitle2">Cancel</Typography>
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              isLoading={isSubmitting}
              sx={{
                width: 160,
                height: 32,
              }}
            >
              <Typography variant="subtitle2">Create</Typography>
            </LoadingButton>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitleStyled onClose={onClose} title="Create Workspace" />
          <DialogContent dividers>
            <Grid container>
              <Grid item width={190}>
                <FormImgUploadControl name="logoFile" control={control} />
              </Grid>
              <Grid item xs pt={1}>
                <Stack spacing={1.5}>
                  <FormTextField
                    fullWidth
                    name="name"
                    label="Workspace Name *"
                    placeholder="Workspace Name"
                    control={control}
                  />
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Button onClick={onClose} color="navy" variant="outlined">
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              isLoading={isSubmitting}
            >
              Create
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </form>
  );
};
