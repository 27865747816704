import { Typography } from "@mui/material";
import React from "react";
import { InfoBlock } from "./InfoBlock";

interface InfoTextBlockProps {
  text?: string;
  listText?: string[];
}

export const InfoTextBlock = ({ text, listText }: InfoTextBlockProps) => {
  return (
    <InfoBlock>
      {text && <Typography variant="small">{text}</Typography>}
      {listText && (
        <ul css={{ listStyleType: "disc", lineHeight: 1.2, marginLeft: 20 }}>
          {listText.map((el) => (
            <li key={el}>
              <Typography variant="small">{el}</Typography>
            </li>
          ))}
        </ul>
      )}
    </InfoBlock>
  );
};
