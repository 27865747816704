import React from "react";
import { HelpMenu } from "./HelpMenu";
import { Box, Stack } from "@mui/material";
import { TssLogoSquare } from "./TssLogoSquare";
import { SignUpLink } from "./SignUpLink";
import { TssLogoLink } from "./TssLogoWithLabel";
import { LiveChatLinkButton } from "./LiveChatLinkButton";

export const NewPublicHeader = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        color: (theme) => theme.colors.colorDarkGray,
        py: 2,
      }}
    >
      <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-start" }}>
        <TssLogoSquare />
      </Box>

      <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
        <TssLogoLink />
      </Box>

      <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
        <Stack direction={"row"} spacing={4} alignItems="center">
          <LiveChatLinkButton />
          <HelpMenu />
          <SignUpLink />
        </Stack>
      </Box>
    </Stack>
  );
};
