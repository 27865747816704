import { useQueryClient } from "react-query";
import {
  ArchiveWorkspaceReq,
  ArchiveWorkspaceRes,
  coreRoutes,
  idFlavor,
  urlBuilder,
  workspaceSubRoute,
} from "@specsheet-common/shared-types";
import { queryKeys } from "src/service/queryHooks/keys";
import { useMutation } from "src/service/reactQuery";

interface UseArchiveWorkspaceProps {
  organisationId: idFlavor<"Organisation">;
  onSuccess?(): void;
  onError?(): void;
  includeArchived: boolean;
  calculateStats: boolean;
}

export const useArchiveWorkspace = ({
  organisationId,
  onError,
  onSuccess,
  includeArchived,
  calculateStats,
}: UseArchiveWorkspaceProps) => {
  const queryClient = useQueryClient();
  return useMutation<ArchiveWorkspaceReq, ArchiveWorkspaceRes>({
    url: urlBuilder({
      root: coreRoutes.workspaces,
      sub: workspaceSubRoute.updateIsActive,
    }),
    onError,
    successMessage: "Workspace successfully updated",
    onSuccess: () => {
      void queryClient.invalidateQueries(
        queryKeys.workspaces.fetchWorkspaces({
          organisationId,
          includeArchived,
          calculateStats,
        })
      );

      void queryClient.invalidateQueries(
        queryKeys.orgUsers.fetchOrgUsers(organisationId)
      );
      onSuccess?.();
    },
  });
};
