import { Service } from "src/service/service";
import { CampaignTemplateDrawerStore } from "./CampaignTemplateDrawerStore";
import { GenericTableStore } from "./GenericTableStore";
import { NotificationCenterStore } from "./NotificationCenterStore";

export class UiStore {
  campaignTemplateDrawerStore: CampaignTemplateDrawerStore;
  genericTablesStore: Map<string, GenericTableStore>;
  notificationCenterStore: NotificationCenterStore;

  constructor(service: Service) {
    this.campaignTemplateDrawerStore = new CampaignTemplateDrawerStore(
      this,
      service
    );
    this.notificationCenterStore = new NotificationCenterStore(this, service);
    this.genericTablesStore = new Map();
  }
}
