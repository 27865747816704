import { Box } from "@mui/material";
import React from "react";
import { ImgIcon } from "src/ui/components/shared/atoms/icons/ImgIcon";
import { ImageIcon } from "src/ui/components/shared/atoms/icons/ImageIcon";

interface NoImagePlaceholderProps {
  size?: 160 | 164;
  variant?: "default" | "newDashboard";
}

export const NoImagePlaceholder = ({
  size,
  variant = "default",
}: NoImagePlaceholderProps) => {
  const sizeFinal = size || (variant === "newDashboard" ? 160 : 164);

  return (
    <Box
      width={sizeFinal}
      height={sizeFinal}
      sx={
        variant === "newDashboard"
          ? {
              backgroundColor: (theme) => theme.newColors.neutral.gray100,
              borderRadius: 1,
            }
          : {
              backgroundColor: (theme) => theme.colors.fadedBlue,
              borderRadius: 3,
            }
      }
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box ml={-1.5}>
        {variant === "newDashboard" ? (
          <ImageIcon
            sx={(theme) => ({ color: theme.newColors.neutral.gray300 })}
          />
        ) : (
          <ImgIcon color={"darkGray"} />
        )}
      </Box>
    </Box>
  );
};
