import { AnalyticsBrowser } from "@segment/analytics-next";
import { flatten } from "flat";
import React, { useEffect } from "react";
import { segmentWriteKey } from "src/constants/analytics";
import { useAuth } from "src/hooks/contexts/useAuth";
import { useFeatures } from "../service/featureFlags";
import * as datadog from "src/util/datadog";

interface Analytics {
  track(name: string, values: Record<string, string | number | boolean>): void;
  page(url: string, data: Record<string, string | number | boolean>): void;
  trackError: typeof datadog.error;
}

export const AnalyticsContext = React.createContext<Analytics | null>(null);

export const AnalyticsProvider: React.FC = ({ children }) => {
  const { workspace, organisation, profile, isInternalUser } = useAuth();
  const { enableDatadog, recordAllSessions } = useFeatures();

  useEffect(() => {
    if (enableDatadog) {
      datadog.setup(recordAllSessions);
    }
  }, [enableDatadog, recordAllSessions]);

  useEffect(() => {
    datadog.setUser(profile);
  }, [profile]);

  const context = React.useMemo(() => {
    const a = AnalyticsBrowser.load({ writeKey: segmentWriteKey });
    const user = {
      name: `${profile.name} ${profile.lastName}`,
      email: profile.email,
      workspaceId: workspace.id,
      workspaceName: workspace.name,
      organisationId: organisation.id,
      organisationName: organisation.name,
      group: organisation.name,
      isInternalUser,
    };
    if (profile.userId) {
      void a.identify(profile.userId, user);
      void a.group(organisation.id, {
        name: organisation.name,
        isPaid: workspace.isPaid,
        organisationId: organisation.id,
        organisationName: organisation.name,
        workspaceId: workspace.id,
        workspaceName: workspace.name,
      });
      void a.group(workspace.id, {
        name: workspace.name,
        isPaid: workspace.isPaid,
        workspaceId: workspace.id,
        workspaceName: workspace.name,
        parent_company_id: organisation.id,
      });
    } else {
      void a.identify({});
    }

    const c: Analytics = {
      trackError: datadog.error,
      track: (name, values) => {
        return a.track(name, flatten({ user, ...values }));
      },
      page: (url, data) => {
        return a.page(
          url,
          flatten({
            user,
            company: `${user.organisationName}/${user.workspaceName}`,
            ...data,
          })
        );
      },
    };

    return c;
  }, [workspace, organisation, profile, isInternalUser]);

  return (
    <AnalyticsContext.Provider value={context}>
      {children}
    </AnalyticsContext.Provider>
  );
};
