import React from "react";
import {
  Stack,
  StackProps,
  Tooltip,
  Typography,
  TypographyProps,
} from "@mui/material";

export interface InfoStatusProps {
  label?: string;
  Icon?(): JSX.Element;
  color?: TypographyProps["color"];
  bgcolor?: StackProps["bgcolor"];
  tooltipText?: string;
  variant?: TypographyProps["variant"];
  sx?: StackProps["sx"];
}

export const InfoStatusLabel = (props: InfoStatusProps) => {
  const { label, color, Icon, bgcolor, tooltipText, variant, sx } = props;

  const renderLabel = (): JSX.Element => (
    <Stack
      direction="row"
      alignItems="center"
      spacing={0.5}
      bgcolor={bgcolor}
      width="fit-content"
      py={0.3}
      px={1.2}
      borderRadius={1}
      sx={sx}
    >
      {Icon && <Icon />}
      {label && (
        <Typography noWrap color={color} variant={variant || "small"}>
          {label}
        </Typography>
      )}
    </Stack>
  );

  if (tooltipText) {
    return (
      <Tooltip title={tooltipText} arrow placement="top">
        {renderLabel()}
      </Tooltip>
    );
  }

  return renderLabel();
};
