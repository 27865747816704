import { SSMenu } from "../../../components/shared/molecules/SsMenu";
import React from "react";
import { HelpLinkButton } from "./HelpLinkButton";
import { helpMenuItems } from "./helpMenuItems";
import { useTheme } from "@emotion/react";

export const HelpMenu = () => {
  const theme = useTheme();

  return (
    <SSMenu
      button={(onClick) => <HelpLinkButton onClick={onClick} />}
      menuItems={helpMenuItems}
      transformOrigin={{
        vertical: -2 * theme.metrics.unit,
        horizontal: "center",
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    />
  );
};
