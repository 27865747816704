import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useAuth } from "../../../hooks/contexts/useAuth";
import { useAuth0 } from "@auth0/auth0-react";

import { coreClientUrl } from "../../../constants/url";

interface LogOutDialogProps {
  onClose(): void;
}

export const LogOutDialog = ({ onClose }: LogOutDialogProps): JSX.Element => {
  const { logout: internalLogout } = useAuth();

  const { logout: externalLogout, isAuthenticated } = useAuth0();

  const handleLogout = () => {
    if (isAuthenticated) {
      // Tell auth0 to return us to a url that can handle the removal of the internal token
      const returnUrl = `${coreClientUrl}/logout`;
      externalLogout({ returnTo: returnUrl });
    } else {
      internalLogout();
    }
  };

  return (
    <>
      <DialogContent dividers>
        <Typography>Are you sure you want to log out?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="navy" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleLogout();
            onClose();
          }}
          type="submit"
          variant="contained"
        >
          Ok
        </Button>
      </DialogActions>
    </>
  );
};
