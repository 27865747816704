import { idFlavor } from "@specsheet-common/shared-types";
import { useCallback, useState } from "react";

export const placementDetailsKind = {
  sharedView: "sharedView",
  builderView: "builderView",
  builderEdit: "builderEdit",
} as const;

export type PlacementDetailsKindType =
  (typeof placementDetailsKind)[keyof typeof placementDetailsKind];

export interface PlacementDetailsControl {
  state: PlacementDetailsKindType;
  currentBookingViewId: idFlavor<"Booking">;
  submitCount: number;
  disableClickAway: boolean;
  setDisableClickAway(state: boolean): void;
  checkIsSharedView(): boolean;
  checkIsBuilderEdit(): boolean;
  switchToBuilderEdit(): void;
  clearKey(): void;
  cancelAndSwitchToBuilderView(): void;
  submitAndSwitchToBuilderView(): void;
  setStateAndKey(
    kind: PlacementDetailsKindType,
    key: idFlavor<"Booking">
  ): void;
}

export const usePlacementDetailsControl = (): PlacementDetailsControl => {
  const [state, setState] = useState<PlacementDetailsKindType>(
    placementDetailsKind.sharedView
  );

  const [submitCount, setSubmitCount] = useState<number>(0);
  const [currentBookingViewId, setCurrentBookingViewId] =
    useState<idFlavor<"Booking">>("");
  const [disableClickAway, setDisableClickAway] = useState(true);

  const checkIsSharedView = useCallback(
    () => state === placementDetailsKind.sharedView,
    [state]
  );
  const checkIsBuilderEdit = useCallback(
    () => state === placementDetailsKind.builderEdit,
    [state]
  );
  const switchToBuilderEdit = useCallback(() => {
    setState(placementDetailsKind.builderEdit);
  }, []);
  const cancelAndSwitchToBuilderView = useCallback(() => {
    setState(placementDetailsKind.builderView);
  }, []);

  const submitAndSwitchToBuilderView = useCallback(() => {
    setSubmitCount(submitCount + 1);
    setState(placementDetailsKind.builderView);
  }, [submitCount]);
  const setStateAndKey = useCallback(
    (kind: PlacementDetailsKindType, key: string) => {
      setCurrentBookingViewId(key);
      setState(kind);
    },
    []
  );

  const clearKey = useCallback(() => {
    setCurrentBookingViewId("");
  }, []);

  return {
    state,
    currentBookingViewId,
    submitCount,
    checkIsSharedView,
    checkIsBuilderEdit,
    switchToBuilderEdit,
    cancelAndSwitchToBuilderView,
    submitAndSwitchToBuilderView,
    setStateAndKey,
    clearKey,
    disableClickAway,
    setDisableClickAway,
  };
};
