import React from "react";
import { Link, Stack, Typography } from "@mui/material";
import { PersonCircleIcon } from "src/ui/components/shared/atoms/icons/PersonCircleIcon";

export const LiveChatLinkButton = () => (
  <Link component="button" underline="none" id="ss-intercom-launcher">
    <Stack direction="row" alignItems="center" spacing={2}>
      <PersonCircleIcon />
      <Typography variant="subtitle2">Live Chat</Typography>
    </Stack>
  </Link>
);
