import React from "react";
import { Box, BoxProps } from "@mui/material";

export const InfoBlock: React.FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Box p={1} bgcolor={(theme) => theme.colors.infoBlockBackground} {...rest}>
      {children}
    </Box>
  );
};
