import { Link, Stack, Typography } from "@mui/material";
import { toJS } from "mobx";
import React from "react";
import { useBuildCampaignContext } from "../../../../hooks/contexts/useBuildCampaignContext";
import { WYSIWYG } from "../../shared/organisms/material-slate/WYSIWYG";
import { assertNever } from "@specsheet-common/shared-tools";
import {
  N_NotificationView,
  notificationCategory,
} from "@specsheet-common/shared-types";
import { useCtaLinkData } from "./useCtaLinkData";

interface GetSmallCardCTAArgs {
  notification: N_NotificationView;
  onClose?(): void;
}

export const SmallCardCTA = ({
  notification,
  onClose,
}: GetSmallCardCTAArgs): JSX.Element | null => {
  const controller = useBuildCampaignContext(true);
  const notificationLinkData = useCtaLinkData(notification.byCategoryData);

  const { byCategoryData } = notification;

  switch (byCategoryData.category) {
    case notificationCategory.TEAM_MEMBER_ADDED:
    case notificationCategory.CAMPAIGN_SHARED:
    case notificationCategory.EXTERNAL:
    case notificationCategory.EXTERNAL_NEW: {
      const campaignId = byCategoryData.campaignId;
      return (
        <Stack spacing={0.5}>
          {notificationLinkData && (
            <Link
              onClick={(e) => {
                e.stopPropagation();
                // Checking if oppened campaign id is the same as in notification. Because otherwise router will fail trying to redirect to the same page.
                // TODO need a better solution for that
                if (
                  campaignId &&
                  controller?.viewModel.value?.campaign.campaignId !==
                    campaignId
                ) {
                  notificationLinkData.onClick();
                }
                if (onClose) {
                  onClose();
                }
              }}
            >
              <Typography variant="small">
                {notificationLinkData.label}
              </Typography>
            </Link>
          )}
        </Stack>
      );
    }
    case notificationCategory.TEAM_MEMBER_REMOVED: {
      return null;
    }
    case notificationCategory.ANNOUNCEMENT: {
      const formattedMessage = toJS(byCategoryData.formattedMessage) || [];
      if (formattedMessage.length > 0) {
        return (
          <WYSIWYG
            key={JSON.stringify(formattedMessage)}
            value={formattedMessage}
            readonly
          />
        );
      }

      return (
        <Typography variant="small">{byCategoryData.messageText}</Typography>
      );
    }
    default: {
      assertNever(byCategoryData);
    }
  }
};
