import { List, ListItem, Stack, Tooltip, Typography } from "@mui/material";
import { idFlavor } from "@specsheet-common/shared-types";
import React from "react";

interface BookingPublicationChangeView {
  bookingId: idFlavor<"Booking">;
  description: string;
  valueBefore: string;
  valueAfter: string;
}

interface PublicationUpdatesListProps {
  pubChanges: BookingPublicationChangeView[];
}

export const PublicationUpdatesList = ({
  pubChanges,
}: PublicationUpdatesListProps) => {
  return (
    <List>
      {pubChanges.map((change) => {
        return (
          <ListItem key={change.bookingId}>
            <Stack width="100%">
              <Typography variant="smallBold">{change.description}</Typography>
              <Tooltip title={change.valueBefore}>
                <Typography
                  variant="small"
                  pl={1}
                  sx={{ fontStyle: "italic" }}
                  noWrap
                >
                  {`Before: ${change.valueBefore || "none"}`}
                </Typography>
              </Tooltip>
              <Tooltip title={change.valueAfter}>
                <Typography
                  variant="small"
                  pl={1}
                  sx={{ fontStyle: "italic" }}
                  noWrap
                >
                  {`After: ${change.valueAfter || "none"}`}
                </Typography>
              </Tooltip>
            </Stack>
          </ListItem>
        );
      })}
    </List>
  );
};
