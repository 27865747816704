import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../../../hooks/contexts/useAuth";
import { orgMemberStatus, routePaths } from "@specsheet-common/shared-types";
import { useAuth0 } from "@auth0/auth0-react";
import { useOnboarding } from "../../../../hooks/contexts/useOnboarding";

export const AuthGuard: React.FC = ({ children }) => {
  const { isAuthenticated, organisation, profile } = useAuth();
  const { isAuthenticated: isAuthenticated0 } = useAuth0();
  const { onboardingShowing } = useOnboarding();

  if (!isAuthenticated && !isAuthenticated0) {
    return <Navigate to={routePaths.login} />;
  }

  if (isAuthenticated && organisation.status !== orgMemberStatus.Active) {
    return <Navigate to={routePaths.inactiveAccount} />;
  } else if (profile.requiresOnboarding && !onboardingShowing) {
    // The user needs to do their onboarding
    return <Navigate to={routePaths.setup} />;
  }

  return <>{children}</>;
};
