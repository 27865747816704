import { assertNever } from "@specsheet-common/shared-tools";
import {
  NotificationByCategoryViewData,
  notificationCategory,
} from "@specsheet-common/shared-types";
import { INITIAL_CAMPAIGN_VERSION } from "../../../../constants/campaign";
import { getExternalMediaOwnerName } from "./getExternalMediaOwnerName";

export const getNotificationSubject = (
  notificationData: NotificationByCategoryViewData
): string => {
  switch (notificationData.category) {
    case notificationCategory.EXTERNAL: {
      const mediaOwnerName = getExternalMediaOwnerName(
        notificationData.structuredNotification.ext.extCampaignMediaOwner
      );

      return `There have been changes to your contract${
        mediaOwnerName === "" ? "" : ` with ${mediaOwnerName}`
      } for the following campaign: ${
        notificationData.campaignTitle
      }. Please review the changes and share a new version of your spec sheet.`;
    }
    case notificationCategory.EXTERNAL_NEW: {
      return `There have been changes to your contract${
        notificationData.mediaOwnerName === ""
          ? ""
          : ` with ${notificationData.mediaOwnerName}`
      } for the following campaign: ${
        notificationData.campaignTitle
      }. Please review the changes and share a new version of your spec sheet.`;
    }
    case notificationCategory.TEAM_MEMBER_ADDED: {
      return `${notificationData.addedRemovedBy} has assigned you to work with them on ${notificationData.campaignTitle}`;
    }
    case notificationCategory.TEAM_MEMBER_REMOVED: {
      return `You have been unassigned from ${notificationData.campaignTitle} by ${notificationData.addedRemovedBy}`;
    }
    case notificationCategory.CAMPAIGN_SHARED: {
      if (
        notificationData.structuredNotification.version ===
        INITIAL_CAMPAIGN_VERSION
      ) {
        return `${notificationData.campaignTitle} has been shared by ${notificationData.sharedByName}`;
      }

      return `Version ${notificationData.structuredNotification.version} of ${notificationData.campaignTitle} has been shared by ${notificationData.sharedByName}`;
    }
    case notificationCategory.ANNOUNCEMENT: {
      return notificationData.subject;
    }
    default: {
      assertNever(notificationData);
    }
  }
};
