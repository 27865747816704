import DoneAllIcon from "@mui/icons-material/DoneAll";
import {
  Button,
  Divider,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { useRootStore } from "../../../hooks/contexts/useRootStore";
import { NotificationSmallCard } from "./NotificationSmallCard";
import { useNavigate } from "react-router-dom";
import { N_NotificationView, routePaths } from "@specsheet-common/shared-types";
import { useQueryNotifications } from "src/service/queryHooks/notifications/queries/useQueryNotifications";
import { useMarkAsRead } from "src/service/queryHooks/notifications/mutations/useMarkAsRead";
import { useMarkAllAsRead } from "src/service/queryHooks/notifications/mutations/useMarkAllAsRead";

interface NotificationPopoverProps {
  anchorElement: HTMLDivElement | null;
  handleClose(): void;
}

export const NotificationsPopover = observer(
  ({ anchorElement, handleClose }: NotificationPopoverProps) => {
    const {
      uiStore: { notificationCenterStore },
    } = useRootStore();

    const { data: notifications = [] } = useQueryNotifications({
      staleTime: Number.POSITIVE_INFINITY,
    });
    const { mutate: markAsRead } = useMarkAsRead({});
    const { mutateAsync: markAllAsRead } = useMarkAllAsRead({});
    const [unreadNotifications, readNotifications] = notifications.reduce<
      N_NotificationView[][]
    >(
      ([unread, read], n) => {
        if (n.isRead) {
          return [unread, [...read, n]];
        }
        return [[...unread, n], read];
      },
      [[], []]
    );
    const navigation = useNavigate();

    const newNotificationsAmount = unreadNotifications.length;
    const open = Boolean(anchorElement);
    const id = open ? "simple-popover" : undefined;

    const redirectToNotificationCenter = (
      notification?: N_NotificationView
    ) => {
      if (notification) {
        notificationCenterStore.setSelectedId(notification.notificationId);
      }
      if (notification && !notification.isRead) {
        void markAsRead(notification.notificationId);
      }
      // TODO: should probably close the popover here?
      navigation(routePaths.notificationCenter);
    };

    const renderNotifications = () => {
      if (!notifications.length) {
        return (
          <Stack p={3} justifyContent="center" alignItems="center">
            <Typography variant="base">
              Currently you don't have any notifications.
            </Typography>
          </Stack>
        );
      }

      return (
        <Stack
          spacing={0.5}
          p={0.5}
          maxHeight={"70vh"}
          minHeight={500}
          sx={{ overflowY: "auto" }}
        >
          {unreadNotifications.map((notification: N_NotificationView) => (
            <NotificationSmallCard
              key={notification.notificationId}
              notification={notification}
              onClose={handleClose}
              onClick={() => redirectToNotificationCenter(notification)}
            />
          ))}
          {readNotifications.map((notification: N_NotificationView) => (
            <NotificationSmallCard
              key={notification.notificationId}
              notification={notification}
              onClose={handleClose}
              onClick={() => redirectToNotificationCenter(notification)}
            />
          ))}
        </Stack>
      );
    };

    const renderNotificationsBlock = () => {
      return (
        <Stack width={350}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent={"space-between"}
            p={2}
          >
            <Stack>
              <Typography variant="smallBold">NOTIFICATIONS</Typography>
              {newNotificationsAmount > 0 && (
                <Typography variant="small">
                  {`You have ${newNotificationsAmount} unread notifications`}
                </Typography>
              )}
            </Stack>
            {newNotificationsAmount > 0 && (
              <Tooltip title="Mark all as read">
                <IconButton onClick={() => markAllAsRead()}>
                  <DoneAllIcon color="primary" />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
          <Divider />
          {renderNotifications()}
          <Divider />
          <Stack p={2} alignItems="center">
            <Button fullWidth onClick={() => redirectToNotificationCenter()}>
              VIEW ALL
            </Button>
          </Stack>
        </Stack>
      );
    };

    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {renderNotificationsBlock()}
      </Popover>
    );
  }
);
