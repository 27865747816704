import React from "react";
import { Link, Stack, Typography } from "@mui/material";
import { QuestionCircleIcon } from "src/ui/components/shared/atoms/icons/QuestionCircleIcon";

interface HelpLinkButtonProps {
  onClick(event: React.MouseEvent<HTMLElement>): void;
}

export const HelpLinkButton = ({ onClick }: HelpLinkButtonProps) => (
  <Link component="button" underline="none" onClick={onClick}>
    <Stack direction="row" alignItems="center" spacing={2}>
      <QuestionCircleIcon />
      <Typography variant="subtitle2">Help</Typography>
    </Stack>
  </Link>
);
