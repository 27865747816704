import {
  MenuItem,
  Typography,
  Theme,
  Stack,
  Tooltip,
  Box,
} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { WithTooltip } from "../../../atoms/tooltip/WithTooltip";

export type MenuItemAction = {
  onClick?(e: React.MouseEvent, onClose?: () => void): void;
  handleClose?(): void;
  disabled?: boolean;
  label: string;
  tooltip?: string;
  id?: string;
  divider?: boolean;
  color?: string;
  maxWidth?: number;
  width?: number;
  dataPw?: string;
} & (
  | {
      onClick(e: React.MouseEvent): void;
      kind?: "action";
      startIcon?: JSX.Element;
      endIcon?: JSX.Element;
    }
  | {
      kind: "component";
      component: JSX.Element;
    }
);

export const SsMenuItemAction = ({
  id,
  disabled,
  tooltip,
  divider,
  color,
  maxWidth = 150,
  width,
  dataPw,
  handleClose,
  ...rest
}: MenuItemAction): JSX.Element => {
  switch (rest.kind) {
    case "component": {
      return (
        <MenuItem
          dense
          id={id}
          disabled={disabled}
          divider={divider}
          sx={(theme: Theme) => ({
            width,
            "&:hover": {
              backgroundColor: theme.newColors.neutral.gray100,
              color: theme.newColors.neutral.primary,
            },
            "&": {
              px: "12px",
            },
          })}
          data-pw={dataPw}
        >
          {rest.component}
        </MenuItem>
      );
    }
    default: {
      return (
        <Tooltip title={disabled && tooltip ? tooltip : ""} arrow>
          <Box>
            <MenuItem
              onClick={(e) => rest.onClick(e, handleClose)}
              dense
              id={id}
              disabled={disabled}
              divider={divider}
              sx={(theme: Theme) => ({
                width,
                "&:hover": {
                  backgroundColor: theme.newColors.neutral.gray100,
                  color: theme.newColors.neutral.primary,
                },
                "&": {
                  px: "12px",
                },
              })}
              data-pw={dataPw}
            >
              {rest.startIcon && (
                <ListItemIcon sx={{ minWidth: "15px" }}>
                  {rest.startIcon}
                </ListItemIcon>
              )}
              <ListItemText disableTypography>
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                  <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                    <WithTooltip tooltip={rest.label}>
                      {(ref) => (
                        <Typography
                          ref={ref}
                          variant="body3"
                          color={(theme) =>
                            color || theme.newColors.neutral.gray500
                          }
                          noWrap
                          sx={{ maxWidth, textOverflow: "ellipsis" }}
                        >
                          {rest.label}
                        </Typography>
                      )}
                    </WithTooltip>
                  </Stack>
                </Stack>
              </ListItemText>
              {rest.endIcon}
            </MenuItem>
          </Box>
        </Tooltip>
      );
    }
  }
};
