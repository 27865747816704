import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Link,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { useGenericTableStore } from "../../../../hooks/contexts/useGenericTableStore";
import { BUILD_CAMPAIGN_PAGE_TABLE_ID } from "../../../pages/BuildCampaignPage/constants";
import { idFlavor, specSheetTabSpecial } from "@specsheet-common/shared-types";
import { Id } from "../../shared/organisms/Table";

interface BookingChangeView {
  bookingId: idFlavor<"Booking">;
  description: string;
}

interface ChangesSummaryListProps {
  changeViews: BookingChangeView[];
  goToRowId?: ((id: Id) => void) | null;
}

export const ChangesSummaryList = observer(
  ({ changeViews, goToRowId }: ChangesSummaryListProps) => {
    const genericTableStore = useGenericTableStore(
      BUILD_CAMPAIGN_PAGE_TABLE_ID
    );

    const renderPlacementName = ({
      name,
      bookingId,
    }: {
      name: string;
      bookingId: idFlavor<"Booking">;
    }) => {
      if (goToRowId) {
        return (
          <Tooltip title="View placement">
            <Link
              onClick={() => {
                genericTableStore.selectTab(specSheetTabSpecial.Overview);
                goToRowId(bookingId);
              }}
            >
              <Typography noWrap variant={"smallBold"}>
                {name}
              </Typography>{" "}
            </Link>
          </Tooltip>
        );
      }

      return (
        <Typography noWrap variant={"smallBold"}>
          {name}
        </Typography>
      );
    };

    return (
      <List>
        {changeViews.map((cv) => {
          return (
            <ListItem key={cv.bookingId}>
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems={"center"}
                width={"100%"}
              >
                <FiberManualRecordIcon sx={{ fontSize: 8 }} />
                {renderPlacementName({
                  name: cv.description,
                  bookingId: cv.bookingId,
                })}
              </Stack>
            </ListItem>
          );
        })}
      </List>
    );
  }
);
