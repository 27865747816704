import { Box, Grid, Typography } from "@mui/material";
import { N_OrganisationView, routePaths } from "@specsheet-common/shared-types";
import React from "react";
import { useLocation } from "react-router-dom";
import { theme } from "src/ui/common/NewTheme";
import { ColoredAvatar } from "../../shared/atoms/ColoredAvatar";
import { MenuContext } from "../MenuContext";

export interface OrgTileProps {
  expanded: boolean;
  org: N_OrganisationView;
  onClick(): void;
  variant?: "small" | "large";
}

export const OrganisationTile = ({
  org,
  expanded,
  onClick,
  variant = "small",
  children,
}: React.PropsWithChildren<OrgTileProps>) => {
  const MenuSelections = React.useContext(MenuContext);
  const location = useLocation();
  const youAreHere =
    MenuSelections.selectedOrganisation === org.organisationId &&
    location.pathname.slice(0, routePaths.orgManagement.length) ===
      routePaths.orgManagement;

  const tssMenuItemHoverable = {
    padding: theme.spacing(1),
    borderRadius: "4px",
    cursor: "pointer",
    outline: youAreHere ? "solid 1px #0000FF" : "none",
    "&:hover": {
      backgroundColor: "#F4F4F5",
    },
    "&:focus": {
      boxShadow: "0px 0px 0px 3px #B3D1FF",
      outline: youAreHere ? "solid 1px #0000FF" : "none",
    },
  };

  const boxSx = {
    height: variant == "large" ? "32px" : "28px",
    width: variant == "large" ? "32px" : "28px",
    padding: variant == "large" ? theme.spacing(0.75) : theme.spacing(1),
  };

  const avatarSx = {
    height: variant == "large" ? "26px" : "20px",
    width: variant == "large" ? "26px" : "20px",
  };

  const textSx = {
    lineHeight: variant == "large" ? "32px" : "28px",
    paddingLeft: theme.spacing(2),
  };

  const textVariant = variant == "large" ? "subtitle2" : "body2";

  return (
    <Box sx={{ padding: theme.spacing(1) }}>
      <Box
        sx={tssMenuItemHoverable}
        onClick={onClick}
        tabIndex={1}
        title={`Enter ${org.name}`}
      >
        <Grid container>
          <Grid item xs="auto">
            <Box sx={boxSx}>
              <ColoredAvatar
                imgSrc={org.logo}
                title={org.name}
                variant="rounded"
                sx={avatarSx}
              />
            </Box>
          </Grid>
          {expanded && (
            <>
              <Grid item xs>
                <Typography variant={textVariant} sx={textSx}>
                  {org.name}
                </Typography>
              </Grid>
              {expanded && children}
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
