import { Components, Theme } from "@mui/material";

export const MuiDialogContent: Components<Theme>["MuiDialogContent"] = {
  // So all dialog contents have padding-y of 16px as per designs
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(4, 0, 4, 0),
    }),
  },
};
