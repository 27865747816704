export const colors = {
  neutral: {
    black: "#000000",
    primary: "#272937",
    gray500: "#5D5F69",
    gray400: "#93949B",
    gray300: "#B3B4B9",
    gray200: "#E5E5E7",
    gray100: "#F4F4F5",
    gray50: "#F9F9F9",
    white: "#FFFFFF",
  },
  theme: {
    dark: "#000099",
    primary: "#0000FF",
    blue500: "#408CFF",
    blue400: "#80B3FF",
    blue300: "#B3D1FF",
    blue200: "#E5F0FF",
    blue100: "#F2F7FF",
    blue50: "#F7FBFF",
  },
  utility: {
    blue: "#408CFF",
    blueLight: "#E5F0FF",
    green: "#38BA38",
    greenLight: "#E5F6E5",
    yellow: "#FFB649",
    yellowLight: "#FFF6E7",
    red: "#FF3A30",
    redLight: "#FFE5E4",
    purple: "#9C00E5",
    purpleLight: "#F2DEFC",
    pink: "#FFA1B2",
    pinkLight: "#FFF3F5",
    turquoise: "#14D6BC",
    turquoiseLight: "#E0FAF6",
    azure: "#14B6D6",
    azureLight: "#E0F6FA",
    orange: "#FF7840",
    orangeLight: "#FFEDE6",
  },
};
