import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routePaths } from "@specsheet-common/shared-types";
import { Box, Button, Divider, Grid, Popover, Typography } from "@mui/material";
import {
  ButtonLogoutIcon,
  MenuEmailIcon,
  MenuPaperIcon,
  MenuThreeDotsIcon,
} from "../../shared/atoms/icon/icons";
import { N_UserProfile } from "@specsheet-common/shared-types";
import { ColoredAvatar } from "../../shared/atoms/ColoredAvatar";
import { MenuButton } from "../MenuItems/MenuButton";
import {
  SS_PRIVACY_POLICY,
  SS_TERMS_OF_USE,
} from "src/constants/externalLinks";
import { SS_SUPPORT_EMAIL } from "src/constants/supportEmail";
import { theme } from "src/ui/common/NewTheme";
import { useAuth } from "src/hooks/contexts/useAuth";
import { adminClientUrl } from "src/constants/url";

interface MenuAvatarProps {
  expanded: boolean;
  userProfile: N_UserProfile;
  handleLogout(): void;
}

export const MenuAvatar = ({
  expanded,
  userProfile,
  handleLogout,
}: MenuAvatarProps) => {
  const { isOpsManager } = useAuth();
  const navigate = useNavigate();

  const youAreHere = window.location.pathname.slice(0, 8) === "/profile";

  const [contextAnchor, setContextAnchor] = useState<HTMLDivElement | null>(
    null
  );

  const contextPositionAnchor = useRef<HTMLDivElement>(null);

  const handleNavToProfileSettings = () => {
    navigate(routePaths.userProfile);
  };

  const handleProfileOptions = (e: React.MouseEvent) => {
    setContextAnchor(contextPositionAnchor.current);
    e.stopPropagation();
  };

  const handleExternalLink = (link: string) => {
    window.open(link);
    setContextAnchor(null);
  };

  const tssMenuItemHoverable = {
    padding: "4px",
    borderRadius: "4px",
    cursor: "pointer",
    outline: youAreHere ? "solid 1px #0000FF" : "none",
    "&:hover": {
      backgroundColor: "#F4F4F5",
    },
    "&:focus": {
      boxShadow: "0px 0px 0px 3px #B3D1FF",
      outline: youAreHere ? "solid 1px #0000FF" : "none",
    },
  };

  const tssMenuIconHoverable = {
    height: "32px",
    width: "32px",
    padding: "4px",
    borderRadius: "4px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#E5E5E7",
    },
    "&:focus": {
      boxShadow: "0px 0px 0px 3px #B3D1FF",
      outline: "none",
    },
  };

  const truncate = (input: string, maxlength: number) => {
    if (input.length <= maxlength) {
      return input;
    }
    return input.slice(0, maxlength - 4) + " ...";
  };

  return (
    <Box ref={contextPositionAnchor}>
      <Box sx={{ padding: theme.spacing(1) }}>
        <Box
          sx={tssMenuItemHoverable}
          onClick={handleNavToProfileSettings}
          tabIndex={1}
        >
          <Grid container>
            <Grid item xs="auto">
              <Box
                sx={{
                  height: "32px",
                  width: "32px",
                  padding: theme.spacing(0.75),
                }}
              >
                <ColoredAvatar
                  imgSrc={userProfile.profilePic}
                  title={userProfile.name}
                  sx={{ height: "26px", width: "26px" }}
                />
              </Box>
            </Grid>
            {expanded && (
              <>
                <Grid item xs>
                  <Box
                    sx={{ height: "32px", width: "160px", overflow: "hidden" }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{ lineHeight: "20px", paddingLeft: theme.spacing(2) }}
                    >
                      {truncate(
                        userProfile.name + " " + userProfile.lastName,
                        16
                      )}
                    </Typography>
                    <Box sx={{ height: "12px", marginTop: theme.spacing(-1) }}>
                      <Typography
                        variant="body3"
                        sx={{
                          lineHeight: "12px",
                          paddingLeft: theme.spacing(2),
                          color: "#B3B4B9",
                        }}
                      >
                        {userProfile.email}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs="auto">
                  <Box
                    onClick={(e: React.MouseEvent) => handleProfileOptions(e)}
                    sx={tssMenuIconHoverable}
                    tabIndex={1}
                  >
                    <MenuThreeDotsIcon size="24px" />
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Box>
      <Popover
        anchorEl={contextAnchor}
        open={Boolean(contextAnchor)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        marginThreshold={0}
        onClose={() => setContextAnchor(null)}
      >
        <Box sx={{ width: "240px" }}>
          <MenuButton
            expanded={expanded}
            icon={<MenuEmailIcon size="24px" />}
            name={"Email Support"}
            typographyVariant={"body2"}
            onClick={() => handleExternalLink("mailto:" + SS_SUPPORT_EMAIL)}
          />
          <Divider />
          <MenuButton
            expanded={expanded}
            icon={<MenuPaperIcon size="24px" />}
            name={"Privacy Policy"}
            typographyVariant={"body2"}
            onClick={() => handleExternalLink(SS_PRIVACY_POLICY)}
          />
          <MenuButton
            expanded={expanded}
            icon={<MenuPaperIcon size="24px" />}
            name={"Terms & Conditions"}
            typographyVariant={"body2"}
            onClick={() => handleExternalLink(SS_TERMS_OF_USE)}
          />
          {isOpsManager && (
            <>
              <Divider />
              <MenuButton
                expanded={expanded}
                icon={<MenuPaperIcon size="24px" />}
                name={"Ops Admin"}
                typographyVariant={"body2"}
                onClick={() => handleExternalLink(adminClientUrl)}
              />
            </>
          )}
          <Box sx={{ padding: theme.spacing(2.75) }}>
            <Button
              onClick={handleLogout}
              color="gray"
              sx={{ padding: theme.spacing(1), width: "100%" }}
            >
              <Typography variant="body3">Log out</Typography>
              <div
                style={{
                  marginLeft: theme.spacing(2),
                  marginTop: theme.spacing(-0.25),
                  marginBottom: theme.spacing(-1.25),
                }}
              >
                <ButtonLogoutIcon size="16px" />
              </div>
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
