import React, { createContext, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
import { Emitters } from "../service/websocket/types";
import { useEmitters } from "../service/websocket/useEmitters";
import { idFlavor } from "@specsheet-common/shared-types";
import { WebsocketMessageIdTemplate } from "@specsheet-common/shared-tools";
import { socketIoURL } from "../constants/url";
import { useAuth0 } from "@auth0/auth0-react";
import { useAccessToken } from "src/hooks/utils/useAccessToken";

export interface WebsocketContextProps {
  socketId: idFlavor<"WebsocketId"> | undefined;
  wsEmit: Emitters;
  socket?: Socket;
  awaitedMessageIds: Set<WebsocketMessageIdTemplate>;
  startWaitingForMessage(messageId: WebsocketMessageIdTemplate): void;
  stopWaitingForMessage(messageId: WebsocketMessageIdTemplate): void;
  isWaitingForMessage: boolean;
  setIsWaitingForMessage(data: boolean): void;
  isWaitingForDocumentDownloadMessage: {
    campaign: boolean;
    placement: boolean;
    placementOne: boolean;
  };
  setIsWaitingForDocumentDownloadMessage(
    setState: (data: {
      campaign: boolean;
      placement: boolean;
      placementOne: boolean;
    }) => {
      campaign: boolean;
      placement: boolean;
      placementOne: boolean;
    }
  ): void;
}

export const WebsocketContext = createContext<WebsocketContextProps | null>(
  null
);

export const WebsocketProvider: React.FC = ({ children }) => {
  const { getAccessToken } = useAccessToken();
  const { isAuthenticated } = useAuth0();

  const [socket, setSocket] = useState<Socket>();

  useEffect(() => {
    const getSocket = async () => {
      if (isAuthenticated) {
        setSocket(
          io(socketIoURL, {
            withCredentials: true,
            extraHeaders: {
              Authorization: await getAccessToken(),
            },
          })
        );
      }
    };

    void getSocket();
  }, [getAccessToken]);

  const [awaitedMessageIds, setAwaitedMessageIds] = useState<
    Set<WebsocketMessageIdTemplate>
  >(new Set());
  const [isWaitingForMessage, setIsWaitingForMessage] = useState(false);
  const [
    isWaitingForDocumentDownloadMessage,
    setIsWaitingForDocumentDownloadMessage,
  ] = useState({
    campaign: false,
    placement: false,
    placementOne: false,
  });
  const [socketId, setSocketId] = useState<string>();
  socket?.on("connect", () => setSocketId(socket.id));

  const startWaitingForMessage = (messageId: WebsocketMessageIdTemplate) => {
    setAwaitedMessageIds(new Set([...awaitedMessageIds, messageId]));
  };

  const stopWaitingForMessage = (messageId: WebsocketMessageIdTemplate) => {
    const newSet = new Set(awaitedMessageIds);
    newSet.delete(messageId);
    setAwaitedMessageIds(newSet);
  };

  const emitters = useEmitters({ socket });

  return (
    <WebsocketContext.Provider
      value={{
        socketId,
        socket,
        wsEmit: emitters,
        awaitedMessageIds,
        startWaitingForMessage,
        stopWaitingForMessage,
        isWaitingForMessage,
        setIsWaitingForMessage,
        isWaitingForDocumentDownloadMessage,
        setIsWaitingForDocumentDownloadMessage,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
};
