import React, { createContext, useState } from "react";

type OnboardingContextProps = {
  onboardingShowing: boolean;
  setOnboardingShowing(s: boolean): void;
};

export const OnboardingContext = createContext<OnboardingContextProps | null>(
  null
);

export const OnboardingProvider: React.FC = ({ children }) => {
  const [showing, setShowing] = useState(false);

  const setOnboardingShowing = (s: boolean) => {
    setShowing(s);
  };

  return (
    <OnboardingContext.Provider
      value={{
        onboardingShowing: showing,
        setOnboardingShowing,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
