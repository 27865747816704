export type Environment =
  | "local"
  | "app.develop"
  | "app.staging"
  | "app.testing"
  | "app.prod"
  | "prod";

export type ConstantsMap<T> = Record<Environment, T>;

export const getEnv = (): Environment => {
  const host = window.location.host;

  if (host.includes("app.develop.thespecsheet.com")) {
    return "app.develop";
  }

  if (host.includes("app.testing.thespecsheet.com")) {
    return "app.testing";
  }

  if (host.includes("app.staging.thespecsheet.com")) {
    return "app.staging";
  }

  if (host.includes("app.prod.thespecsheet.com")) {
    return "app.prod";
  }

  if (host.includes("app.thespecsheet.com")) {
    return "prod";
  }

  return "local";
};
