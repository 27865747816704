import { ConstantsMap, getEnv } from "./util";

const data: ConstantsMap<string> = {
  prod: "65233fff85757f1341fa1208",
  "app.prod": "65233fff85757f1341fa1208",
  "app.staging": "6528a0247e8abe121bd0c4de",
  "app.testing": "65233fff85757f1341fa1207",
  "app.develop": "6528a0297e8abe121bd0c4e9",
  local: "6528a02e7e8abe121bd0c4f2",
};

export const launchDarkly = { clientSideId: data[getEnv()] };
