import { Emitters } from "./types";
import { Socket } from "socket.io-client";
import { idFlavor, socketMessageKinds } from "@specsheet-common/shared-types";

interface UseEmittersProps {
  socket?: Socket;
}

export const useEmitters = ({ socket }: UseEmittersProps): Emitters => {
  return {
    [socketMessageKinds.userUpdated]: (userId: idFlavor<"AppUser">) => {
      socket?.emit(socketMessageKinds.userUpdated, userId);
    },
    [socketMessageKinds.clientLogin]: (authToken: idFlavor<"AuthToken">) => {
      socket?.emit(socketMessageKinds.clientLogin, authToken);
    },
    [socketMessageKinds.enteredCampaign]: ({
      campaignId,
      userId,
    }: {
      campaignId: idFlavor<"Campaign">;
      userId: idFlavor<"AppUser">;
    }) => {
      socket?.emit(socketMessageKinds.enteredCampaign, { campaignId, userId });
    },
    [socketMessageKinds.leftCampaign]: ({
      campaignId,
      userId,
    }: {
      campaignId: idFlavor<"Campaign">;
      userId: idFlavor<"AppUser">;
    }) => {
      socket?.emit(socketMessageKinds.leftCampaign, { campaignId, userId });
    },
  };
};
