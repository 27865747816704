import { useContext } from "react";
import {
  SidePanelContext,
  SidePanelContextProps,
} from "../../contexts/SidePanelContext";

export const useSidePanelContext = (): SidePanelContextProps => {
  const context = useContext(SidePanelContext);

  if (!context) {
    throw new Error("No context for side panel.");
  }

  return context;
};
