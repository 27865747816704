import { Service } from "../service";
import { makeFieldLike, makeTableQuery } from "src/adl-gen/common/tabular";
import { Organisation, Publisher } from "src/adl-gen/kachemedia/specsheet/db";
import { DbKey, WithDbId } from "src/adl-gen/common/db";
import {
  debounceAsync,
  DEFAULT_DEBOUNCE_TIME,
} from "@specsheet-common/shared-tools";

export const fetchPublishersByTerm = debounceAsync(
  DEFAULT_DEBOUNCE_TIME,
  async (args: {
    service: Pick<Service, "queryPublishers">;
    term: string;
    withPlacementsOnly: boolean;
    withSiteIdPlacementsOnly: boolean;
    organisationId: DbKey<Organisation>;
  }): Promise<WithDbId<Publisher>[]> => {
    const {
      service,
      term,
      withPlacementsOnly,
      withSiteIdPlacementsOnly,
      organisationId,
    } = args;

    const termQuery = term
      ? makeTableQuery({
          filter: {
            kind: "like",
            value: makeFieldLike({
              field: {
                kind: "name",
                value: "name",
              },
              pattern: "%" + term + "%",
              caseSensitive: false,
            }),
          },
        })
      : makeTableQuery({});

    const data = await service.queryPublishers({
      query: termQuery,
      withPlacementsOnly,
      withSiteIdPlacementsOnly,
      organisationId,
    });

    return data.items;
  }
);
