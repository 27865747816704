import { useState, useEffect } from "react";

interface UseLocalStorageArgs<STATE_TYPE> {
  key?: string;
  defaultValue: STATE_TYPE;
}

function getValue<T>(key: string | undefined, defaultValue: T) {
  const saved = key ? localStorage.getItem(key) : null;
  const initial = saved ? JSON.parse(saved) : null;
  return initial ?? defaultValue;
}

function setValue<T>(key: string | undefined, value: T | null) {
  if (key) {
    if (value === null) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }
}

// useLocalStorage hook:
export const useLocalStorage = <STATE_TYPE>({
  key,
  defaultValue,
}: UseLocalStorageArgs<STATE_TYPE>): [
  value: STATE_TYPE,
  setValue: (data: STATE_TYPE) => void
] => {
  const [value, resSetValue] = useState<STATE_TYPE>(() => {
    return getValue(key, defaultValue);
  });

  useEffect(() => {
    setValue(key, value);
  }, [key, value]);

  return [value, resSetValue];
};
