import React from "react";
import { Box, Typography } from "@mui/material";
import { theme } from "src/ui/common/NewTheme";

interface MenuTextProps {
  expanded: boolean;
  text: string;
}

export const MenuInlineText = ({ expanded, text }: MenuTextProps) => {
  return (
    <>
      {expanded && (
        <Box sx={{ padding: theme.spacing(3), paddingTop: theme.spacing(0) }}>
          <Typography variant="body3" sx={{ color: "#B3B4B9" }}>
            {text}
          </Typography>
        </Box>
      )}
    </>
  );
};
