// DO NOT USE, DEPRECATED
import classnames from "classnames";
import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { IconProps, ToggleIcon } from "../icon/icons";
import layout from "../../../../common/layout-lib.css";
import styles from "./button.css";
import { useAnalytics } from "src/hooks/contexts/useAnalytics";

type PrimaryButtonColors = "blue" | "green" | "black" | "red";
type SecondaryButtonColors = "blue" | "black" | "green";

export interface SubMenuDropdownItem {
  title: string;
  // eslint-disable-next-line no-use-before-define
  dropdownItems: DropdownItem[];
}

export interface DropdownItem {
  label: string;
  onClick?(): void;
  subMenu?: SubMenuDropdownItem;
  component?: JSX.Element;
}

interface MainDropdownMenu {
  kind: "mainMenu";
  value: DropdownItem[];
}

interface SubDropdownMenu {
  kind: "subMenu";
  value: SubMenuDropdownItem;
}

type DropdownMenuType = MainDropdownMenu | SubDropdownMenu;

interface ButtonProps {
  // eslint-disable-next-line no-use-before-define
  customCss?: ButtonCustomCss;
  Icon?: React.ComponentType<IconProps>;
  hasToggleDropdown?: boolean;
  dropdownItems?: DropdownItem[];
  onClick?(e: React.MouseEvent<HTMLButtonElement>): Promise<void> | void;
  alwaysInProgress?: boolean;
  disabled?: boolean;
  tabIndex?: number;
  children?: React.ReactNode;
}

/** Custom css props */
interface ButtonCustomCss {
  outerContainer?: string;
  container?: string;
  spinner?: string;
  icon?: string;
}

@observer
class Button extends React.Component<ButtonProps> {
  constructor(props: ButtonProps) {
    super(props);
    makeObservable(this, {
      currDropdownItems: observable.ref,
      isInProgress: observable.ref,
      dropdownExpanded: observable.ref,
      onClickToggle: action,
      collapseDropdown: action,
      initialiseDropdownItems: action,
      resetDropdownItems: action,
      toggleOnSubMenu: action,
    });
  }

  currDropdownItems?: DropdownMenuType = undefined;

  isInProgress: boolean = !!this.props.alwaysInProgress;

  dropdownExpanded: boolean = false;

  onClickToggle = () => {
    this.dropdownExpanded = !this.dropdownExpanded;
    if (!this.dropdownExpanded) {
      this.resetDropdownItems();
    }
  };

  collapseDropdown = () => {
    this.dropdownExpanded = false;
    this.resetDropdownItems();
  };

  initialiseDropdownItems = () => {
    this.currDropdownItems = {
      kind: "mainMenu",
      value: this.props.dropdownItems || [],
    };
  };

  resetDropdownItems = () => {
    this.currDropdownItems = undefined;
  };

  toggleOnSubMenu = (title: string, dropdownItems: DropdownItem[]) => {
    this.currDropdownItems = {
      kind: "subMenu",
      value: {
        title,
        dropdownItems,
      },
    };
  };

  render() {
    const outerContainerCss = this.props.customCss
      ? this.props.customCss.outerContainer
      : undefined;
    const containerCss = this.props.customCss
      ? this.props.customCss.container
      : undefined;
    const spinnerCss = this.props.customCss
      ? this.props.customCss.spinner
      : undefined;
    const iconCss =
      this.props.customCss && !this.props.disabled
        ? this.props.customCss.icon
        : undefined;

    if (this.props.disabled) {
      this.collapseDropdown();
    }
    const className = classnames(
      styles.button,
      this.isInProgress && styles.inProgress,
      containerCss
    );
    return (
      <div className={outerContainerCss}>
        <OutsideClickHandler
          onOutsideClick={() => {
            if (this.dropdownExpanded) {
              this.collapseDropdown();
              this.resetDropdownItems();
            }
          }}
        >
          <button
            tabIndex={this.props.tabIndex}
            type="button"
            disabled={!!this.props.disabled}
            onClick={
              this.props.hasToggleDropdown ? this.onClickToggle : this.onClick
            }
            className={className}
          >
            {this.isInProgress ? (
              <div className={classnames(styles.spinner, spinnerCss)} />
            ) : undefined}
            <div className={styles.content}>
              {this.props.Icon ? (
                <this.props.Icon
                  className={classnames(styles.leftIcon, iconCss)}
                  color={this.props.disabled ? "gray" : "white"}
                />
              ) : undefined}
              <span className={styles.contentLabel}>{this.props.children}</span>
              {this.props.hasToggleDropdown ? (
                <ToggleIcon
                  className={classnames(
                    styles.toggleIcon,
                    iconCss,
                    this.dropdownExpanded && styles.buttonExpanded
                  )}
                />
              ) : undefined}
            </div>
          </button>
          {this.dropdownExpanded && (
            <div
              className={classnames(
                styles.dropdownContent,
                this.currDropdownItems?.kind === "subMenu" &&
                  styles.subMenuContent
              )}
            >
              <this.DropdownSection />
            </div>
          )}
        </OutsideClickHandler>
      </div>
    );
  }

  DropdownSection = () => {
    const dropdownTitle =
      this.currDropdownItems?.kind === "subMenu" ? (
        <span className={styles.subMenuTitle}>
          {this.currDropdownItems?.value.title}
        </span>
      ) : undefined;
    const dropdownItems =
      this.currDropdownItems?.kind === "subMenu"
        ? this.currDropdownItems?.value.dropdownItems
        : this.props.dropdownItems;
    const dropdownSection = (
      <div className={layout.vertical}>
        {dropdownTitle}
        {dropdownItems?.map((mainMenuDropdownItem) => (
          <a
            onClick={() => {
              if (mainMenuDropdownItem.subMenu) {
                const subMenuDropdownItems =
                  mainMenuDropdownItem.subMenu.dropdownItems.map(
                    (subMenuDropdownItem) => {
                      return {
                        label: subMenuDropdownItem.label,
                        onClick: () => {
                          if (subMenuDropdownItem.onClick) {
                            subMenuDropdownItem.onClick();
                          }
                          this.resetDropdownItems();
                          this.collapseDropdown();
                        },
                      };
                    }
                  );
                this.toggleOnSubMenu(
                  mainMenuDropdownItem.subMenu.title,
                  subMenuDropdownItems
                );
              } else {
                if (mainMenuDropdownItem.onClick) {
                  mainMenuDropdownItem.onClick();
                }
              }
            }}
          >
            {mainMenuDropdownItem.label}
          </a>
        ))}
      </div>
    );
    return dropdownSection;
  };

  onClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (this.isInProgress) {
      return;
    }
    if (!this.props.onClick) {
      return;
    }

    this.isInProgress = true;
    try {
      await this.props.onClick(e);
    } catch (err) {
      // Rethrow
      throw err;
    } finally {
      this.isInProgress = false;
    }
  };
}

export interface PrimaryButtonProps
  extends Pick<
    ButtonProps,
    "onClick" | "disabled" | "alwaysInProgress" | "children" | "tabIndex"
  > {
  color?: PrimaryButtonColors;
  className?: string;
}

export interface SecondaryButtonProps
  extends Pick<
    ButtonProps,
    "onClick" | "disabled" | "alwaysInProgress" | "children" | "tabIndex"
  > {
  color?: SecondaryButtonColors;
  className?: string;
}

const TrackedButton = (props: ButtonProps) => {
  const { track } = useAnalytics();
  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    void track("click", {
      name: (e.target as HTMLButtonElement).textContent || "unknown",
    });
    if (props.onClick) {
      void props.onClick(e);
    }
  };
  return <Button {...{ ...props, onClick }} />;
};

export const PrimaryButton = (props: PrimaryButtonProps) => {
  const color = props.color || "blue";
  const container = classnames(styles.primary, styles[color], props.className);
  const spinner = classnames(styles[color]);
  return <TrackedButton {...props} customCss={{ container, spinner }} />;
};

export const SecondaryButton = (props: SecondaryButtonProps) => {
  const color = props.color || "black";
  const container = classnames(
    props.className,
    styles.secondary,
    styles[color]
  );
  return <TrackedButton {...props} customCss={{ container }} />;
};
