import {
  authSubRoute,
  coreRoutes,
  urlBuilder,
} from "@specsheet-common/shared-types";
import { useMutation } from "src/service/reactQuery";

export const useActivateUser = () => {
  return useMutation<void, void>({
    url: urlBuilder({
      root: coreRoutes.auth,
      sub: authSubRoute.activateUser,
    }),
  });
};
