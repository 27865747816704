import {
  Box,
  Button,
  ButtonProps,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React from "react";
import { theme } from "src/ui/common/NewTheme";

interface NoAccessPlaceholderProps {
  title: string;
  description: string;
  icon?: JSX.Element;
  cta?: ButtonProps;
  buttonTitle?: string;
  buttonClick?(): void;
  showRefresh?: boolean;
  bgColor?: "gray" | "white";
}

export const NoAccessPlaceholder = ({
  title,
  description,
  icon,
  cta,
  buttonTitle,
  buttonClick,
  showRefresh,
  bgColor = "gray",
}: NoAccessPlaceholderProps) => {
  const bg =
    bgColor === "gray"
      ? theme.newColors.neutral.gray200
      : theme.newColors.neutral.white;

  const ctaDataPw = `${cta?.title
    ?.toLowerCase()
    .split(" ")
    .join("-")}-fallback-btn`;

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%", backgroundColor: bg }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "100%",
            width: 480,
            margin: "auto",
            padding: theme.spacing(4),
          }}
        >
          <Box sx={{ width: 376, textAlign: "center" }}>{icon}</Box>
          <Typography
            variant="subtitle1"
            sx={{ marginTop: theme.spacing(4), textAlign: "center" }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginTop: theme.spacing(2), textAlign: "center" }}
          >
            {description}
          </Typography>
          {showRefresh ? (
            <Button
              color="primary"
              variant="contained"
              size="small"
              sx={{ marginTop: theme.spacing(4), width: "160px" }}
              onClick={() => window.location.reload()}
            >
              Refresh Page
            </Button>
          ) : null}
          {cta && (
            <Box mt={4}>
              <Button
                variant="contained"
                endIcon={cta.endIcon}
                onClick={cta.onClick}
                sx={{
                  px: 3,
                  height: "32px",
                  "& .MuiButton-endIcon": {
                    marginLeft: 2,
                  },
                }}
                size="small"
                data-pw={ctaDataPw}
                {...cta}
              >
                {cta.title}
              </Button>
            </Box>
          )}
          {buttonTitle !== undefined && (
            <Box textAlign="center" sx={{ marginTop: theme.spacing(4) }}>
              <b>
                <a onClick={buttonClick}>{buttonTitle}</a>
              </b>
            </Box>
          )}
        </Stack>
      </Box>
    </ThemeProvider>
  );
};
