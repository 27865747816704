import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import * as datadog from "src/util/datadog";

/**
 * This error comes from inside the react-virtuoso library, not from our own
 * resize oberver code. The position of the RV team seems to be that the
 * generated log is harmless as it would not be logged without explicit
 * handlers like handleError below.
 *
 * The problem for us is that when we send thousands of these messages to
 * raygun, they start to throttle us, and this throttling happens upstream of
 * any filters they allow us to apply, so we are just swallowing the error here
 */
window.addEventListener("error", (e) => {
  if (
    e.message ===
      "ResizeObserver loop completed with undelivered notifications." ||
    e.message === "ResizeObserver loop limit exceeded"
  ) {
    e.stopImmediatePropagation();
  }
});

// Global error handler, includes errors that occur in the handler
window.addEventListener("error", (event: ErrorEvent) =>
  datadog.error(event.error, { eventType: "error" })
);

// Global unhandled rejection handler
window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) =>
  datadog.error(event.reason, { eventType: "unhandledrejection" })
);

ReactDOM.render(<App />, document.querySelector("#root"));
