import {
  Link,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { ExpandableString } from "src/ui/components/shared/atoms/ExpandableString";
import { useGenericTableStore } from "../../../../hooks/contexts/useGenericTableStore";
import { BUILD_CAMPAIGN_PAGE_TABLE_ID } from "../../../pages/BuildCampaignPage/constants";
import { idFlavor, specSheetTabSpecial } from "@specsheet-common/shared-types";
import { Id } from "../../shared/organisms/Table";

interface BookingFieldChange {
  fieldName: string;
  valueBefore: string;
  valueAfter: string;
}

interface BookingFieldUpdateChangeView {
  bookingId: idFlavor<"Booking">;
  description: string;
  changes: BookingFieldChange[];
}

interface BookingFieldUpdatesListProps {
  bookingFieldChanges: BookingFieldUpdateChangeView[];
  goToRowId: ((id: Id) => void) | null;
}

export const BookingFieldUpdatesList = observer(
  ({ bookingFieldChanges, goToRowId }: BookingFieldUpdatesListProps) => {
    const genericTableStore = useGenericTableStore(
      BUILD_CAMPAIGN_PAGE_TABLE_ID
    );

    return (
      <List>
        {bookingFieldChanges.map((fieldChange) => {
          return (
            <ListItem key={fieldChange.bookingId} sx={{ width: "100%" }}>
              <Stack width="100%">
                <Stack direction="row" spacing={0.2} alignItems="center">
                  <Tooltip title="View placement">
                    <Link
                      onClick={
                        goToRowId
                          ? () => {
                              genericTableStore.selectTab(
                                specSheetTabSpecial.Overview
                              );
                              goToRowId(fieldChange.bookingId);
                            }
                          : undefined
                      }
                    >
                      <Typography variant="smallBold">
                        {fieldChange.description}
                      </Typography>
                    </Link>
                  </Tooltip>
                </Stack>
                {fieldChange.changes.map((change) => {
                  const changeString = `${change.valueBefore} -> ${change.valueAfter}`;
                  return (
                    <Stack
                      direction="row"
                      key={`${fieldChange.bookingId}-${change.fieldName}`}
                      pl={1}
                      spacing={1}
                    >
                      <Tooltip title={changeString}>
                        <ExpandableString
                          typographyProps={{
                            variant: "small",
                            sx: { fontStyle: "italic" },
                          }}
                        >
                          <span
                            css={{ fontWeight: 500 }}
                          >{`${change.fieldName}: `}</span>
                          {changeString}
                        </ExpandableString>
                      </Tooltip>
                    </Stack>
                  );
                })}
              </Stack>
            </ListItem>
          );
        })}
      </List>
    );
  }
);
