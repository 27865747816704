import { Box, Button, Grid, Popover, Typography } from "@mui/material";
import { N_OrganisationView, routePaths } from "@specsheet-common/shared-types";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/hooks/contexts/useAuth";
import { useLocationState } from "src/hooks/utils/useLocationState";
import { authOrganisationConverter } from "src/legacyConvertersHelpers/organisation";
import { useQueryOrganisations } from "src/service/queryHooks/organisations/queries/useQueryOrganisations";
import { ButtonPlusIcon, MenuSwitchIcon } from "../../shared/atoms/icon/icons";
import { OrganisationTile } from "./OrganisationTile";
import { ContactSalesDialog } from "../../campaignUsers/dialogs/ContactSalesDialog";
import { useDialogContext } from "../../../../hooks/contexts/useDialogContext";
import { CalendlyDialog } from "../../campaignUsers/dialogs/CalendlyDialog";

interface MenuButtonProps {
  expanded: boolean;
  onContextOpen(): void;
  onContextClose(): void;
}

export const OrganisationList = ({
  expanded,
  onContextOpen,
  onContextClose,
}: MenuButtonProps) => {
  const { organisation } = useAuth();
  const { changeOrganisation } = useLocationState();
  const navigate = useNavigate();
  const { onOpen: onDialogOpen, onClose: onDialogClose } = useDialogContext();

  const [contextAnchor, setContextAnchor] = useState<HTMLDivElement | null>(
    null
  );

  const contextPositionAnchor = useRef<HTMLDivElement>(null);

  const handleNavToOrganisationSettings = () => {
    navigate(routePaths.orgManagement);
  };

  const handleOrganisationSwitcher = (e: React.MouseEvent) => {
    onContextOpen();
    setContextAnchor(contextPositionAnchor.current);
    e.stopPropagation();
  };

  const handleChooseOrg = (org: N_OrganisationView) => {
    onContextClose();
    setContextAnchor(null);
    changeOrganisation(routePaths.orgWorkspaces, org.organisationId);
  };

  const handleAddOrg = () => {
    onDialogOpen({
      title: "Contact Sales",
      body: (
        <ContactSalesDialog
          chatButtonProps={{ id: "ss-intercom-launcher" }}
          onClose={onDialogClose}
          onBookCall={() =>
            onDialogOpen({
              title: "Contact Sales",
              body: <CalendlyDialog onClose={onDialogClose} />,
              maxWidth: "md",
              fullWidth: true,
            })
          }
        />
      ),
      maxWidth: "md",
      fullWidth: true,
    });
  };
  const handleClosePopover = () => {
    onContextClose();
    setContextAnchor(null);
  };

  const query = useQueryOrganisations({
    staleTime: Number.POSITIVE_INFINITY,
  });
  const organisations = query.data || [];

  const tssMenuIconHoverable = {
    height: "32px",
    width: "32px",
    padding: "4px",
    borderRadius: "4px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#E5E5E7",
    },
    "&:focus": {
      boxShadow: "0px 0px 0px 3px #B3D1FF",
      outline: "none",
    },
  };

  const switcherIcon = (
    <Grid item xs="auto">
      <Box
        data-pw="org-switcher"
        onClick={(e: React.MouseEvent) => handleOrganisationSwitcher(e)}
        sx={tssMenuIconHoverable}
        tabIndex={1}
        title="Switch organisation"
      >
        <MenuSwitchIcon size="24px" />
      </Box>
    </Grid>
  );

  return (
    <Box ref={contextPositionAnchor}>
      <OrganisationTile
        expanded={expanded}
        org={authOrganisationConverter(organisation)}
        onClick={handleNavToOrganisationSettings}
        variant="large"
      >
        {switcherIcon}
      </OrganisationTile>
      <Popover
        anchorEl={contextAnchor}
        open={Boolean(contextAnchor)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        marginThreshold={0}
        onClose={handleClosePopover}
      >
        <Box sx={{ width: "240px" }} data-pw="org-list">
          <Box sx={{ maxHeight: "calc(100vh - 580px)", overflowY: "auto" }}>
            {organisations.map((org) => {
              return (
                <OrganisationTile
                  key={org.organisationId}
                  expanded={expanded}
                  org={org}
                  onClick={() => handleChooseOrg(org)}
                  variant="small"
                />
              );
            })}
          </Box>
          <Box sx={{ padding: "11px" }}>
            <Button
              onClick={handleAddOrg}
              color="gray"
              sx={{ padding: "4px", width: "100%" }}
            >
              <Typography variant="body3">Create Organisation</Typography>
              <div
                style={{
                  marginLeft: "8px",
                  marginTop: "-1px",
                  marginBottom: "-5px",
                }}
              >
                <ButtonPlusIcon size="16px" />
              </div>
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
