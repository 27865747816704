import { useError } from "src/hooks/utils/useError";
import {
  presignedUrlUpload,
  useGenerateS3PresignedUrl,
} from "src/service/file-upload";

export const useMaybeNewFileOrClear = (filePathPrefix: string) => {
  const { errorAlert } = useError();
  const generatePresignedUrl = useGenerateS3PresignedUrl();

  const getFileUrl = async (file: File | null | "empty") => {
    if (file !== null && file !== "empty") {
      try {
        const logo = presignedUrlUpload({
          file,
          presignedUrl: await generatePresignedUrl({
            public: true,
            fileName: `${filePathPrefix}-${file.name}`,
          }),
        });

        return await logo;
      } catch (e) {
        errorAlert("Something went wrong", e);
      }
    }
    return null;
  };

  return async (file: File | null | "empty") => ({
    file: await getFileUrl(file),
    clear: file === "empty",
  });
};
