import { idFlavor } from "@specsheet-common/shared-types";

import { useLocation, useNavigate } from "react-router-dom";

const locationStateKinds = {
  WORKSPACE_CHANGE: "WORKSPACE_CHANGE",
  ORGANISATION_CHANGE: "ORGANISATION_CHANGE",
} as const;

interface LocationState {
  workspaceId: idFlavor<"Workspace"> | null;
  organisationId: idFlavor<"Organisation"> | null;
  changeWorkspace(to: string, workspaceId: idFlavor<"Workspace">): void;
  changeOrganisation(
    to: string,
    organisationId: idFlavor<"Organisation">,
    workspaceId?: idFlavor<"Workspace">
  ): void;
}

export type WireState =
  | {
      kind: typeof locationStateKinds.WORKSPACE_CHANGE;
      data: { workspaceId: idFlavor<"Workspace"> };
    }
  | {
      kind: typeof locationStateKinds.ORGANISATION_CHANGE;
      data: {
        organisationId: idFlavor<"Organisation">;
        workspaceId?: idFlavor<"Workspace">;
      };
    }
  | null;

export const useLocationState = (): LocationState => {
  const navigate = useNavigate();
  const changeWorkspace = (to: string, workspaceId: idFlavor<"Workspace">) => {
    navigate(to, {
      state: {
        kind: locationStateKinds.WORKSPACE_CHANGE,
        data: { workspaceId },
      },
    });
  };

  const changeOrganisation = (
    to: string,
    organisationId: idFlavor<"Organisation">,
    workspaceId: idFlavor<"Workspace">
  ) => {
    navigate(to, {
      state: {
        kind: locationStateKinds.ORGANISATION_CHANGE,
        data: { organisationId, workspaceId },
      },
    });
  };

  const defaultData = {
    changeWorkspace,
    changeOrganisation,
    workspaceId: null,
    organisationId: null,
  };

  const state = useLocation().state as WireState | undefined;

  switch (state?.kind) {
    case locationStateKinds.WORKSPACE_CHANGE: {
      return { ...defaultData, workspaceId: state.data.workspaceId };
    }
    case locationStateKinds.ORGANISATION_CHANGE: {
      return {
        ...defaultData,
        organisationId: state.data.organisationId,
        workspaceId: state.data.workspaceId || null,
      };
    }
    default: {
      return defaultData;
    }
  }
};
