import React, { FocusEventHandler } from "react";
import { Box, Menu, MenuProps, PaperProps } from "@mui/material";
import { MenuItemProps, SsMenuItem } from "./SsMenuItem";

interface SSMenuProps {
  menuItems: MenuItemProps[];
  button(onClick: (event: React.MouseEvent<HTMLElement>) => void): JSX.Element;
  transformOrigin?: MenuProps["transformOrigin"];
  anchorOrigin?: MenuProps["anchorOrigin"];
  width?: number;
  onBlur?: FocusEventHandler;
  fullSizeButton?: boolean;
  paperProps?: Partial<PaperProps>;
}

export const SSMenu = ({
  menuItems,
  button,
  transformOrigin,
  anchorOrigin,
  width = 200,
  fullSizeButton,
  onBlur,
  paperProps,
}: SSMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          ...(fullSizeButton
            ? {
                height: "100%",
                width: "100%",
              }
            : {}),
        }}
      >
        {button(handleClick)}
      </Box>
      {open ? (
        <Menu
          PaperProps={paperProps}
          anchorEl={anchorEl}
          open={open}
          sx={{
            "& .MuiPaper-root": {
              width,
              maxHeight: 550,
            },
            "& .MuiList-root": {
              padding: 0,
            },
          }}
          onBlur={onBlur}
          onClose={(event: React.SyntheticEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            handleClose();
          }}
          onClick={handleClose}
          transformOrigin={
            transformOrigin || { horizontal: "right", vertical: "top" }
          }
          anchorOrigin={
            anchorOrigin || { horizontal: "right", vertical: "bottom" }
          }
        >
          {menuItems.map((menuItem, index) => {
            if (menuItem.kind !== "group") {
              return (
                <SsMenuItem
                  key={`${menuItem.label}-${index}`}
                  handleClose={handleClose}
                  {...menuItem}
                />
              );
            }

            return (
              <SsMenuItem key={`${menuItem.label}-${index}`} {...menuItem} />
            );
          })}
        </Menu>
      ) : null}
    </React.Fragment>
  );
};
