import { UseQueryOptions } from "react-query";
import {
  coreRoutes,
  N_NotificationView,
  notificationSubRoute,
  urlBuilder,
} from "@specsheet-common/shared-types";
import { queryKeys } from "src/service/queryHooks/keys";
import { useAuth } from "src/hooks/contexts/useAuth";
import { useQuery } from "src/service/reactQuery";

interface HookProps {
  staleTime?: UseQueryOptions["staleTime"];
}

export const useQueryNotifications = (props?: HookProps) => {
  const { isAuthenticated } = useAuth();
  return useQuery<never, N_NotificationView[]>({
    queryKey: queryKeys.notifications.fetchNotifications,
    url: urlBuilder({
      root: coreRoutes.notifications,
      sub: notificationSubRoute.list,
    }),
    enabled: isAuthenticated,
    staleTime: props?.staleTime,
  });
};
