import { ErrorFallback } from "./ErrorFallback";
import React, { ErrorInfo } from "react";
import * as datadog from "src/util/datadog";

interface State {
  hasError: boolean;
  error?: Error;
}

class ErrorWithCause extends Error {
  cause: unknown;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export class ErrorBoundary extends React.Component<{}, State> {
  constructor(props: never) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    const renderingError = new ErrorWithCause(error.message);
    renderingError.name = `ReactRenderingError`;
    renderingError.stack = info.componentStack;
    renderingError.cause = error;
    datadog.error(renderingError, {});
  }

  render() {
    if (this.state.hasError) {
      return <ErrorFallback error={this.state.error} />;
    }

    return this.props.children;
  }
}
