import {
  PaperProps,
  PopoverActions,
  PopoverOrigin,
  PopoverPosition,
  PopoverReference,
  SxProps,
} from "@mui/material";
import { Theme } from "@mui/system";
import React, { createContext, useRef, useState } from "react";

interface MenuItem {
  label: string;
  onClick(e: React.MouseEvent): void;
  disabled?: boolean;
  id?: string;
  divider?: boolean;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  color?: string;
}

interface MenuProps {
  anchorEl: Element;
  anchorOrigin?: PopoverOrigin;
  items: MenuItem[];
  transformOrigin?: PopoverOrigin;
  PaperProps?: Partial<PaperProps>;
  action?: React.Ref<PopoverActions>;
  onClose?(): void;
  anchorReference?: PopoverReference;
  anchorPosition?: PopoverPosition;
  sx?: SxProps<Theme>;
}

interface PopoverContextProps {
  onOpen(props: MenuProps): void;
  onClose(): void;
  menuProps?: MenuProps;
}

export const MenuContext = createContext<PopoverContextProps | null>(null);

export const MenuProvider: React.FC = ({ children }) => {
  const [menuProps, setMenuProps] = useState<MenuProps | undefined>();

  const menuPropsRef = useRef<MenuProps>();

  menuPropsRef.current = menuProps;

  const onOpen = (props: MenuProps) => {
    setMenuProps(props);
  };

  const onClose = () => {
    const currentMenuProps = menuPropsRef.current;
    if (currentMenuProps && currentMenuProps.onClose) {
      currentMenuProps.onClose();
    }

    setMenuProps(undefined);
  };

  return (
    <MenuContext.Provider
      value={{
        onOpen,
        onClose,
        menuProps,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
