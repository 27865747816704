import { PlacementView } from "../adl-gen/kachemedia/specsheet/campaigns";
import { Service } from "../service/service";
import { action, makeObservable, observable } from "mobx";
import { fetchById } from "../service/placements/fetchById";
import { idFlavor } from "@specsheet-common/shared-types";

export class PlacementStore {
  isInitiated: boolean = false;
  isLoading: boolean = false;
  placement: PlacementView | null = null;
  lastFetchDate: Date = new Date();

  constructor(private readonly service: Service) {
    this.service = service;

    makeObservable(this, {
      placement: observable,
      isInitiated: observable,
      isLoading: observable,
      lastFetchDate: observable,
      setPlacement: action,
      setIsLoading: action,
      setCurrentLastFetchDate: action,
      fetch: action,
      clear: action,
    });
  }

  setCurrentLastFetchDate = (): Date => {
    const newDate = new Date();
    this.lastFetchDate = newDate;
    return newDate;
  };

  setPlacement = (placement: PlacementView | null) => {
    this.placement = placement;
  };

  setIsLoading = (status: boolean) => {
    this.isLoading = status;
  };

  fetch = async ({
    placementId,
  }: {
    placementId: idFlavor<"Placement">;
  }): Promise<void> => {
    this.isInitiated = true;

    const startDate = this.setCurrentLastFetchDate();
    this.isLoading = true;

    const data = await fetchById({
      service: this.service,
      placementId,
    });

    if (this.lastFetchDate.getTime() > startDate.getTime()) {
      return;
    }

    this.setPlacement(data);
    this.isLoading = false;
  };

  clear = () => {
    this.setPlacement(null);
  };
}
