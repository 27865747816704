import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "src/ui/common/NewTheme";

export interface MenuButtonProps {
  expanded: boolean;
  icon: JSX.Element;
  name: string;
  alertCount?: number;
  typographyVariant?:
    | "subtitle1"
    | "subtitle2"
    | "subtitle3"
    | "body1"
    | "body2"
    | "body3";
  id?: string;
  onClick?(): void;
  isActiveState?: boolean;
}

export const MenuButton = ({
  expanded,
  icon,
  name,
  alertCount = 0,
  typographyVariant = "subtitle2",
  id,
  onClick,
  isActiveState = false,
  children,
}: React.PropsWithChildren<MenuButtonProps>) => {
  const tssMenuItemHoverable = {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    cursor: "pointer",
    outline: isActiveState ? "solid 1px #0000FF" : "none",
    "&:hover": {
      backgroundColor: "#F4F4F5",
    },
    "&:focus": {
      boxShadow: "0px 0px 0px 3px #B3D1FF",
      outline: isActiveState ? "solid 1px #0000FF" : "none",
    },
  };

  return (
    <Box sx={{ padding: theme.spacing(1) }}>
      <Box sx={tssMenuItemHoverable} id={id} onClick={onClick} tabIndex={1}>
        <Grid container>
          <Grid item xs="auto">
            <Box
              sx={{
                height: "28px",
                width: "32px",
                padding: theme.spacing(0.5),
                paddingLeft: theme.spacing(1),
              }}
            >
              {icon}
            </Box>
          </Grid>
          {expanded && (
            <Grid item xs>
              <Grid container>
                <Grid item xs="auto">
                  <Typography
                    variant={typographyVariant}
                    sx={{
                      color: "#5D5F69",
                      lineHeight: "28px",
                      paddingLeft: theme.spacing(2),
                    }}
                  >
                    {name}
                  </Typography>
                </Grid>
                {alertCount > 0 && (
                  <Grid item xs="auto" sx={{ paddingTop: theme.spacing(1) }}>
                    <Typography
                      variant="body3"
                      sx={{
                        backgroundColor: "#CD4B5B",
                        color: "#FFFFFF",
                        height: "16px",
                        borderRadius: "16px",
                        paddingLeft: theme.spacing(1),
                        paddingRight: theme.spacing(1),
                        marginLeft: theme.spacing(2),
                      }}
                    >
                      {alertCount}
                    </Typography>
                  </Grid>
                )}
                {expanded && children}
                <Grid item xs></Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
