import React from "react";
import { MenuItemAction, SsMenuItemAction } from "./SsMenuItemAction";
import { MenuItemGroup, SsMenuItemGroup } from "./SsMenuItemGroup";

export type MenuItemProps = MenuItemAction | MenuItemGroup;

export const SsMenuItem = (props: MenuItemProps) => {
  if (props.kind === "group") {
    return (
      <SsMenuItemGroup
        kind={props.kind}
        label={props.label}
        icon={props.icon}
        children={props.children}
        disabled={props.disabled}
      />
    );
  }

  return <SsMenuItemAction {...props} />;
};
