import { useFlags } from "launchdarkly-react-client-sdk";
import * as datadog from "src/util/datadog";
import * as ld from "launchdarkly-react-client-sdk";
import { launchDarkly } from "src/constants/launchDarkly";
import { apiWithHeaders as api } from "src/configuration/api";
import { getApplicationVersion, z } from "@specsheet-common/shared-tools";
import { useEffect, useState } from "react";
import React from "react";
import { NoAccessPlaceholder } from "../molecules/NoAccessState";
import * as SVG from "../../../components/shared/atoms/icon/icons-svg";
import { Box } from "@mui/system";

const Inner: React.FC = ({ children }) => {
  const { forbidLoadOnVersionMismatch } = useFlags();
  const [match, setMatch] = useState<boolean>();

  useEffect(() => {
    const clientVersion = getApplicationVersion();

    const checkVersion = async () => {
      try {
        if (forbidLoadOnVersionMismatch) {
          const res = await api.get<{ applicationVersion: string }>(
            "/applicationVersion"
          );
          const { applicationVersion } = z
            .object({
              applicationVersion: z.string().min(1),
            })
            .parse(res.data);

          // eslint-disable-next-line no-console
          console.groupCollapsed("AppVersionLockoutWrapper.versions");
          // eslint-disable-next-line no-console
          console.log("server version:", applicationVersion);
          // eslint-disable-next-line no-console
          console.log("client version:", clientVersion);
          // eslint-disable-next-line no-console
          console.groupEnd();

          setMatch(applicationVersion === clientVersion);
        }
      } catch (e) {
        datadog.error(e, { eventType: "versionMismatch", clientVersion });
      }
    };

    void checkVersion();
    const timer = setInterval(checkVersion, 15_000);
    return () => {
      clearInterval(timer);
    };
  }, [forbidLoadOnVersionMismatch]);

  if (forbidLoadOnVersionMismatch === undefined) {
    return null;
  }

  if (forbidLoadOnVersionMismatch && match === undefined) {
    return null;
  }

  if (forbidLoadOnVersionMismatch && !match) {
    return (
      <Box height="100%" display="flex" justifyContent="center">
        <NoAccessPlaceholder
          icon={SVG.NoAccessArchivedEmptyStateSVG}
          title="A new version of the Spec Sheet has been released."
          description="Please refresh the page to access the latest version"
          showRefresh
        />
      </Box>
    );
  }
  return <>{children}</>;
};

export const AppVersionLockoutWrapper: React.FC = ({ children }) => {
  const [Provider, setProvider] = useState<
    (({ children }: { children: React.ReactNode }) => JSX.Element) | undefined
  >(undefined);

  useEffect(() => {
    async function fetchData() {
      const provider = await ld.asyncWithLDProvider({
        clientSideID: launchDarkly.clientSideId,
        context: {
          kind: "user",
          key: "abc123",
          name: "System User",
          email: "support@thespecsheet.com",
        },
      });

      setProvider(() => provider);
    }

    void fetchData();
  }, []);

  return Provider ? (
    <Provider>
      <Inner>{children}</Inner>
    </Provider>
  ) : null;
};
