import {
  auth0SubRoute,
  coreRoutes,
  ResendAuth0VerificationEmailReq,
  urlBuilder,
} from "@specsheet-common/shared-types";
import { useMutation } from "src/service/reactQuery";

interface MutationProps {
  // who need types anyway
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess?(data: any): void;
  onError?(): void;
}

export const useResendVerificationEmail = ({
  onError,
  onSuccess,
}: MutationProps) => {
  return useMutation<ResendAuth0VerificationEmailReq, unknown>({
    url: urlBuilder({
      root: coreRoutes.auth0,
      sub: auth0SubRoute.resendVerification,
    }),
    successMessage: "Verification email resent",
    onError,
    onSuccess,
  });
};
