import React, { createContext, useCallback, useEffect, useState } from "react";

interface OnUnmountWrapperProps {
  onUnmount(): void;
}

export const OnUnmountWrapper: React.FC<OnUnmountWrapperProps> = ({
  onUnmount,
  children,
}) => {
  useEffect(() => {
    return () => {
      onUnmount();
    };
  }, []);

  return <>{children}</>;
};

type DrawerProps = {
  body: JSX.Element;
  title?: string;
  onClose?(): void;
  id?: string;
  expandable?: boolean;
  expanded?: boolean;
  drawerType?: string; //for rerender trace last.
  selected?: string;
  onToggleContent?(): void;
};

export interface DrawerContextProps {
  open(props: DrawerProps | ((currentProps: DrawerProps) => DrawerProps)): void;
  close(): void;
  expandToggle(): void;
  drawerProps?: DrawerProps;
  onClickAway(disableByPointer: boolean, disableByDialog: boolean): void;
  displayHistoryDrawer: boolean;
  setDisplayHistoryDrawer(state: boolean): void;
  onToggleContentHandler: (() => void) | undefined;
  disableClickAway: boolean;
  setDisableClickAway(state: boolean): void;
  disableClickAwayByOpeningDialog: boolean;
  setDisableClickAwayByOpeningDialog(state: boolean): void;
}
export const DrawerContext = createContext<DrawerContextProps | null>(null);

export const DrawerProvider: React.FC = ({ children }) => {
  const [drawerProps, setDrawerProps] = useState<DrawerProps | undefined>();
  const [expanded, setExpanded] = useState<Map<string | undefined, boolean>>(
    new Map()
  );
  const [viewChanges, setViewChanges] = useState(false);
  const [disableClickAway, setDisableClickAway] = useState(true);
  const [disableClickAwayByOpeningDialog, setDisableClickAwayByOpeningDialog] =
    useState(false);

  const onClose = useCallback(() => {
    setDrawerProps((currentDrawerProps) => {
      if (currentDrawerProps === undefined) {
        return undefined;
      }
      if (currentDrawerProps && currentDrawerProps.id) {
        setExpanded((prev) =>
          prev.set(currentDrawerProps.id, Boolean(currentDrawerProps.expanded))
        );
      }

      if (currentDrawerProps && currentDrawerProps.onClose) {
        currentDrawerProps.onClose();
      }
      setViewChanges(false);
      return undefined;
    });
  }, []);

  const onToggleContentHandler = useCallback(() => setViewChanges(false), []);

  const onOpen = useCallback(
    (
      props:
        | DrawerProps
        | ((currentProps: DrawerProps | undefined) => DrawerProps)
    ) => {
      setDrawerProps((pastProps) => {
        const parsedProps =
          typeof props === "function" ? props(pastProps) : props;
        if (parsedProps.id && !expanded.has(parsedProps.id)) {
          setExpanded((state) =>
            state.set(parsedProps.id, Boolean(parsedProps.expanded))
          );
        }
        return {
          ...props,
          body: (
            <OnUnmountWrapper onUnmount={onClose}>
              {parsedProps.body}
            </OnUnmountWrapper>
          ),
          expanded: parsedProps.id
            ? !!expanded.get(parsedProps.id)
            : Boolean(parsedProps.expanded),
        };
      });
    },
    [expanded, onClose]
  );

  const onExpandToggle = useCallback(() => {
    setDrawerProps((currentDrawerProps) => {
      if (currentDrawerProps) {
        if (currentDrawerProps.id) {
          setExpanded((prev) =>
            prev.set(currentDrawerProps.id, !currentDrawerProps.expanded)
          );
        }
        return {
          ...currentDrawerProps,
          expanded: !currentDrawerProps.expanded,
        };
      }
      return currentDrawerProps;
    });
  }, []);

  const onClickAway = useCallback(
    (disableClick: boolean, disableByDialog: boolean) => {
      if (drawerProps && disableClick && !disableByDialog) {
        onClose();
      }
    },
    [drawerProps, onClose]
  );

  useEffect(() => {
    const escListener = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClickAway(true, disableClickAwayByOpeningDialog);
      }
    };
    window?.addEventListener("keydown", escListener);
    return () => window?.removeEventListener("keydown", escListener);
  }, [disableClickAwayByOpeningDialog, onClickAway, onClose]);

  return (
    <DrawerContext.Provider
      value={{
        open: onOpen,
        close: onClose,
        expandToggle: onExpandToggle,
        drawerProps,
        onClickAway,
        displayHistoryDrawer: viewChanges,
        setDisplayHistoryDrawer: setViewChanges,
        onToggleContentHandler,
        disableClickAway,
        setDisableClickAway,
        disableClickAwayByOpeningDialog,
        setDisableClickAwayByOpeningDialog,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};
