import React from "react";
import { idFlavor } from "@specsheet-common/shared-types";

interface MenuContextProps {
  isExpanded: boolean;
  selectedOrganisation?: idFlavor<"Organisation">;
  selectedWorkspace?: idFlavor<"Workspace">;
}

export const MenuContext = React.createContext<MenuContextProps>({
  isExpanded: true,
});
