import { CssBaseline, Fade, ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { observer } from "mobx-react";
import { SnackbarProvider } from "notistack";
import * as React from "react";
import { Suspense } from "react";
import "react-datepicker/dist/react-datepicker.min.css";
import "react-mde/lib/styles/css/react-mde-all.css";
import { QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import { AuthProvider } from "src/contexts/AuthContext";
import { Auth0ProviderWithConfig } from "src/contexts/Auth0Context";
import { DialogProvider } from "src/contexts/DialogContext";
import { DrawerProvider } from "src/contexts/DrawerContext";
import { theme } from "src/ui/common/NewTheme";
import { DialogWithContext } from "src/ui/components/shared/organisms/DialogWithContext";
import { ModalCanvas } from "src/ui/components/shared/organisms/ModalCanvas";
import { ErrorBoundary } from "src/ui/components/shared/system/error-handler/ErrorBoundary";
import { ProgressFallback } from "src/ui/components/shared/system/ProgressFallback";
import { INTERCOM_APP_ID } from "./constants/intercom";
import { AnalyticsProvider } from "./contexts/AnalyticsContext";
import { PopoverProvider } from "./contexts/PopoverContext";
import { RootMobxStoreProvider } from "./contexts/RootMobxStoreContext";
import { WebsocketProvider } from "./contexts/WebsocketContext";
import { TssRoutes as Routes } from "./routes";
import { OnboardingProvider } from "./contexts/OnboardingContext";
import { PlacementDetailsControlProvider } from "./contexts/PlacementDetailsControlContext";
import { SidePanelProvider } from "./contexts/SidePanelContext";
import { MenuProvider } from "./contexts/MenuContext";
import { ApplicationVersionWithContext } from "./ui/components/shared/organisms/ApplicationVersionWithContext";
import { ApplicationVersionProvider } from "./contexts/ApplicationVersionContext";
import { useFavicons } from "./useFavicons";
import { enAU } from "date-fns/locale";
import { queryClient } from "src/service/reactQuery";
import { FeatureFlagProvider } from "./service/featureFlags";
import {GraphqlProvider} from "./contexts/GraphqlContext";
import { AppVersionLockoutWrapper } from "./ui/components/shared/system/AppVersionLockoutWrapper";
import { ReleaseLockoutWrapper } from "./ui/components/shared/system/ReleaseLockoutWrapper";

export const App = observer(() => {
  useFavicons();

  return (
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        TransitionComponent={Fade}
      >
        <DialogProvider>
          <ErrorBoundary>
            <ThemeProvider theme={theme}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/*@ts-ignore this code flaps between compiling and not depending on env */}
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={enAU}
              >
                <CssBaseline />

                <Auth0ProviderWithConfig>
                  <RootMobxStoreProvider>
                    <GraphqlProvider>
                      <QueryClientProvider client={queryClient}>
                        <Suspense fallback={<ProgressFallback />}>
                          <BrowserRouter>
                            <ReleaseLockoutWrapper>
                              <AppVersionLockoutWrapper>
                                <WebsocketProvider>
                                  <ApplicationVersionProvider>
                                    <AuthProvider>
                                      <FeatureFlagProvider>
                                        <AnalyticsProvider>
                                          <ModalCanvas />
                                          <PopoverProvider>
                                            <MenuProvider>
                                              <DrawerProvider>
                                                <SidePanelProvider>
                                                  <PlacementDetailsControlProvider>
                                                    <OnboardingProvider>
                                                      <Routes />
                                                    </OnboardingProvider>
                                                    <DialogWithContext />
                                                    <ApplicationVersionWithContext />
                                                  </PlacementDetailsControlProvider>
                                                </SidePanelProvider>
                                              </DrawerProvider>
                                            </MenuProvider>
                                          </PopoverProvider>
                                        </AnalyticsProvider>
                                      </FeatureFlagProvider>
                                    </AuthProvider>
                                  </ApplicationVersionProvider>
                                </WebsocketProvider>
                              </AppVersionLockoutWrapper>
                            </ReleaseLockoutWrapper>
                          </BrowserRouter>
                        </Suspense>
                      </QueryClientProvider>
                    </GraphqlProvider>
                  </RootMobxStoreProvider>
                </Auth0ProviderWithConfig>
              </LocalizationProvider>
            </ThemeProvider>
          </ErrorBoundary>
        </DialogProvider>
      </SnackbarProvider>
    </IntercomProvider>
  );
});
