import { useAuth0 } from "@auth0/auth0-react";
import { R } from "@specsheet-common/shared-tools";
import { useCallback } from "react";
import * as datadog from "src/util/datadog";

export interface GetAccessToken {
  getAccessToken(): Promise<string>;
}

export const useAccessToken = (): GetAccessToken => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const getAccessToken = useCallback(async () => {
    try {
      return await getAccessTokenSilently();
    } catch (e) {
      if (R.isError(e) && "error" in e) {
        datadog.error(e, { eventType: "Auth0AccessToken." + e.error });
        if (e.error === "login_required" || e.error === "consent_required") {
          await loginWithRedirect();
        }
      } else {
        datadog.error(e, { eventType: "Auth0AccessToken.unknown" });
      }
      throw e;
    }
  }, [getAccessTokenSilently, loginWithRedirect]);
  return {
    getAccessToken,
  };
};
