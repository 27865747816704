import { BrowserHttp } from "./service/browser-http";
import { HttpWithRetries } from "./service/retrying-http";
import { RESOLVER } from "./adl-gen/resolver";
import { HttpService } from "./service/http-service";
import { HttpServiceError } from "./service/http-service-error";
import { legacyApiURL } from "./constants/url";
import { useAccessToken } from "./hooks/utils/useAccessToken";

export const useSharedResources = () => {
  const http = new HttpWithRetries(new BrowserHttp(), 8, 100);

  const { getAccessToken } = useAccessToken();

  const service = new HttpService(
    http,
    `${legacyApiURL}/_a`,
    RESOLVER,
    getAccessToken,
    (error: HttpServiceError) => {
      // Check if it's an authorization error
      if (error.status === 401) {
        const publicMessageFragment = error.publicMessage
          ? `: ${error.publicMessage}`
          : "";
        // eslint-disable-next-line no-console
        console.log(`An authorization error occurred ${publicMessageFragment}`);
        return;
      }
    }
  );

  return {
    service,
  };
};
