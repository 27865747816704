import { PaperProps, PopoverActions, PopoverOrigin } from "@mui/material";
import React, { createContext, useCallback, useRef, useState } from "react";
import { ModalProps } from "@mui/material/Modal";

interface PopoverProps {
  anchorEl: Element;
  anchorOrigin?: PopoverOrigin;
  body: JSX.Element;
  transformOrigin?: PopoverOrigin;
  PaperProps?: Partial<PaperProps>;
  action?: React.Ref<PopoverActions>;
  onClose?: ModalProps["onClose"];
  marginThreshold?: number;
  transitionDuration?: number;
}

interface PopoverContextProps {
  onOpen(props: PopoverProps): void;
  onClose(): void;
  popoverProps?: PopoverProps;
  updatePosition(): void;
}

export const PopoverContext = createContext<PopoverContextProps | null>(null);

export const PopoverProvider: React.FC = ({ children }) => {
  const [popoverProps, setPopoverProps] = useState<PopoverProps | undefined>();

  const popoverPropsRef = useRef<PopoverProps>();

  popoverPropsRef.current = popoverProps;
  const popoverRef = useRef<PopoverActions>(null);

  const onOpen = useCallback((props: PopoverProps) => {
    setPopoverProps(props.action ? props : { ...props, action: popoverRef });
  }, []);

  const onClose = useCallback(
    (
      event: Record<string, never> = {},
      reason: "backdropClick" | "escapeKeyDown" = "backdropClick"
    ) => {
      const currentPopoverProps = popoverPropsRef.current;
      if (currentPopoverProps && currentPopoverProps.onClose) {
        currentPopoverProps.onClose(event, reason);
      }

      setPopoverProps(undefined);
    },
    []
  );

  const updatePosition = useCallback(() => {
    setTimeout(() => {
      if (typeof popoverProps?.action === "object") {
        popoverProps?.action?.current?.updatePosition();
      }
    }, 0);
  }, [popoverProps?.action]);

  return (
    <PopoverContext.Provider
      value={{
        onOpen,
        onClose,
        popoverProps,
        updatePosition,
      }}
    >
      {children}
    </PopoverContext.Provider>
  );
};
