import { useFlags } from "launchdarkly-react-client-sdk";

interface Flags {
  enableDeleting: boolean;
  enableDatadog: boolean;
  recordAllSessions: boolean;
  useMetaLogRendering: boolean;
  noChangeCountOnBuilder: boolean;
  campaignMetaLogDebugging: boolean;
  useGraphql: boolean;
  cmlChangeBadge: boolean;
  fieldConcatenation: boolean;
  checkToSpec: boolean;
}

export const useFeatures = (): Flags => {
  return useFlags<Flags>();
};
