import React from "react";
import { Link, Stack, Typography } from "@mui/material";
import { PersonCircleIcon } from "src/ui/components/shared/atoms/icons/PersonCircleIcon";

export const SignUpLink = () => (
  <Link
    underline="none"
    href="https://www.thespecsheet.com/waitlist"
    target="_blank"
  >
    <Stack direction="row" alignItems="center" spacing={2}>
      <PersonCircleIcon />
      <Typography variant="subtitle2">Sign up</Typography>
    </Stack>
  </Link>
);
