import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Button, Stack, Tooltip, Typography, IconButton } from "@mui/material";
import React, { useState } from "react";
import { NoImagePlaceholder } from "src/ui/components/shared/atoms/NoImagePlaceholder";
import { PencilSquareIcon } from "../atoms/icons/PencilSquareIcon";
import { TrashIcon } from "../atoms/icons/TrashIcon";
import { UploadIcon } from "../atoms/icons/UploadIcon";

interface ImgUploadProps {
  currentSrc?: string | null;
  currentFile?: unknown;
  onUpdate(file: File): void;
  onRemove?(): void;
  width?: number;
  variant?: "default" | "newDashboard";
}

export const ImgUpload = ({
  currentFile,
  currentSrc,
  onUpdate,
  onRemove,
  width = 164,
  variant = "newDashboard",
}: ImgUploadProps) => {
  const [value, setValue] = useState("");

  const fileOrImageProvided =
    (currentFile && currentFile instanceof File) ||
    (currentSrc && currentFile !== "empty" && !currentSrc.endsWith("/"));

  const showRemoveButton = fileOrImageProvided && onRemove;

  const renderImage = () => {
    if (currentFile && currentFile instanceof File) {
      return (
        <img
          css={{ width }}
          alt="Image"
          src={URL.createObjectURL(currentFile)}
        />
      );
    }

    if (
      (typeof currentFile === "string" && currentFile === "empty") ||
      currentSrc?.endsWith("/")
    ) {
      return <NoImagePlaceholder variant={variant} />;
    }

    if (currentSrc) {
      return <img alt="Image" src={currentSrc} />;
    }

    return <NoImagePlaceholder variant={variant} />;
  };

  return variant === "newDashboard" ? (
    <Stack spacing={1} width={width} sx={{ position: "relative" }}>
      {renderImage()}
      <Stack
        direction={"row"}
        spacing={1}
        sx={{ position: "absolute", top: 4, right: 12 }}
      >
        {showRemoveButton && (
          <Tooltip title={"Remove"}>
            <IconButton onClick={onRemove}>
              <TrashIcon
                sx={(theme) => ({ color: theme.newColors.neutral.primary })}
              />
            </IconButton>
          </Tooltip>
        )}
        <IconButton component="label">
          {fileOrImageProvided ? (
            <PencilSquareIcon
              sx={(theme) => ({ color: theme.newColors.neutral.primary })}
            />
          ) : (
            <UploadIcon
              sx={(theme) => ({ color: theme.newColors.neutral.primary })}
            />
          )}
          <input
            accept="image/*"
            id="file-input-link"
            type="file"
            style={{ display: "none" }}
            value={value}
            onClick={() => setValue("")}
            data-pw="image-upload"
            onChange={(e) => {
              const file = e.target.files && e.target.files[0];
              if (file !== null) {
                onUpdate(file);
              }
            }}
          />
        </IconButton>
      </Stack>
    </Stack>
  ) : (
    <Stack spacing={1} width={width}>
      {renderImage()}

      <Stack direction={"row"} width={width} spacing={1}>
        <label htmlFor="file-input-link">
          <input
            accept="image/*"
            id="file-input-link"
            type="file"
            style={{ display: "none" }}
            value={value}
            onClick={() => setValue("")}
            data-pw="image-upload"
            onChange={(e) => {
              const file = e.target.files && e.target.files[0];
              if (file !== null) {
                onUpdate(file);
              }
            }}
          />

          <Button
            fullWidth
            component="span"
            variant={"contained"}
            color={"fadedBlue"}
            sx={{
              border: "2px solid #D6D6FF",
              width: showRemoveButton ? width - 40 : width,
            }}
            disableElevation
          >
            Edit
          </Button>
        </label>
        {showRemoveButton && (
          <Tooltip title={"Remove"}>
            <Button
              onClick={onRemove}
              variant={"contained"}
              color={"error"}
              sx={{ width: 20, minWidth: 15 }}
              disableElevation
            >
              <DeleteOutlinedIcon />
            </Button>
          </Tooltip>
        )}
      </Stack>
      <Typography variant="small" textAlign="center">
        Please upload a thumbnail with ratio 1:1
      </Typography>
    </Stack>
  );
};
