import React, { useState } from "react";
import { DbKey } from "../../../../adl-gen/common/db";
import { Organisation } from "../../../../adl-gen/kachemedia/specsheet/db";
import {
  WorkspaceControlDialog,
  WorkspaceControlDialogContext,
} from "src/ui/components/workspaces/dialogs/WorkspaceControlDialog";

export const useWorkspaceDialogs = (
  organisationId: DbKey<Organisation>,
  variant: "default" | "newDashboard" = "default"
) => {
  const [open, setOpen] = useState(false);
  const [dialogContext, setDialogContext] =
    useState<WorkspaceControlDialogContext>(null);

  const handleOpenDialog = (context: WorkspaceControlDialogContext) => {
    setOpen(true);
    setDialogContext(context);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const Dialog = () => {
    return (
      <WorkspaceControlDialog
        onClose={() => {
          setOpen(false);
        }}
        context={dialogContext}
        isOpen={open}
        organisationId={organisationId}
        variant={variant}
      />
    );
  };

  return { handleOpenDialog, handleCloseDialog, Dialog };
};
