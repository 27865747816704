import React, { createContext, useCallback, useRef, useState } from "react";
import { DialogProps as MuiDialogProps } from "@mui/material";
import { EmptyObject } from "react-hook-form";

export interface DialogProps {
  body: JSX.Element;
  title?: string;
  subTitle?: string;
  onClose?(): void;
  maxWidth?: MuiDialogProps["maxWidth"];
  fullWidth?: boolean;
  sx?: MuiDialogProps["sx"];
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
}

interface DialogContextProps {
  onOpen(dialogProps: DialogProps): void;
  onClose(
    event?: EmptyObject,
    reason?: "backdropClick" | "escapeKeyDown"
  ): void;
  dialogProps?: DialogProps;
}

export const DialogContext = createContext<DialogContextProps | null>(null);

export const DialogProvider: React.FC = ({ children }) => {
  const [dialogProps, setDialogProps] = useState<DialogProps | undefined>();

  const drawerPropsRef = useRef<DialogProps>();

  drawerPropsRef.current = dialogProps;

  const onOpen = useCallback(
    ({
      body,
      title,
      subTitle,
      onClose,
      maxWidth,
      fullWidth = true,
      sx,
      disableEscapeKeyDown,
      disableBackdropClick,
    }: {
      body: JSX.Element;
      title: string;
      subTitle?: string;
      onClose?(): void;
      maxWidth: MuiDialogProps["maxWidth"];
      fullWidth?: boolean;
      sx?: MuiDialogProps["sx"];
      disableEscapeKeyDown?: boolean;
      disableBackdropClick?: boolean;
    }) => {
      setDialogProps({
        body,
        title,
        subTitle,
        onClose,
        maxWidth,
        fullWidth,
        sx,
        disableEscapeKeyDown,
        disableBackdropClick,
      });
    },
    []
  );

  const onClose = useCallback(
    (_: EmptyObject, reason: "backdropClick" | "escapeKeyDown") => {
      const currentDialogProps = drawerPropsRef.current;
      if (
        currentDialogProps?.disableBackdropClick &&
        reason === "backdropClick"
      ) {
        return;
      }

      if (currentDialogProps && currentDialogProps.onClose) {
        currentDialogProps.onClose();
      }

      setDialogProps(undefined);
    },
    []
  );

  return (
    <DialogContext.Provider
      value={{
        onOpen,
        onClose,
        dialogProps,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};
