import React from "react";
import { Box, useTheme } from "@mui/material";
import { TssLogoSquare as TssLogo } from "src/ui/components/shared/atoms/icons/TssLogoSquare";

export const TssLogoSquare = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: 48,
        height: 48,
        padding: theme.spacing(2),
        boxSizing: "border-box",
        borderWidth: theme.metrics.borderWidth,
        borderRadius: theme.spacing(theme.metrics.borderRadius),
        borderColor: theme.newColors.neutral.gray300,
        borderStyle: "solid",
      }}
    >
      <TssLogo css={{ width: 32, height: 32, fill: theme.colors.colorBlue }} />
    </Box>
  );
};
