import { observer } from "mobx-react";
import React from "react";
import {
  IconProps,
  QuestionIcon,
} from "src/ui/components/shared/atoms/icon/icons";
import { Box } from "@mui/material";

interface QuestionModalContentProps {
  content: string;
}

/** Question modal content */
@observer
export class QuestionModalContent extends React.Component<QuestionModalContentProps> {
  /** Renders the content of the modal */
  render() {
    return (
      <div css={{ margin: "16px 0", overflow: "auto" }}>
        <p css={{ textAlign: "center" }}>{this.props.content}</p>
      </div>
    );
  }
}

interface QuestionModalHeaderProps {
  title: string;
  Icon?: React.ComponentType<IconProps>;
}

/** Question modal content */
export class QuestionModalHeader extends React.Component<QuestionModalHeaderProps> {
  /** Renders the modal header */
  render() {
    return (
      <Box
        sx={(theme) => ({
          p: `${2 * theme.metrics.unit}px ${3 * theme.metrics.unit}px`,
          pb: 0,
        })}
        display={"flex"}
        alignItems={"center"}
      >
        {this.props.Icon ? (
          <this.props.Icon css={{ marginRight: "8px" }} color="blue" />
        ) : (
          <QuestionIcon css={{ marginRight: "8px" }} color="blue" />
        )}
        <h6
          css={{
            fontWeight: "bold",
            marginBottom: 0,
          }}
        >
          {this.props.title}
        </h6>
      </Box>
    );
  }
}
