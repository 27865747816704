import {
  Alert,
  AlertTitle,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { useArchiveWorkspace } from "../../../../../service/queryHooks/workspaces/mutations/useArchiveWorkspace";
import { InfoTextBlock } from "src/ui/components/shared/atoms/InfoTextBlock";
import { LoadingButton } from "src/ui/components/shared/molecules/buttons/LoadingButton";
import { DialogTitleStyled } from "src/ui/components/shared/mui-styled/Dialog";
import { WorkspaceView } from "../../../../../adl-gen/kachemedia/specsheet/users";
import { InfoCircleFillIcon } from "src/ui/components/shared/atoms/icons/InfoCircleFillIcon";

interface ArchiveWorkplaceProps {
  workspace: WorkspaceView;
  onClose(): void;
  variant: "default" | "newDashboard";
}

export const ArchiveWorkplace = observer(
  ({ onClose, workspace, variant = "default" }: ArchiveWorkplaceProps) => {
    const mutation = useArchiveWorkspace({
      organisationId: workspace.organisationId,
      onSuccess: onClose,
      includeArchived: true,
      calculateStats: true,
    });

    const onArchiveWorkspace = async () => {
      await mutation.mutateAsync({
        workspaceId: workspace.workspaceId,
        isActive: false,
      });
    };

    return variant === "newDashboard" ? (
      <>
        <DialogTitleStyled
          onClose={onClose}
          title="Archive Workspace"
          sx={{ paddingX: 4 }}
        />
        <DialogContent sx={{ paddingX: 4, paddingBottom: 0 }}>
          <Divider sx={{ marginBottom: 4 }} />

          <Alert
            severity="info"
            icon={
              <InfoCircleFillIcon
                sx={(theme) => ({ color: theme.newColors.utility.blue })}
              />
            }
            sx={{ letterSpacing: "-0.006em" }}
          >
            <AlertTitle>
              You are about to archive the {workspace.name} workspace
            </AlertTitle>
            Archiving a workspace will revoke access to all except admin
            members. No campaigns can be created or shared from archived
            workspaces. You can revert these changes by unarchiving the
            workspace in the future.
          </Alert>
          <Divider sx={{ marginTop: 4 }} />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", padding: 4 }}>
          <Button
            onClick={onClose}
            variant="outlined"
            sx={(theme) => ({
              color: theme.newColors.neutral.primary,
              borderColor: theme.newColors.neutral.gray200,
              width: 160,
              height: 32,
            })}
          >
            Cancel
          </Button>
          <LoadingButton
            async
            type="submit"
            variant="contained"
            onClick={onArchiveWorkspace}
            sx={{
              width: 160,
              height: 32,
            }}
          >
            Archive
          </LoadingButton>
        </DialogActions>
      </>
    ) : (
      <>
        <DialogTitleStyled onClose={onClose} title="Archive Workspace" />
        <DialogContent dividers>
          <Stack spacing={1}>
            <Typography variant="base">
              Are you sure that you want to archive the workspace{" "}
              <span style={{ fontWeight: 600 }}>{`${workspace.name}`}</span>
            </Typography>
            <InfoTextBlock
              listText={[
                "Archived workspaces can be recovered at any time",
                "Associated campaigns, templates, and groups will also be archived",
              ]}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button onClick={onClose} color="navy" variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            async
            type="submit"
            variant="contained"
            onClick={onArchiveWorkspace}
          >
            Archive
          </LoadingButton>
        </DialogActions>
      </>
    );
  }
);
