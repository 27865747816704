import { Components, Theme } from "@mui/material";

export const MuiDialogTitle: Components<Theme>["MuiDialogTitle"] = {
  // Padding-bottom of 8px to match the design
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(0, 0, 2, 0),
      fontSize: 16,
      color: theme.newColors.theme.primary,
    }),
  },
};
