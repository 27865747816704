import { useEffect } from "react";
import { GenericTableStore } from "src/stores/ui/GenericTableStore";
import { useRootStore } from "./useRootStore";

export const useGenericTableStore = (tableId: string) => {
  const {
    uiStore: { genericTablesStore },
  } = useRootStore();

  const genericTableStore =
    genericTablesStore.get(tableId) || new GenericTableStore();

  useEffect(() => {
    if (!genericTablesStore.has(tableId)) {
      genericTablesStore.set(tableId, genericTableStore);
    }
  }, []);

  return genericTableStore;
};
