import {
  authSubRoute,
  AuthWhoAmIRes,
  coreRoutes,
  urlBuilder,
  WhoAmIReq,
} from "@specsheet-common/shared-types";
import { useMutation } from "src/service/reactQuery";

export const useWhoAmI = ({ onError }: { onError(): void }) => {
  return useMutation<WhoAmIReq, AuthWhoAmIRes>({
    url: urlBuilder({
      root: coreRoutes.auth,
      sub: authSubRoute.whoAmI,
    }),
    onError,
  });
};
