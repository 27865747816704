import { createTheme } from "@mui/material";
import { themeOptions } from "../Theme";
import { components } from "./components";
import { typography } from "./typography";
import { metrics } from "./metrics";
import { palette } from "./palette";
import { colors } from "./colors";
import { transitions } from "./transitions";

declare module "@mui/material/styles" {
  interface Theme {
    newColors: typeof colors;
  }

  interface ThemeOptions {
    newColors: typeof colors;
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsSizeOverrides {
    "extra-small": true;
    small: true;
    medium: true;
    large: true;
    "extra-large": true;
  }
}

export const theme = createTheme({
  ...themeOptions,
  metrics,
  palette,
  newColors: colors,
  spacing: metrics.unit,
  transitions: transitions,
  // @ts-expect-error fix this
  components,
  typography,
});
