import {
  coreRoutes,
  idFlavor,
  notificationSubRoute,
  urlBuilder,
} from "@specsheet-common/shared-types";
import { useQueryClient } from "react-query";
import { queryKeys } from "src/service/queryHooks/keys";
import { useMutation } from "src/service/reactQuery";

interface MutationProps {
  onSuccess?(): void;
  onError?(): void;
}

export const useMarkAsRead = ({ onError, onSuccess }: MutationProps) => {
  const queryClient = useQueryClient();
  return useMutation<idFlavor<"Notification">, idFlavor<"Notification">>({
    url: (notificationId) =>
      urlBuilder({
        root: coreRoutes.notifications,
        sub: notificationSubRoute.changeStatus,
        tailParam: notificationId,
      }),
    requestFormatter: () => ({ isRead: true }),
    onSuccess: () => {
      void queryClient.invalidateQueries(
        queryKeys.notifications.fetchNotifications
      );
      onSuccess?.();
    },
    onError,
  });
};
