import { useAuth0 } from "@auth0/auth0-react";
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { useEffect } from "react";
import { useAccessToken } from "src/hooks/utils/useAccessToken";
import * as datadog from "src/util/datadog";
import { coreApiURL } from "../constants/url";

const responseInterceptor = (response: AxiosResponse) => {
  return response.data;
};

const errorInterceptor = (response: AxiosError) => {
  datadog.error(response, {});
  return response;
};

const AxiosDefaults = {
  headers: { "Content-Type": "application/json" },
};

const AxiosInstance = axios.create({
  ...AxiosDefaults,
  baseURL: coreApiURL,
});

const AxiosInstanceWithHeaders = axios.create({
  ...AxiosDefaults,
  baseURL: coreApiURL,
});

AxiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);

export const useApi = () => {
  const { isAuthenticated } = useAuth0();
  const { getAccessToken } = useAccessToken();
  useEffect(() => {
    const requestInterceptor = async (
      config: InternalAxiosRequestConfig
    ): Promise<InternalAxiosRequestConfig> => {
      const authorizationToken = isAuthenticated
        ? await getAccessToken()
        : undefined;
      if (authorizationToken && config.headers) {
        config.headers.Authorization = authorizationToken;
      }
      return config;
    };
    AxiosInstance.interceptors.request.use(requestInterceptor);
  }, [getAccessToken, isAuthenticated]);

  return AxiosInstance;
};
export const apiWithHeaders = AxiosInstanceWithHeaders;
