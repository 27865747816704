import { Box, Grid, Typography } from "@mui/material";
import {
  routePaths,
  WorkspaceViewWithStats,
  WORKSPACE_PERMISSIONS,
} from "@specsheet-common/shared-types";
import React from "react";
import { useLocation } from "react-router-dom";
import { theme } from "src/ui/common/NewTheme";
import { ColoredAvatar } from "../../shared/atoms/ColoredAvatar";
import { MenuPlusIcon } from "../../shared/atoms/icon/icons";
import { MenuContext } from "../MenuContext";

export interface WorkspaceTileProps {
  expanded: boolean;
  workspace: WorkspaceViewWithStats;
  onClick(): void;
  onIconClick(e: React.MouseEvent, ws: WorkspaceViewWithStats): void;
}

export const WorkspaceTile = ({
  expanded,
  workspace,
  onClick,
  onIconClick,
}: WorkspaceTileProps) => {
  const MenuSelections = React.useContext(MenuContext);
  const location = useLocation();
  const youAreHere =
    MenuSelections.selectedWorkspace ===
      workspace.workspaceAccess.workspace.workspaceId &&
    (location.pathname.slice(0, routePaths.workspaceManagement.length) ===
      routePaths.workspaceManagement ||
      location.pathname.slice(0, routePaths.dashboard.length) ===
        routePaths.dashboard ||
      location.pathname.slice(0, routePaths.campaignBuild.length) ===
        routePaths.campaignBuild);

  const tssMenuItemHoverable = {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    cursor: "pointer",
    outline: youAreHere ? "solid 1px #0000FF" : "none",
    "&:hover": {
      backgroundColor: "#F4F4F5",
    },
    "&:focus": {
      boxShadow: "0px 0px 0px 3px #B3D1FF",
      outline: youAreHere ? "solid 1px #0000FF" : "none",
    },
  };

  const tssMenuIconHoverable = {
    height: "28px",
    width: "28px",
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#E5E5E7",
    },
    "&:focus": {
      boxShadow: "0px 0px 0px 3px #B3D1FF",
      outline: "none",
    },
  };

  const truncate = (input: string, maxlength: number) => {
    if (input.length <= maxlength) {
      return input;
    }
    return input.slice(0, maxlength - 4) + " ...";
  };

  return (
    <Box sx={{ padding: theme.spacing(1) }}>
      <Box
        sx={tssMenuItemHoverable}
        onClick={onClick}
        tabIndex={1}
        title={`Enter ${workspace.workspaceAccess.workspace.name}`}
      >
        <Grid container>
          <Grid item xs="auto">
            <Box
              sx={{
                height: "28px",
                width: "32px",
                padding: theme.spacing(1),
                paddingLeft: theme.spacing(1.5),
              }}
            >
              <ColoredAvatar
                imgSrc={workspace.workspaceAccess.workspace.logo}
                title={workspace.workspaceAccess.workspace.name}
                variant="rounded"
                sx={{ height: "20px", width: "20px" }}
              />
            </Box>
          </Grid>
          {expanded && (
            <>
              <Grid item xs>
                <Box sx={{ height: "28px", overflow: "hidden" }}>
                  <Typography
                    variant="body2"
                    sx={{ lineHeight: "28px", paddingLeft: theme.spacing(2) }}
                  >
                    {truncate(workspace.workspaceAccess.workspace.name, 22)}
                  </Typography>
                </Box>
              </Grid>
              {workspace.workspaceAccess.permissions.includes(
                WORKSPACE_PERMISSIONS.CAMPAIGN_CREATE
              ) && (
                <Grid item xs="auto">
                  <Box
                    onClick={(e: React.MouseEvent) => onIconClick(e, workspace)}
                    sx={tssMenuIconHoverable}
                    tabIndex={1}
                    title={`Create new campaign in ${workspace.workspaceAccess.workspace.name}`}
                  >
                    <MenuPlusIcon size="24px" />
                  </Box>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
