import React from "react";
import { IconButton, Stack, Theme, Typography, useTheme } from "@mui/material";
import { InfoCircleFillIcon } from "src/ui/components/shared/atoms/icons/InfoCircleFillIcon";
import { XIcon } from "src/ui/components/shared/atoms/icons/XIcon";
import { ExclamationTriangleIcon } from "src/ui/components/shared/atoms/icons/ExclamationTriangle";
import { CheckCircleFillIcon } from "src/ui/components/shared/atoms/icons/CheckCircleFillIcon";

export type Severity = "error" | "warning" | "info" | "success";

interface SnackbarBlockProps {
  message: React.ReactNode;
  action?: JSX.Element;
  onClose?(): void;
  alignItems?: "stretch" | "center" | "start" | "end";
  severity?: Severity;
}

const colorAndIconMapCreator = (theme: Theme) => ({
  error: {
    colors: {
      backgroundColor: theme.newColors.utility.red,
    },
    icon: (
      <ExclamationTriangleIcon
        sx={{
          color: theme.newColors.neutral.white,
          backgroundColor: theme.newColors.utility.red,
        }}
      />
    ),
  },
  warning: {
    colors: {
      backgroundColor: theme.newColors.utility.yellow,
    },
    icon: (
      <ExclamationTriangleIcon
        sx={{
          color: theme.newColors.neutral.white,
          backgroundColor: theme.newColors.utility.yellow,
        }}
      />
    ),
  },
  info: {
    colors: {
      backgroundColor: theme.newColors.utility.blue,
    },
    icon: (
      <InfoCircleFillIcon
        sx={{
          color: theme.newColors.neutral.white,
          backgroundColor: theme.newColors.utility.blue,
        }}
      />
    ),
  },
  success: {
    colors: {
      backgroundColor: theme.newColors.utility.green,
    },
    icon: (
      <CheckCircleFillIcon
        sx={{
          color: theme.newColors.neutral.white,
          backgroundColor: theme.newColors.utility.green,
        }}
      />
    ),
  },
});

export const SnackbarBlock = ({
  message,
  action,
  onClose,
  alignItems,
  severity,
}: SnackbarBlockProps) => {
  const theme = useTheme();
  const colorsAndIcon = colorAndIconMapCreator(theme)[severity || "info"];
  return (
    <Stack
      alignItems={alignItems || "center"}
      direction="row"
      spacing={4}
      px={4}
      py={2}
      sx={{
        borderRadius: theme.spacing(2),
        boxShadow: `${theme.spacing(0, 1.5, 6)} ${
          theme.newColors.neutral.primary
        }1E`,
        minWidth: "640px",
        color: theme.newColors.neutral.white,
        ...colorsAndIcon.colors,
      }}
    >
      {colorsAndIcon.icon}
      <Typography flex={1} variant="body2" data-pw="snackbar-message">
        {message}
      </Typography>
      {action}
      {onClose && (
        <IconButton
          onClick={onClose}
          sx={{
            color: theme.newColors.neutral.white,
            ...colorsAndIcon.colors,
            height: `${theme.spacing(6)} !important;`,
            width: `${theme.spacing(6)} !important;`,
          }}
          data-pw="snackbar-close-button"
        >
          <XIcon
            sx={{
              height: `${theme.spacing(6)} !important;`,
              width: `${theme.spacing(6)} !important;`,
            }}
          />
        </IconButton>
      )}
    </Stack>
  );
};
