import React, { useMemo } from "react";
import { createEditor, Descendant, Editor, Transforms } from "slate";
import { Editable, Slate, withReact } from "slate-react";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { withHistory } from "slate-history";
import { ControlPanel } from "./components/ControlPanel";
import { Element } from "./components/Element";
import { Leaf } from "./components/Leaf";
import {
  makeListWysiwygItem,
  makeWysiwygCustomText,
} from "../../../../../adl-gen/kachemedia/specsheet/common";
import {
  convertWysiwygDependentToString,
  notNull,
  notNullish,
} from "@specsheet-common/shared-tools";
import { assertNever } from "@specsheet-common/shared-tools";
import { N_WysiwygDescendant } from "@specsheet-common/shared-types";
import { XIcon } from "../../atoms/icons/XIcon";
import { useWysiwygEditor } from "./useWysiwygEditor";

interface WysiwygBaseProps {
  value: N_WysiwygDescendant[];
  placeholder?: string;
  maxLength?: number;
  minHeight?: number;
  maxHeight?: number;
  height?: number;
  inline?: boolean;
  error?: string;
  autoFocus?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  dataPw?: string;
}

interface WysiwygPropsReadOnly extends WysiwygBaseProps {
  readonly: true;
}

interface WysiwygPropsEditable extends WysiwygBaseProps {
  onChange(value: N_WysiwygDescendant[]): void;
  readonly?: false;
}

type WysiwygProps = WysiwygPropsReadOnly | WysiwygPropsEditable;

export const WYSIWYG = (props: WysiwygProps) => {
  const { value, placeholder, minHeight, readonly, maxLength } = props;
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const theme = useTheme();

  const { onKeyDown } = useWysiwygEditor({
    editor,
    onKeyDown: props.onKeyDown,
  });

  const getValue = (): Descendant[] => {
    if (value && value.length > 0) {
      return value
        .filter((des) => {
          return !(des.value.length === 0);
        })
        .map((des) => {
          switch (des.kind) {
            case "paragraph": {
              return {
                kind: "paragraph",
                children: des.value,
              };
            }
            case "numberedList":
            case "bulletedList": {
              const listChildren = des.value
                .map((li) => {
                  if (li.kind === "listItem") {
                    return { kind: li.kind, children: li.value };
                  }
                  return null;
                })
                .filter(notNull);

              const toReturn: Descendant = {
                kind: des.kind,
                children: listChildren,
              };

              return toReturn;
            }
            default: {
              assertNever(des);
            }
          }
        });
    }
    return [
      {
        kind: "paragraph",
        children: [{ text: "" }],
      },
    ];
  };
  const initialValue = getValue();
  const characterCount = value
    ?.map(convertWysiwygDependentToString)
    .filter(notNull)
    .join("").length;
  const invalidCharacterCount =
    maxLength !== undefined && characterCount > maxLength;

  return (
    <Slate
      editor={editor}
      key={readonly ? JSON.stringify(initialValue) : undefined}
      value={initialValue}
      onChange={(val) => {
        const getDataForServer = () => {
          const preparedData = val
            .map((des) => {
              if ("kind" in des) {
                switch (des.kind) {
                  case "paragraph": {
                    return {
                      kind: des.kind,
                      value: des.children.map(makeWysiwygCustomText),
                    };
                  }
                  case "numberedList":
                  case "bulletedList": {
                    const listChildren = des.children
                      .map((li) => {
                        if (li.kind === "listItem") {
                          return makeListWysiwygItem(
                            "listItem",
                            li.children.map(makeWysiwygCustomText)
                          );
                        }
                        return null;
                      })
                      .filter(notNull);

                    return {
                      kind: des.kind,
                      value: listChildren,
                    };
                  }
                }
              }
              return null;
            })
            .filter(notNull);

          // checking if empty value, as slate requires at least one node to be set up
          if (
            preparedData.length === 1 &&
            preparedData[0].kind === "paragraph" &&
            preparedData[0].value.length === 1 &&
            preparedData[0].value[0].text.trim() === ""
          ) {
            return [];
          }
          return preparedData;
        };

        if (!props.readonly) {
          props.onChange(getDataForServer());
        }
      }}
    >
      <Stack sx={{ display: props.inline ? "inline" : "flex" }}>
        {!props.readonly && <ControlPanel editor={editor} />}
        <Box
          p={props.readonly ? undefined : 1}
          minHeight={minHeight}
          sx={
            props.readonly
              ? undefined
              : {
                  border: `solid 1px ${theme.newColors.neutral.gray200}`,
                  paddingLeft: theme.spacing(2),
                  marginTop: theme.spacing(1),
                  position: "relative",
                  borderRadius: theme.spacing(1),
                  "&:focus-within": {
                    border: "solid blue 2px",
                    boxShadow: `${theme.newColors.theme.blue300} 0px 0px 0px 4px`,
                  },
                  "&:hover .clear-icon": {
                    visibility: "visible",
                  },
                }
          }
        >
          <Editable
            css={{
              maxHeight: props.maxHeight,
              overflow: "auto",
              width: "100%",
              height: notNullish(props.height) ? props.height : "100%",
              cursor: props.readonly ? undefined : "pointer",
            }}
            data-pw={props.dataPw}
            onKeyDown={onKeyDown}
            autoFocus={props.autoFocus}
            placeholder={placeholder}
            readOnly={props.readonly}
            renderElement={(args) => <Element {...args} />}
            renderLeaf={(args) => <Leaf {...args} />}
          />
          {props.readonly ? null : (
            <IconButton
              onClick={() => {
                Transforms.delete(editor, {
                  at: {
                    anchor: Editor.start(editor, []),
                    focus: Editor.end(editor, []),
                  },
                });
              }}
              onBlur={(e) => e.stopPropagation()}
              className="clear-icon ignore-blur"
              sx={{
                color: theme.newColors.neutral.gray300,
                "&:hover": {
                  backgroundColor: theme.newColors.neutral.gray100,
                },
                position: "absolute",
                right: "15px",
                top: "calc(50% - 15px)",
                visibility: "hidden",
              }}
            >
              <XIcon
                sx={{
                  height: `${theme.spacing(6)} !important;`,
                  width: `${theme.spacing(6)} !important;`,
                }}
              />
            </IconButton>
          )}
        </Box>
      </Stack>
      {maxLength && !props.error ? (
        <Typography
          color={(t) =>
            invalidCharacterCount
              ? t.newColors.utility.yellow
              : t.newColors.neutral.gray400
          }
          variant="small"
          textAlign="right"
          sx={{ float: "right" }}
          margin={2}
        >
          {characterCount ?? 0}/{maxLength} Characters
        </Typography>
      ) : null}
      {props.error ? (
        <Typography
          color="error"
          variant="small"
          textAlign="right"
          sx={{ float: "right" }}
          margin={2}
        >
          {props.error}
        </Typography>
      ) : null}
    </Slate>
  );
};
