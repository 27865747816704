import { Service } from "src/service/service";
import { action, makeObservable, observable } from "mobx";
import { ClientView } from "src/adl-gen/kachemedia/specsheet/campaigns";
import { fetchClientsByTerm } from "src/service/clients/fetchClientsByTerm";
import { idFlavor } from "@specsheet-common/shared-types";

export class ClientsListStore {
  isInitiated: boolean = false;
  isLoading: boolean = false;
  clients: ClientView[] = [];
  lastFetchDate: Date = new Date();

  constructor(private readonly service: Service) {
    makeObservable(this, {
      clients: observable,
      isInitiated: observable,
      isLoading: observable,
      lastFetchDate: observable,
      setClients: action,
      setCurrentLastFetchDate: action,
      fetch: action,
    });
  }

  setCurrentLastFetchDate = (): Date => {
    const newDate = new Date();
    this.lastFetchDate = newDate;
    return newDate;
  };

  setClients = (clients: ClientView[]) => {
    this.clients = clients;
  };

  fetch = async ({
    workspaceId,
    searchTerm,
  }: {
    workspaceId: idFlavor<"Workspace">;
    searchTerm?: string;
  }): Promise<void> => {
    this.isInitiated = true;

    const startDate = this.setCurrentLastFetchDate();
    this.isLoading = true;

    const data = await fetchClientsByTerm({
      workspaceId,
      service: this.service,
      term: searchTerm || "",
    });

    if (this.lastFetchDate.getTime() > startDate.getTime()) {
      return;
    }

    this.setClients(data);
    this.isLoading = false;
  };
}
