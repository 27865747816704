import React, { createContext, useCallback, useEffect, useState } from "react";
import { getApplicationVersion } from "@specsheet-common/shared-tools";
import {
  socketMessageKinds,
  validateApplicationVersionMessageData,
} from "@specsheet-common/shared-types";
import { useWebsocket } from "../hooks/contexts/useWebsocket";

interface ApplicationVersion {
  version: string | null;
  setVersion(version: string): void;
}

export const ApplicationVersionContext = createContext<ApplicationVersion>({
  version: getApplicationVersion(),
  setVersion: () => {},
});

const useApplicationVersionListener = ({
  setVersion,
}: {
  setVersion(version: string): void;
}) => {
  const { socket } = useWebsocket();

  const websocketCallback = useCallback(
    (message) => {
      const messageData = validateApplicationVersionMessageData(
        JSON.parse(message)
      );
      setVersion(messageData.version);
    },
    [setVersion]
  );

  useEffect(() => {
    socket?.on(socketMessageKinds.applicationVersion, websocketCallback);
    return () => {
      socket?.off(socketMessageKinds.applicationVersion, websocketCallback);
    };
  }, [socket, websocketCallback]);
};

export const ApplicationVersionProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(getApplicationVersion());
  useApplicationVersionListener({ setVersion: setState });
  return (
    <ApplicationVersionContext.Provider
      value={{ version: state, setVersion: setState }}
    >
      {children}
    </ApplicationVersionContext.Provider>
  );
};
