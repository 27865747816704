import { UiStore } from "./UiStore";
import { Service } from "src/service/service";
import { action, makeObservable, observable } from "mobx";
import { idFlavor } from "@specsheet-common/shared-types";

interface AddCampaignTemplateDrawerContext {
  kind: "addTemplate";
}
interface EditCampaignTemplateDrawerContext {
  kind: "editTemplate";
  templateId: idFlavor<"CampaignTemplate">;
}

type CampaignTemplateDrawerContext =
  | EditCampaignTemplateDrawerContext
  | AddCampaignTemplateDrawerContext;

export class CampaignTemplateDrawerStore {
  drawerContext: CampaignTemplateDrawerContext | null = null;

  constructor(
    private readonly uiStore: UiStore,
    private readonly service: Service
  ) {
    this.uiStore = uiStore;
    this.service = service;

    makeObservable(this, {
      drawerContext: observable,
      setDrawerContext: action,
    });
  }

  setDrawerContext = (drawerContext: CampaignTemplateDrawerContext | null) => {
    if (drawerContext) {
      this.drawerContext = drawerContext;
    } else {
      this.drawerContext = null;
    }
  };
}
