import React from "react";
import { PortalPageLayoutBasic } from "src/ui/layouts/PortalPageLayoutBasic";

export const PortalPageLayoutZeroPadding: React.FC = ({ children }) => {
  return (
    <PortalPageLayoutBasic
      styles={{
        body: {
          padding: `0 0 0 0`,
          maxWidth: "none",
        },
      }}
    >
      {children}
    </PortalPageLayoutBasic>
  );
};

export const PortalPageLayoutZeroPaddingNoOverflow: React.FC = ({
  children,
}) => {
  return (
    <PortalPageLayoutBasic
      styles={{
        body: {
          hideOverflowY: true,
          padding: `0 0 0 0`,
          maxWidth: "none",
        },
      }}
    >
      {children}
    </PortalPageLayoutBasic>
  );
};
