import React from "react";
import { Box, Container, Divider, ThemeProvider } from "@mui/material";
import { theme as newTheme } from "src/ui/common/NewTheme";
import { NewPublicHeader } from "src/ui/layouts/components/NewPublicHeader";

export const NewPublicPageLayout: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={newTheme}>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflowY: "hidden",
          overflowX: "hidden",
          width: "100%",
          backgroundColor: theme.colors.colorLightGray,
        })}
      >
        <Box
          sx={(theme) => ({
            width: "100%",
            backgroundColor: theme.colors.colorWhite,
          })}
        >
          <Container sx={{ flexShrink: 0 }} maxWidth="lg">
            <NewPublicHeader />
          </Container>
        </Box>
        <Divider />

        <Box
          sx={(theme) => ({
            position: "relative",
            display: "flex",
            width: "100%",
            flexDirection: "column",
            flex: "1 1 auto",
            overflowY: "hidden",
            margin: "0 auto",
            backgroundColor: theme.newColors.neutral.gray200,
          })}
        >
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
};
