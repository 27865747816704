import { Components, Theme } from "@mui/material";

export const MuiDialog: Components<Theme>["MuiDialog"] = {
  // So all dialogs have a padding of 16px and border radius of 8px
  styleOverrides: {
    root: ({ theme }) => ({
      ".MuiDialog-paper": {
        padding: theme.spacing(4),
        borderRadius: theme.spacing(2),
      },
      "& .MuiDialog-paperWidthXs": {
        width: 480,
      },
    }),
  },
};
