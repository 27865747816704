import React from "react";
import { useError } from "src/hooks/utils/useError";
import { NoAccessPlaceholder } from "../../molecules/NoAccessState";
import { NoAccessArchivedEmptyStateSVG } from "../../atoms/icon/icons-svg";
import { routePaths } from "@specsheet-common/shared-types";
import { useNavigate } from "react-router-dom";

interface ErrorFallbackProps {
  error?: Error;
}

export const ErrorFallback = ({ error }: ErrorFallbackProps): JSX.Element => {
  const { error: handleError } = useError();
  handleError(error);

  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <NoAccessPlaceholder
        icon={NoAccessArchivedEmptyStateSVG}
        title="Something went wrong."
        description="Please reload the page and try again."
        showRefresh
        buttonTitle="Go to your dashboard"
        buttonClick={() => navigate(routePaths.dashboard)}
      />
    </div>
  );
};
