import { Observer } from "mobx-react";
import React from "react";
import { Box } from "@mui/material";
import { theme } from "../common/Theme";

export const ActionPageLayout: React.FC = (props) => {
  return (
    <Observer>
      {() => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            backgroundColor: theme.colors.colorGray,
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              flex: "1 1 auto",
              padding: 2,
              margin: "0 auto",
            }}
          >
            <Box
              sx={{
                alignSelf: "center",
                flex: "1 1 auto",
                height: "100%",
                width: "100%",
                maxWidth: theme.metrics.globals.page.maxPageWidth,
              }}
            >
              {props.children}
            </Box>
          </Box>
        </Box>
      )}
    </Observer>
  );
};
