import classnames from "classnames";
import * as React from "react";
import * as SVG from "./icons-svg";
import styles from "./icons.css";

type IconSizes =
  | "xs"
  | "1x"
  | "sm"
  | "2x"
  | "3x"
  | "4x"
  | "5x"
  | "6x"
  | "16px"
  | "24px";

export type IconColors =
  | "black"
  | "red"
  | "green"
  | "blue"
  | "orange"
  | "white"
  | "gray"
  | "darkGray"
  | "lightGray"
  | "yellow"
  | "lightGray"
  | "navy";

export interface IconProps {
  /** Additional classes */
  className?: string;
  /** Color (Default: black) */
  color?: IconColors;
  /** Size otherwise medium */
  size?: IconSizes;
  /** Callback on click */
  // tslint:disable-next-line: invalid-void
  onClick?(): void;
  /** Title displayed on hovering */
  title?: string;
}

/** All icons for easy iterating */
const ALL_ICONS: {
  name: string;
  Icon(props: IconProps): JSX.Element;
}[] = [];

export function makeIconComponent(
  name: string,
  svg: JSX.Element
): (props: IconProps) => JSX.Element {
  const Icon = (props: IconProps) => {
    const size = props.size || "1x";
    return (
      <div
        title={props.title}
        className={classnames(
          styles.icon,
          styles[`icon-${size}`],
          props.color && styles[props.color],
          props.onClick && styles.clickable,
          props.className
        )}
        onClick={props.onClick}
      >
        {svg}
      </div>
    );
  };
  ALL_ICONS.push({
    name,
    Icon,
  });
  return Icon;
}

export const CloseIcon = makeIconComponent("Close", SVG.CloseSvg);
export const PlusIcon = makeIconComponent("Plus", SVG.PlusSvg);
export const QuestionIcon = makeIconComponent("Question", SVG.QuestionSvg);
export const StarFullIcon = makeIconComponent("Star Full", SVG.StarFullSvg);
export const StarLineIcon = makeIconComponent("Star Line", SVG.StarLineSvg);

export const LinkIcon = makeIconComponent("Link", SVG.LinkIconSVG);
export const ToggleIcon = makeIconComponent("Toggle", SVG.ToggleSVG);
export const VerifiedIcon = makeIconComponent("Verified", SVG.VerifiedSVG);

export const MenuExpandIcon = makeIconComponent("Expand", SVG.MenuExpandSVG);
export const MenuCollapseIcon = makeIconComponent(
  "Collapse",
  SVG.MenuCollapseSVG
);
export const MenuSwitchIcon = makeIconComponent(
  "Switch Organisation",
  SVG.MenuSwitchIconSVG
);
export const MenuPlusIcon = makeIconComponent("New Campaign", SVG.MenuPlusSVG);
export const MenuPlusBoxIcon = makeIconComponent(
  "Add Workspace",
  SVG.MenuPlusBoxSVG
);
export const MenuShareIcon = makeIconComponent(
  "Shared with me",
  SVG.MenuShareSVG
);
export const MenuNotificationsIcon = makeIconComponent(
  "Notifications",
  SVG.MenuNotificationsSVG
);
export const MenuLivechatIcon = makeIconComponent(
  "Live Chat",
  SVG.MenuLivechatSVG
);
export const MenuWhatsnewIcon = makeIconComponent(
  "What's New",
  SVG.MenuWhatsnewSVG
);
export const MenuHelpIcon = makeIconComponent("Help Centre", SVG.MenuHelpSVG);
export const MenuThreeDotsIcon = makeIconComponent(
  "Profile",
  SVG.MenuThreeDotsSVG
);
export const MenuEmailIcon = makeIconComponent(
  "Email Support",
  SVG.MenuEmailSVG
);
export const MenuPaperIcon = makeIconComponent("Go To", SVG.MenuPaperSVG);

export const ButtonPlusIcon = makeIconComponent(
  "Add Organisation",
  SVG.ButtonPlusSVG
);
export const ButtonLogoutIcon = makeIconComponent(
  "Logout",
  SVG.ButtonLogoutSVG
);
