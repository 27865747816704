import React from "react";
import { Box, Grid } from "@mui/material";
import { MenuPlusBoxIcon } from "../../shared/atoms/icon/icons";
import { useWorkspaceDialogs } from "../../workspaces/dialogs/useWorkspaceDialogs";
import { useAuth } from "src/hooks/contexts/useAuth";
import { theme } from "src/ui/common/NewTheme";

interface MenuButtonProps {
  expanded: boolean;
}

export const AddWorkspace = ({}: MenuButtonProps) => {
  const { organisation } = useAuth();
  const organisationId = organisation.id;

  const { handleOpenDialog: handleWorkspaceDialogs, Dialog: WorkspaceDialog } =
    useWorkspaceDialogs(organisationId, "newDashboard");

  return (
    <Box sx={{ padding: theme.spacing(2) }}>
      <Grid container>
        <Grid item xs="auto">
          <Box
            sx={{ height: "32px", width: "32px", padding: theme.spacing(1) }}
            title={"Create new workspace"}
          >
            <MenuPlusBoxIcon
              size="24px"
              onClick={() => handleWorkspaceDialogs({ kind: "create" })}
            />
          </Box>
        </Grid>
      </Grid>
      <WorkspaceDialog />
    </Box>
  );
};
