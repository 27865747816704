import { Tooltip } from "@mui/material";
import React, { Ref, useLayoutEffect, useRef, useState } from "react";

interface WithTooltipProps {
  tooltip: string | string[];
  children(tooltip: Ref<HTMLDivElement>): React.ReactElement;
}

export const WithTooltip = ({ tooltip, children }: WithTooltipProps) => {
  const nameRef = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  useLayoutEffect(() => {
    const nameElement = nameRef.current;
    if (nameElement) {
      const offsetWidth = nameElement.offsetWidth;
      const scrollWidth = nameElement.scrollWidth;
      if (offsetWidth < scrollWidth) {
        setShowTooltip(true);
      }
    }
  });
  return showTooltip ? (
    <Tooltip title={tooltip} arrow>
      {children(nameRef)}
    </Tooltip>
  ) : (
    children(nameRef)
  );
};
