import { useContext } from "react";
import { WebsocketContext } from "../../contexts/WebsocketContext";

export const useWebsocket = () => {
  const context = useContext(WebsocketContext);

  if (!context) {
    throw new Error("No websocket context provided");
  }

  return context;
};
