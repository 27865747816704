import { createTheme, ThemeOptions } from "@mui/material";
import { colors as newColors } from "./NewTheme/colors";

const unit = 8;
const borderWidth = 1;
const contentHeight = 4 * unit;
const contentPadding = 2 * unit;
const headerHeight = contentHeight + 2 * contentPadding + borderWidth;
const publicHeaderHeight = contentHeight + contentPadding + borderWidth;

const metrics = {
  unit,
  borderWidth,
  borderRadius: 0.5,
  globals: {
    page: {
      minPageWidth: 1024,
      maxPageWidth: 1920,
    },
  },
  contentHeight,
  contentPadding,
  headerHeight,
  publicHeaderHeight,
};

const fonts = {
  fontStyle: {
    fontWeightBold: 600,
    fontWeightNormal: 400,
  },
  sizes: {
    tinyFontSize: 10,
    smallFontSize: 12,
    baseFontSize: 14,
    mediumFontSize: 16,
    largeFontSize: 20,
  },
} as const;

const colors = {
  colorMainTextFont: "#3e3f42",
  colorGray1ce: "#1c2026",
  colorSecondaryTextFont: "#6b6c6f",
  colorBlack: "#000000",
  colorNeutralLight: "#A0AEC0",
  colorNavy: "#000066",
  colorBlue: "#0000FF",
  colorBlue500: "#408CFF",
  colorBlue25: "#0000FF3F",
  colorBlue5: "#0000FF0C",
  colorGrey: "#C4C4C4",
  colorMediumGray: "#dee1ec",
  colorLightGray: "#FBFBFD",
  colorDarkGray: "#6B6C6F",
  colorGray: "#F4F4F4",
  colorGray300: "#B3B4B9",
  colorGreen: "#38BA38",
  colorCharcoal: "#130800",
  colorYellow: "rgba(250,207,85)",
  colorYellow25: "rgba(250,207,85,0.25)",
  colorLightGreen: "#E5F9EC",
  colorRed: "#FF4928",
  colorRed10: "rgba(255,73,40,0.1)",
  colorRed25: "rgba(255,73,40,0.25)",
  colorRed50: "rgba(255,73,40,0.5)",
  colorRed75: "rgba(255,73,40,0.75)",
  colorOrange: "#fd7200",
  subGroupRowBackgroundColour: "#F3F7FC",
  endGroupRowBackgroundColour: "#FBFBFB",
  colorBorder: "rgba(158, 160, 165, 0.25)",
  colorWhite: "#FFF",
  tableHeaderBackground: "#F9F9F9",
  infoBlockBackground: "#F7FAFC",
  fadedBlue: "#EBEBFF",
  fadedGreen: "#E5F9EC",
  alert: "#A6361F",
} as const;

const modules = {
  genericTable: {
    groupPadding: metrics.unit,
    defaultDataRowHeight: 35,
    defaultHeaderRowHeight: 44,
    defaultCheckBoxWidth: 35,
    defaultGroupColumnWidth: 100,
  },
  link: {
    fontSize: 12,
    color: colors.colorBlue,
    textDecoration: "underline",
    cursor: "pointer",
    marginLeft: metrics.unit,
    ":hover": {
      fontWeight: fonts.fontStyle.fontWeightBold,
    },
  },
} as const;

declare module "@mui/material/styles" {
  interface Theme {
    metrics: typeof metrics;
    fonts: typeof fonts;
    colors: typeof colors;
    modules: typeof modules;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    metrics: typeof metrics;
    fonts: typeof fonts;
    colors: typeof colors;
    modules: typeof modules;
  }

  interface TypographyVariants {
    warning: React.CSSProperties;
    base: React.CSSProperties;
    baseBold: React.CSSProperties;
    body3: React.CSSProperties;
    subtitle3: React.CSSProperties;
    subtitle4: React.CSSProperties;
    extraLargeIcon: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    warning?: React.CSSProperties;
    base?: React.CSSProperties;
    baseBold?: React.CSSProperties;
    small?: React.CSSProperties;
    smallBold?: React.CSSProperties;
    subtitle3?: React.CSSProperties;
    body3?: React.CSSProperties;
  }

  interface Palette {
    lightBlue: Palette["primary"];
    gray: Palette["primary"];
    green: Palette["primary"];
    navy: Palette["primary"];
    fadedBlue: Palette["primary"];
    lightGray: Palette["primary"];
  }

  interface PaletteOptions {
    lightBlue: PaletteOptions["primary"];
    gray: PaletteOptions["primary"];
    green: PaletteOptions["primary"];
    navy: PaletteOptions["primary"];
    fadedBlue: PaletteOptions["primary"];
    lightGray: PaletteOptions["primary"];
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    warning: true;
    base: true;
    baseBold: true;
    small: true;
    smallBold: true;
    subtitle3: true;
    body3: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    lightBlue: true;
    gray: true;
    green: true;
    navy: true;
    fadedBlue: true;
    neutral: true;
    neutralGray: true;
  }
}
declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    lightBlue: true;
    gray: true;
    green: true;
    navy: true;
    fadedBlue: true;
    neutral: true;
  }
}
declare module "@mui/material/ButtonGroup" {
  interface ButtonGroupPropsColorOverrides {
    lightBlue: true;
    gray: true;
    green: true;
    navy: true;
    fadedBlue: true;
  }
}
declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    lightBlue: true;
    gray: true;
    green: true;
    navy: true;
    fadedBlue: true;
    blue: true;
    yellow: true;
    purple: true;
    azure: true;
    turquoise: true;
    orange: true;
    pink: true;
    lightGray: true;
  }
}

export const themeOptions: ThemeOptions = {
  spacing: unit,
  metrics,
  fonts,
  colors,
  newColors,
  modules,
  palette: {
    primary: { main: colors.colorBlue },
    secondary: { main: colors.colorGreen, contrastText: "#fff" },
    lightBlue: { main: colors.colorBlue, contrastText: "#fff" },
    gray: { main: colors.colorDarkGray },
    lightGray: { main: colors.colorGray300 },
    success: {
      main: colors.colorGreen,
      contrastText: colors.colorWhite,
    },
    error: {
      main: colors.colorRed75,
      light: colors.colorRed50,
      dark: colors.colorRed,
    },
    text: {
      primary: colors.colorMainTextFont,
      secondary: colors.colorSecondaryTextFont,
    },
    green: {
      main: colors.colorGreen,
      contrastText: colors.colorWhite,
    },
    navy: {
      main: colors.colorNavy,
      contrastText: colors.colorWhite,
    },
    fadedBlue: {
      main: colors.fadedBlue,
      contrastText: colors.colorNavy,
    },
  },
  typography: {
    fontWeightBold: 600,
    fontWeightRegular: 400,
    fontFamily: [
      "Inter",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "system",
      "sans-serif",
    ].join(","),
    warning: { color: colors.colorRed75, fontSize: fonts.sizes.baseFontSize },
    base: { fontSize: fonts.sizes.baseFontSize },
    small: { fontSize: fonts.sizes.smallFontSize },
    smallBold: {
      fontSize: fonts.sizes.smallFontSize,
      fontWeight: fonts.fontStyle.fontWeightBold,
    },
    baseBold: {
      fontSize: fonts.sizes.baseFontSize,
      fontWeight: fonts.fontStyle.fontWeightBold,
    },
    h1: {
      fontSize: 36,
      fontWeight: fonts.fontStyle.fontWeightBold,
    },
    h2: {
      fontSize: 30,
      fontWeight: fonts.fontStyle.fontWeightBold,
    },
    h3: {
      fontSize: 26,
      fontWeight: fonts.fontStyle.fontWeightBold,
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: fonts.fontStyle.fontWeightBold,
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiDialog-paperWidthXs": {
            width: 480,
          },
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: { backgroundColor: colors.colorWhite },
      },
    },
    MuiLink: {
      styleOverrides: { button: { ":hover": { color: colors.colorBlue500 } } },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ":hover": {
            backgroundColor: colors.colorBlue25,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: colors.colorNavy,
          fontSize: "1.1rem",
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        thickness: 10,
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeLarge: { fontSize: "1.1rem" },
        root: {
          textTransform: "none",
          fontSize: "1rem",
          fontWeight: 600,
          boxShadow: "none",
        },
        iconSizeSmall: {
          "& > *:first-of-type": {
            fontSize: fonts.sizes.mediumFontSize,
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: { fontSize: 20, color: colors.colorNavy },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: metrics.unit * 2,
          backgroundColor: colors.colorLightGray,
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          width: 30,
          height: 30,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 30,
          height: 30,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 11,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 20,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 28,
          height: 18,
          padding: 2,
          "& .MuiSwitch-switchBase": {
            margin: 1,
            padding: 0,
            transform: "translate(2px, 2px)",
            "&.Mui-checked": {
              transform: "translate(12px, 2px)",
              "& .MuiSwitch-thumb:before": {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"><path fill="${encodeURIComponent(
                  colors.colorBlue
                )}" d="M6.36799 1.98497C6.40211 1.95015 6.44284 1.92249 6.48778 1.90361C6.53273 1.88473 6.58099 1.875 6.62974 1.875C6.67849 1.875 6.72675 1.88473 6.7717 1.90361C6.81664 1.92249 6.85737 1.95015 6.89149 1.98497C7.03449 2.12947 7.03649 2.36297 6.89649 2.50997L3.93999 6.00497C3.90642 6.04183 3.86569 6.07145 3.82028 6.09203C3.77487 6.11261 3.72574 6.1237 3.67589 6.12464C3.62605 6.12557 3.57654 6.11633 3.53039 6.09747C3.48424 6.07862 3.44242 6.05054 3.40749 6.01497L1.60849 4.19197C1.53911 4.12121 1.50024 4.02606 1.50024 3.92697C1.50024 3.82787 1.53911 3.73272 1.60849 3.66197C1.64261 3.62715 1.68334 3.59949 1.72828 3.58061C1.77323 3.56173 1.82149 3.552 1.87024 3.552C1.91899 3.552 1.96725 3.56173 2.0122 3.58061C2.05714 3.59949 2.09787 3.62715 2.13199 3.66197L3.65799 5.20847L6.35799 1.99597C6.3611 1.9921 6.36444 1.98843 6.36799 1.98497Z"/></svg>')`,
              },
              "& + .MuiSwitch-track": {
                opacity: 1,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.12,
              },
            },
          },
          "& .MuiSwitch-thumb": {
            backgroundColor: colors.colorWhite,
            width: 12,
            height: 12,
            "&:before": {
              content: "''",
              position: "absolute",
              width: "100%",
              height: "100%",
              left: 0,
              top: 0,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",

              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 8 8"><path fill="${encodeURIComponent(
                colors.colorGray1ce
              )}" d="M1.0186 1.15396C1.03121 1.12359 1.04968 1.096 1.07296 1.07278C1.09618 1.04949 1.12377 1.03102 1.15414 1.01842C1.18451 1.00582 1.21707 0.999329 1.24996 0.999329C1.28284 0.999329 1.3154 1.00582 1.34578 1.01842C1.37615 1.03102 1.40374 1.04949 1.42696 1.07278L3.99995 3.64577L6.57295 1.07278C6.59617 1.04949 6.62376 1.03102 6.65413 1.01842C6.68451 1.00582 6.71707 0.999329 6.74995 0.999329C6.78283 0.999329 6.81539 1.00582 6.84577 1.01842C6.87614 1.03102 6.90373 1.04949 6.92695 1.07278C6.95023 1.096 6.9687 1.12359 6.98131 1.15396C6.99391 1.18433 7.0004 1.21689 7.0004 1.24978C7.0004 1.28266 6.99391 1.31522 6.98131 1.34559C6.9687 1.37596 6.95023 1.40355 6.92695 1.42678L4.35395 3.99977L6.92696 6.57277C6.9739 6.61972 7.00027 6.68339 7.00027 6.74977C7.00027 6.81616 6.9739 6.87983 6.92696 6.92677C6.88002 6.97372 6.81635 7.00009 6.74996 7.00009C6.68357 7.00009 6.6199 6.97372 6.57296 6.92677L3.99995 4.35377L1.42695 6.92677C1.38001 6.97372 1.31634 7.00009 1.24995 7.00009C1.18356 7.00009 1.11989 6.97372 1.07295 6.92677C1.02601 6.87983 0.999634 6.81616 0.999634 6.74977C0.999634 6.68339 1.02601 6.61972 1.07295 6.57277L3.64595 3.99977L1.07296 1.42678C1.04968 1.40355 1.03121 1.37596 1.0186 1.34559C1.006 1.31522 0.999512 1.28266 0.999512 1.24978C0.999512 1.21689 1.006 1.18433 1.0186 1.15396Z"/></svg>')`,
            },
          },
        },
      },
    },
  },
};

export const theme = createTheme(themeOptions);
