import { N_OrganisationView } from "@specsheet-common/shared-types";
import { AuthOrganisation } from "src/contexts/AuthContext";

export const authOrganisationConverter = (
  authOrg: AuthOrganisation
): N_OrganisationView => {
  return {
    organisationId: authOrg.id,
    name: authOrg.name,
    logo: authOrg.logo,
    config: authOrg.config,
  };
};
