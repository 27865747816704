import { Button, DialogActions, DialogContent } from "@mui/material";
import React from "react";
import { InlineWidget } from "react-calendly";

interface CalendlyDialogProps {
  onClose(): void;
}

export const CalendlyDialog = ({ onClose }: CalendlyDialogProps) => {
  return (
    <>
      <DialogContent dividers>
        <InlineWidget url="https://calendly.com/the-spec-sheet" />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button
          onClick={onClose}
          color="gray"
          variant="outlined"
          sx={{ width: "160px", height: "32px" }}
        >
          Cancel
        </Button>
      </DialogActions>
    </>
  );
};
