import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import { Box, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { InfoStatusLabel } from "../shared/atoms/InfoStatusLabel";
import { SmallCardCTA } from "./helpers/getSmallCardCTA";
import { getNotificationSubject } from "./helpers/getNotificationSubject";
import { stringLabelSinceDate } from "@specsheet-common/shared-tools";
import { idFlavor, N_NotificationView } from "@specsheet-common/shared-types";

interface NotificationSmallCardProps {
  notification: N_NotificationView;
  onClick?(id: idFlavor<"Notification">): void;
  onClose?(): void;
}

export const NotificationSmallCard = observer(
  ({ notification, onClick, onClose }: NotificationSmallCardProps) => {
    return (
      <Stack
        spacing={0.4}
        p={2}
        sx={{
          backgroundColor: notification.isRead ? undefined : "#edf3ea",
          ":hover": { backgroundColor: "white" },
          cursor: onClick ? "pointer" : undefined,
        }}
        border={(theme) =>
          `${theme.metrics.borderWidth}px dotted ${theme.colors.colorBorder}`
        }
        borderRadius={2}
        onClick={
          onClick ? () => onClick(notification.notificationId) : undefined
        }
      >
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Stack
            spacing={0.5}
            css={{
              display: "-webkit-box",
              "-webkit-box-orient": "vertical",
              "-webkit-line-clamp": "5",
              overflow: "hidden",
            }}
          >
            {"workspaceName" in notification.byCategoryData &&
              "organisationName" in notification.byCategoryData && (
                <Typography variant="baseBold" display={"block"}>
                  {`${notification.byCategoryData.organisationName} - ${notification.byCategoryData.workspaceName}`}
                </Typography>
              )}
            <Typography variant="body3" display={"block"}>
              {getNotificationSubject(notification.byCategoryData)}
            </Typography>
          </Stack>
          {!notification.isRead && (
            <InfoStatusLabel label="new" bgcolor="#FACF5540" />
          )}
        </Stack>
        <Box
          css={{
            display: "-webkit-box",
            "-webkit-box-orient": "vertical",
            "-webkit-line-clamp": "4",
            overflow: "hidden",
          }}
        >
          <SmallCardCTA notification={notification} onClose={onClose} />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
          justifyContent="flex-end"
        >
          <WatchLaterOutlinedIcon sx={{ fontSize: 14 }} />
          <Typography variant={"smallBold"} sx={{ fontSize: 10 }}>
            {stringLabelSinceDate(new Date(notification.created))}
          </Typography>
        </Stack>
      </Stack>
    );
  }
);
