import React, { ReactNode, createContext } from "react";
import { useLocalStorage } from "../hooks/utils/useLocalStorage";

interface SidePanelProviderProps {
  children: ReactNode;
}
export interface SidePanelContextProps {
  isSidePanelExpanded: boolean;
  onSidePanelExpandedChange(expanded: boolean): void;
}
export const SidePanelContext = createContext<SidePanelContextProps | null>(
  null
);

export const SidePanelProvider = ({ children }: SidePanelProviderProps) => {
  const [isSidePanelExpanded, onSidePanelExpandedChange] =
    useLocalStorage<boolean>({
      key: `side-panel-expanded`,
      defaultValue: true,
    });

  return (
    <SidePanelContext.Provider
      value={{ isSidePanelExpanded, onSidePanelExpandedChange }}
    >
      {children}
    </SidePanelContext.Provider>
  );
};
