import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export const CloseIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox={"0 0 16 16"} fill="currentColor">
    <path
      d="M2.78243 3.01938C2.80449 2.96623 2.83681 2.91795 2.87756 2.87731C2.9182 2.83657 2.96647 2.80424 3.01963 2.78219C3.07278 2.76013 3.12976 2.74878 3.18731 2.74878C3.24485 2.74878 3.30183 2.76013 3.35498 2.78219C3.40814 2.80424 3.45642 2.83657 3.49706 2.87731L7.9998 7.38005L12.5025 2.87731C12.5432 2.83657 12.5915 2.80424 12.6446 2.78219C12.6978 2.76013 12.7547 2.74878 12.8123 2.74878C12.8698 2.74878 12.9268 2.76013 12.98 2.78219C13.0331 2.80424 13.0814 2.83657 13.122 2.87731C13.1628 2.91795 13.1951 2.96623 13.2172 3.01938C13.2392 3.07253 13.2506 3.12951 13.2506 3.18706C13.2506 3.24461 13.2392 3.30159 13.2172 3.35474C13.1951 3.40789 13.1628 3.45617 13.122 3.49681L8.6193 7.99955L13.1221 12.5023C13.2042 12.5845 13.2504 12.6959 13.2504 12.8121C13.2504 12.9282 13.2042 13.0397 13.1221 13.1218C13.0399 13.204 12.9285 13.2501 12.8123 13.2501C12.6961 13.2501 12.5847 13.204 12.5026 13.1218L7.9998 8.61905L3.49704 13.1218C3.41489 13.204 3.30347 13.2501 3.18729 13.2501C3.07111 13.2501 2.95969 13.204 2.87754 13.1218C2.79539 13.0397 2.74924 12.9282 2.74924 12.8121C2.74924 12.6959 2.79539 12.5845 2.87754 12.5023L7.3803 7.99955L2.87756 3.49681C2.83681 3.45617 2.80449 3.40789 2.78243 3.35474C2.76038 3.30159 2.74902 3.24461 2.74902 3.18706C2.74902 3.12951 2.76038 3.07253 2.78243 3.01938Z"
      fill="#0000FF"
    />
  </SvgIcon>
);
