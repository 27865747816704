import { useContext } from "react";
import { AuthContext } from "src/contexts/AuthContext";

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("No auth context provided");
  }

  return context;
};
