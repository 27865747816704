import { Components, Theme } from "@mui/material";

export const MuiDialogActions: Components<Theme>["MuiDialogActions"] = {
  // So all dialog actions have padding-top of 16px
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(4, 0, 0, 0),
      justifyContent: "space-between",
    }),
  },
};
