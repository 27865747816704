import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../../../hooks/contexts/useAuth";
import { orgMemberStatus, routePaths } from "@specsheet-common/shared-types";

export const ActiveAccountGuard: React.FC = ({ children }) => {
  const { isAuthenticated, organisation } = useAuth();

  if (isAuthenticated && organisation.status === orgMemberStatus.Active) {
    return <Navigate to={routePaths.dashboard} />;
  }

  return <>{children}</>;
};
