import React, { useCallback } from "react";
import { ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Editor } from "slate";
import { Box } from "@mui/system";
import { useWysiwygEditor } from "../useWysiwygEditor";

interface ControlPanelProps {
  editor: Editor;
}

export const ControlPanel = ({ editor }: ControlPanelProps) => {
  const theme = useTheme();
  const { isMarkActive, isBlockActive, toggleMark, toggleBlock } =
    useWysiwygEditor({ editor });

  const onBoldClicked = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      toggleMark("bold");
    },
    [toggleMark]
  );

  const onItalicClicked = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      toggleMark("italic");
    },
    [toggleMark]
  );

  const onUnderlineClicked = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      toggleMark("underline");
    },
    [toggleMark]
  );

  const onNumberedListClicked = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      toggleBlock("numberedList");
    },
    [toggleBlock]
  );

  const onBulletedListClicked = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      toggleBlock("bulletedList");
    },
    [toggleBlock]
  );

  return (
    <ToggleButtonGroup
      value={Editor.marks(editor)}
      size={"small"}
      sx={{ backgroundColor: "transparent" }}
    >
      <ToggleButton
        value="bold"
        selected={isMarkActive("bold")}
        onClick={onBoldClicked}
      >
        <FormatBoldIcon />
      </ToggleButton>
      <ToggleButton
        value="italic"
        selected={isMarkActive("italic")}
        onClick={onItalicClicked}
      >
        <FormatItalicIcon />
      </ToggleButton>
      <ToggleButton
        value="underline"
        selected={isMarkActive("underline")}
        onClick={onUnderlineClicked}
      >
        <FormatUnderlinedIcon />
      </ToggleButton>
      <Box borderLeft={`1px solid ${theme.newColors.neutral.gray200}`} />
      <ToggleButton
        value="numberedList"
        selected={isBlockActive("numberedList")}
        onClick={onNumberedListClicked}
      >
        <FormatListNumberedIcon />
      </ToggleButton>
      <ToggleButton
        value="bulletedList"
        selected={isBlockActive("bulletedList")}
        onClick={onBulletedListClicked}
      >
        <FormatListBulletedIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
