import { ConstantsMap, getEnv } from "./util";

const data: ConstantsMap<{ segmentWriteKey: string; clarityId?: string }> = {
  prod: {
    segmentWriteKey: "Ue2lpLc3YPtbDqSIAIS8HTkC5osx8AvB",
    clarityId: "ibd5mufgmf",
  },
  "app.prod": {
    segmentWriteKey: "Ue2lpLc3YPtbDqSIAIS8HTkC5osx8AvB",
    clarityId: "ibd5mufgmf",
  },
  "app.staging": {
    segmentWriteKey: "sZAZ2bh9WisnArNUQQKS380rcQ0ZJYOT",
    clarityId: "hycul5kyou",
  },
  "app.testing": {
    segmentWriteKey: "sZAZ2bh9WisnArNUQQKS380rcQ0ZJYOT",
  },
  "app.develop": {
    segmentWriteKey: "sZAZ2bh9WisnArNUQQKS380rcQ0ZJYOT",
  },
  local: {
    segmentWriteKey: "sZAZ2bh9WisnArNUQQKS380rcQ0ZJYOT",
  },
};

export const { segmentWriteKey, clarityId } = data[getEnv()];
