import { Link, useTheme } from "@mui/material";
import React from "react";
import { TssLogoWithLabel } from "src/ui/components/shared/atoms/icons/TssLogoWithLabel";

export const TssLogoLink = () => {
  const theme = useTheme();

  return (
    <Link href="/">
      <TssLogoWithLabel
        css={{ width: 170, height: 16, fill: theme.colors.colorBlue }}
      />
    </Link>
  );
};
