import React from "react";
import { Navigate } from "react-router-dom";
import { routePaths } from "@specsheet-common/shared-types";
import { useAuth0 } from "@auth0/auth0-react";

export const GuestGuard: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return <Navigate to={routePaths.dashboard} />;
  }

  return <>{children}</>;
};
