import { useContext } from "react";
import { BuildCampaignContext } from "../../contexts/BuildCampaignContext";
import { BuildCampaignPageController } from "src/controllers/media-planner/build-campaign-controller";

export function useBuildCampaignContext(
  allowUndefined: true
): BuildCampaignPageController | undefined;
export function useBuildCampaignContext(
  allowUndefined?: false
): BuildCampaignPageController;
export function useBuildCampaignContext(allowUndefined?: boolean) {
  const context = useContext(BuildCampaignContext);

  if (!context && allowUndefined !== true) {
    throw new Error(
      "No context for build campaign. Please check that you call useBuildCampaignContext inside BuildCampaignContextProvider"
    );
  }

  return context;
}
