import {
  Avatar,
  AvatarProps,
  SxProps,
  Theme,
  Tooltip,
  TooltipProps,
} from "@mui/material";
import * as React from "react";
import Color from "color";
import { CSSProperties } from "react";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    // eslint-disable-next-line unicorn/prefer-code-point
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let rgb = "";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    // eslint-disable-next-line unicorn/prefer-string-slice
    rgb += `00${value.toString(16)}`.substring(2);
  }

  rgb = rgb.padStart(6, "0");
  const color = Color(`#${rgb}`).hsl();
  const adjustedColor = color.saturationl(100).lightness(40).rgb().string();

  return adjustedColor;
}

function stringAvatar(name: string, isActive?: boolean) {
  return {
    sx: {
      bgcolor: isActive === false ? "white" : stringToColor(name),
    },
    children: `${name.split(" ")[0][0]?.toUpperCase()}${
      name.split(" ")[1] ? name.split(" ")[1][0]?.toUpperCase() : ""
    }`,
  };
}

export interface ColoredAvatarProps extends AvatarProps {
  imgSrc?: string;
  title?: string;
  subTitle?: string;
  tooltipLabel?: React.ReactNode;
  isActive?: boolean;
  tooltipPlacement?: TooltipProps["placement"];
  tooltipCss?: CSSProperties;
  tooltipArrowCss?: CSSProperties;
}

export const ColoredAvatar = ({
  imgSrc,
  title,
  subTitle,
  tooltipLabel,
  isActive,
  tooltipPlacement,
  tooltipCss,
  tooltipArrowCss,
  ...rest
}: ColoredAvatarProps) => {
  const fullinfo = title || subTitle || "NA";

  const renderAvatar = () => {
    if (!imgSrc || imgSrc.endsWith("/")) {
      const stringAvatarSx = stringAvatar(fullinfo, isActive).sx;

      const avatarSx: SxProps<Theme> = {
        ...stringAvatarSx,
        ...rest.sx,
        color: isActive === false ? "#bbb8b8" : undefined,
        outlineColor: `${
          isActive === false ? "#bbb8b8" : stringAvatarSx.bgcolor
        }!important`,
      };

      return (
        <Avatar {...stringAvatar(fullinfo, isActive)} {...rest} sx={avatarSx} />
      );
    }

    return <Avatar src={imgSrc} alt={title} {...rest} />;
  };

  if (tooltipLabel) {
    return (
      <Tooltip
        placement={tooltipPlacement || "top"}
        title={tooltipLabel}
        arrow
        componentsProps={{
          tooltip: { sx: tooltipCss },
          arrow: { sx: tooltipArrowCss },
        }}
      >
        {renderAvatar()}
      </Tooltip>
    );
  }

  return renderAvatar();
};
