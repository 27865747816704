import React, { useRef, useState } from "react";
import { Box, Link, Stack, Typography, TypographyProps } from "@mui/material";
import { useSize } from "../../../../hooks/utils/useSize";

interface ExpandableStringProps {
  typographyProps: TypographyProps;
}

export const ExpandableString: React.FC<ExpandableStringProps> = ({
  typographyProps,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const boxRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);

  const boxSize = useSize(boxRef);
  const textSize = useSize(textRef);

  const checkIfNotFit = () => {
    if (!boxSize || !textSize) {
      return false;
    }
    return textSize.width > boxSize.width;
  };

  const showControls = checkIfNotFit();

  return (
    <Stack
      ref={boxRef}
      direction={isExpanded ? "column" : "row"}
      css={{
        width: "100%",
        maxWidth: "100%",
        display: "flex",
      }}
    >
      <Box
        sx={{
          whiteSpace: isExpanded ? "normal" : "nowrap",
          overflow: isExpanded ? "auto" : "hidden",
        }}
      >
        <Typography ref={textRef} {...typographyProps}>
          {children}
        </Typography>
      </Box>
      {!isExpanded && showControls && (
        <Link ml={0.5} onClick={() => setIsExpanded(true)}>
          ...
        </Link>
      )}
      {isExpanded && showControls && (
        <Link variant={"small"} onClick={() => setIsExpanded(false)}>
          hide
        </Link>
      )}
    </Stack>
  );
};
