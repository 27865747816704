import { Service } from "../service";
import { PlacementView } from "../../adl-gen/kachemedia/specsheet/campaigns";
import { DbKey } from "../../adl-gen/common/db";
import { Placement } from "../../adl-gen/kachemedia/specsheet/db";

export const fetchById = async (args: {
  service: Pick<Service, "queryPlacement">;
  placementId: DbKey<Placement>;
}): Promise<PlacementView | null> => {
  const { service, placementId } = args;

  const data = await service.queryPlacement(placementId);

  if (data.kind === "ok") {
    return data.value;
  }

  return null;
};
