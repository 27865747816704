import React, { useEffect, useState } from "react";
import { useApplicationVersionContext } from "../../../../hooks/contexts/useApplicationVersionContext";
import { getApplicationVersion } from "@specsheet-common/shared-tools";
import { useSnackbar } from "notistack";
import { Snackbar } from "../molecules/snackbars/Snackbar";
import { Link, Typography } from "@mui/material";

export const ApplicationVersionWithContext: React.FC = () => {
  const { version } = useApplicationVersionContext();
  const [clientVersion] = useState(getApplicationVersion());
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.groupCollapsed("versions");
    // eslint-disable-next-line no-console
    console.log("server version:", version);
    // eslint-disable-next-line no-console
    console.log("client version:", clientVersion);
    // eslint-disable-next-line no-console
    console.groupEnd();
  }, [version, clientVersion]);

  const clearCacheData = async () => {
    const cacheKeys = await caches.keys();
    return Promise.all(cacheKeys.map(async (name) => caches.delete(name)));
  };

  useEffect(() => {
    if (clientVersion !== version) {
      // eslint-disable-next-line no-console
      console.warn("Triggering application version mismatch alert", {
        clientVersion,
        version,
      });
      enqueueSnackbar("Your application is out of date, please reload.", {
        variant: "warning",
        persist: true,
        preventDuplicate: true,
        content: (id, message) => (
          <Snackbar
            id={id}
            message={<Typography>{message}</Typography>}
            severity="warning"
            action={
              <Link
                component="button"
                underline="none"
                onClick={async () => {
                  await clearCacheData();
                  window.location.reload();
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={(theme) => ({ color: theme.newColors.neutral.white })}
                >
                  Reload
                </Typography>
              </Link>
            }
          />
        ),
      });
    }
  }, [clientVersion, version]);

  return <></>;
};
