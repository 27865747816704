import {
  coreRoutes,
  notificationSubRoute,
  urlBuilder,
} from "@specsheet-common/shared-types";
import { useMutation } from "src/service/reactQuery";
import { queryKeys } from "../../keys";
import { useQueryClient } from "react-query";

interface MutationProps {
  onSuccess?(): void;
  onError?(): void;
}

export const useMarkAllAsRead = ({ onError, onSuccess }: MutationProps) => {
  const queryClient = useQueryClient();

  return useMutation<void, never>({
    method: "get",
    url: urlBuilder({
      root: coreRoutes.notifications,
      sub: notificationSubRoute.markAllAsRead,
    }),
    onError,
    onSuccess: () => {
      void queryClient.invalidateQueries(
        queryKeys.notifications.fetchNotifications
      );
      if (onSuccess) {
        onSuccess?.();
      }
    },
  });
};
