import { useSnackbar } from "notistack";
import * as datadog from "src/util/datadog";

export const useError = () => {
  const { enqueueSnackbar } = useSnackbar();
  return {
    error: (e: unknown) => {
      datadog.error(e, {});
    },
    errorAlert: (message: string | undefined, error?: unknown) => {
      enqueueSnackbar(message || "Something went wrong", { variant: "error" });
      datadog.error(error, { originalMessage: message });
    },
  };
};
