import { useContext } from "react";
import { ApplicationVersionContext } from "../../contexts/ApplicationVersionContext";

export const useApplicationVersionContext = () => {
  const context = useContext(ApplicationVersionContext);

  if (!context) {
    throw new Error(
      "No Application Version context available. Please check that you call useApplicationVersionContext inside ApplicationVersionProvider"
    );
  }

  return context;
};
