import React from "react";
import { makeIconComponent } from "../icon/icons";

const ImgSVG = (
  <svg
    width="41"
    height="37"
    viewBox="0 0 41 37"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M25.7391 2L26.6051 1.5L25.7391 -2.38419e-07L24.873 1.5L25.7391 2ZM38.9357 24.8573V25.8573H40.6678L39.8017 24.3573L38.9357 24.8573ZM12.5424 24.8573L11.6764 24.3573L10.8104 25.8573H12.5424V24.8573ZM24.873 2.5L38.0697 25.3573L39.8017 24.3573L26.6051 1.5L24.873 2.5ZM19.223 15.2861L26.6051 2.5L24.873 1.5L17.491 14.2861L19.223 15.2861ZM12.4286 14.1302C14.4109 14.1302 16.2612 14.6823 17.8376 15.6406L18.8765 13.9316C16.9959 12.7883 14.7876 12.1302 12.4286 12.1302V14.1302ZM2 24.5588C2 18.7992 6.66906 14.1302 12.4286 14.1302V12.1302C5.56449 12.1302 0 17.6947 0 24.5588H2ZM12.4286 34.9874C6.66906 34.9874 2 30.3184 2 24.5588H0C0 31.423 5.56449 36.9874 12.4286 36.9874V34.9874ZM22.8538 24.8316C22.7094 30.4648 18.097 34.9874 12.4286 34.9874V36.9874C19.1844 36.9874 24.681 31.5975 24.8531 24.8829L22.8538 24.8316ZM38.9357 23.8573H23.8535V25.8573H38.9357V23.8573ZM23.8535 23.8573H12.5424V25.8573H23.8535V23.8573ZM13.4084 25.3573L19.223 15.2861L17.491 14.2861L11.6764 24.3573L13.4084 25.3573ZM17.8376 15.6406C20.8493 17.4715 22.8573 20.7811 22.8573 24.5588H24.8573C24.8573 20.054 22.4601 16.1101 18.8765 13.9316L17.8376 15.6406ZM22.8573 24.5588C22.8573 24.65 22.8561 24.741 22.8538 24.8316L24.8531 24.8829C24.8559 24.7752 24.8573 24.6671 24.8573 24.5588H22.8573Z" />
  </svg>
);

export const ImgIcon = makeIconComponent("Img", ImgSVG);
