import React from "react";
import { CustomElement } from "../types";

interface ElementProps {
  attributes: unknown;
  element: CustomElement;
}

export const Element: React.FC<ElementProps> = ({
  attributes,
  children,
  element,
}) => {
  switch (element.kind) {
    case "numberedList": {
      return (
        // @ts-expect-error after ts update
        <ol css={{ listStyleType: "decimal", marginLeft: 30 }} {...attributes}>
          {children}
        </ol>
      );
    }
    case "listItem": {
      // @ts-expect-error after ts update
      return <li {...attributes}>{children}</li>;
    }
    case "bulletedList": {
      return (
        // @ts-expect-error after ts update
        <ul css={{ listStyleType: "disc", marginLeft: 30 }} {...attributes}>
          {children}
        </ul>
      );
    }
    default: {
      return (
        // @ts-expect-error after ts update
        <p style={{ lineHeight: "2em" }} {...attributes}>
          {children}
        </p>
      );
    }
  }
};
