import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

interface ContactSalesDialogProps {
  onClose(): void;
  chatButtonProps: {
    id?: string;
  };
  onBookCall(): void;
}

export const ContactSalesDialog = ({
  onClose,
  chatButtonProps,
  onBookCall,
}: ContactSalesDialogProps) => {
  return (
    <>
      <DialogContent dividers>
        <Typography
          variant={"h1"}
          sx={(theme) => ({
            ...theme.typography.h1,
            color: theme.newColors.neutral.primary,
            paddingTop: theme.spacing(4),
            marginBottom: theme.spacing(6),
          })}
          textAlign={"center"}
        >
          🚀
        </Typography>
        <Typography
          variant={"h3"}
          sx={(theme) => ({
            ...theme.typography.h3,
            color: theme.newColors.neutral.primary,
            marginBottom: theme.spacing(4),
          })}
          textAlign={"center"}
        >
          Let us help you get started.
        </Typography>

        <Typography
          variant={"body1"}
          sx={(theme) => ({
            ...theme.typography.body1,
            color: theme.newColors.neutral.gray500,
          })}
          textAlign={"center"}
        >
          We would love to help you get started on the Spec Sheet. To setup an
          organisation and use the wider feature set we have to offer, please
          book in a call or start a chat with one of our sales representatives.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button
          onClick={onClose}
          color="gray"
          variant="outlined"
          sx={{ width: "160px", height: "32px" }}
        >
          Cancel
        </Button>
        <Stack direction="row" gap={4}>
          <Button
            color="gray"
            variant="outlined"
            onClick={onBookCall}
            sx={(theme) => ({
              width: "120px",
              height: "32px",
              border: "none",
              ":not(:hover)": {
                color: theme.newColors.theme.primary,
              },
            })}
          >
            Book a Call
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{ width: "160px", height: "32px" }}
            {...chatButtonProps}
          >
            Start a Chat
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};
