import React, { Fragment, lazy, LazyExoticComponent, useEffect } from "react";
import { Route, RouteObject, Routes, useLocation } from "react-router-dom";
import { AuthGuard } from "src/ui/components/shared/system/AuthGuard";
import {
  PortalPageLayoutZeroPadding,
  PortalPageLayoutZeroPaddingNoOverflow,
} from "src/ui/layouts/PortalPageLayoutVariations";
import { ActionPageLayout } from "src/ui/layouts/ActionPageLayout";
import { PortalPageLayoutBasic } from "src/ui/layouts/PortalPageLayoutBasic";
import { GuestGuard } from "src/ui/components/shared/system/GuestGuard";
import { ActiveAccountGuard } from "src/ui/components/shared/system/ActiveAccountGuard";
import { routePaths } from "@specsheet-common/shared-types";
import { useAnalytics } from "./hooks/contexts/useAnalytics";
import { NewPublicPageLayout } from "./ui/layouts/NewPublicPageLayout";

interface RouteProps extends RouteObject {
  guard?: React.FC;
  layout?: React.FC;
  component: LazyExoticComponent<React.FC>;
  path: string;
  routes?: RouteProps[];
}

export const renderRoutes = (routes: RouteProps[] = []) =>
  routes.map((route: RouteProps, i: number) => {
    const Guard = route.guard || Fragment;
    const Layout = route.layout || Fragment;
    const Component = route.component;

    return (
      <Route
        key={i}
        path={route.path}
        element={
          <Guard>
            <Layout>
              <Component />
            </Layout>
          </Guard>
        }
        children={route.routes ? renderRoutes(route.routes) : undefined}
      />
    );
  });

export const routes = (): RouteProps[] => [
  {
    path: routePaths.status,
    layout: ActionPageLayout,
    component: lazy(() => import("src/ui/pages/public/StatusPage")),
  },
  {
    path: routePaths.login,
    guard: GuestGuard,
    layout: ActionPageLayout,
    component: lazy(() => import("src/ui/pages/public/LoginPage")),
  },
  {
    path: routePaths.forgotPassword,
    layout: ActionPageLayout,
    component: lazy(() => import("src/ui/pages/public/LoginPage")),
  },
  {
    path: routePaths.userProfile,
    layout: PortalPageLayoutZeroPadding,
    guard: AuthGuard,
    component: lazy(() => import("src/ui/pages/ProfilePage")),
  },
  {
    path: routePaths.dashboard,
    layout: PortalPageLayoutZeroPadding,
    guard: AuthGuard,
    component: lazy(() => import("src/ui/pages/DashboardPage")),
  },
  {
    path: routePaths.shared,
    layout: PortalPageLayoutZeroPadding,
    guard: AuthGuard,
    component: lazy(() => import("src/ui/pages/SharedWithMePage")),
    routes: [
      {
        path: routePaths.sharedCampaigns,
        component: lazy(
          () => import("src/ui/pages/SharedWithMePage/Campaigns")
        ),
      },
    ],
  },
  {
    path: `${routePaths.sharedCampaignBuild}/:campaignId`,
    layout: PortalPageLayoutZeroPaddingNoOverflow,
    guard: AuthGuard,
    component: lazy(() => import("src/ui/pages/NewBuildCampaignPage")),
  },
  {
    path: routePaths.campaignCreate,
    layout: PortalPageLayoutZeroPaddingNoOverflow,
    guard: AuthGuard,
    component: lazy(() => import("src/ui/pages/CreateCampaignPage")),
  },
  {
    path: `${routePaths.newDuplicate}/:campaignId`,
    layout: PortalPageLayoutZeroPaddingNoOverflow,
    guard: AuthGuard,
    component: lazy(() => import("src/ui/pages/DuplicateCampaignPage")),
  },
  {
    path: routePaths.orgManagement,
    layout: PortalPageLayoutZeroPadding,
    guard: AuthGuard,
    component: lazy(() => import("src/ui/pages/OrgManagementPage")),
  },
  {
    path: routePaths.orgManagement,
    layout: PortalPageLayoutZeroPadding,
    guard: AuthGuard,
    component: lazy(() => import("src/ui/pages/OrgManagement/Layout")),
    routes: [
      {
        path: routePaths.orgWorkspaces,
        component: lazy(() => import("src/ui/pages/OrgManagement/Workspaces")),
      },
      {
        path: routePaths.orgMembers,
        component: lazy(() => import("src/ui/pages/OrgManagement/Members")),
      },
    ],
  },
  {
    path: routePaths.workspaceManagement,
    layout: PortalPageLayoutZeroPadding,
    guard: AuthGuard,
    component: lazy(() => import("src/ui/pages/WorkspaceManagementPage")),
    routes: [
      {
        path: routePaths.workspaceTemplates,
        component: lazy(
          () =>
            import("src/ui/pages/WorkspaceManagementPage/WorkspaceTemplates")
        ),
      },
      {
        path: routePaths.workspaceClients,
        component: lazy(
          () => import("src/ui/pages/WorkspaceManagementPage/WorkspaceClients")
        ),
      },
      {
        path: routePaths.workspaceCampaigns,
        component: lazy(
          () => import("src/ui/pages/NewWorkspaceManagementPage/Campaigns")
        ),
      },
      {
        path: routePaths.newWorkspaceMembers,
        component: lazy(
          () => import("src/ui/pages/NewWorkspaceManagementPage/Members")
        ),
      },
      {
        path: routePaths.newWorkspaceGroups,
        component: lazy(
          () => import("src/ui/pages/NewWorkspaceManagementPage/Groups")
        ),
      },
      {
        path: routePaths.newWorkspaceClients,
        component: lazy(
          () => import("src/ui/pages/NewWorkspaceManagementPage/Clients")
        ),
      },
      {
        path: routePaths.newWorkspaceCampaigns,
        component: lazy(
          () => import("src/ui/pages/NewWorkspaceManagementPage/Campaigns")
        ),
      },
    ],
  },
  {
    path: routePaths.notificationCenter,
    layout: PortalPageLayoutZeroPadding,
    guard: AuthGuard,
    component: lazy(() => import("src/ui/pages/NotificationCenterPage")),
  },
  {
    path: routePaths.campaignEdit,
    layout: PortalPageLayoutBasic,
    guard: AuthGuard,
    component: lazy(
      () =>
        import(
          "src/ui/pages/CreateEditDuplicateCampaignPage/EditCampaignNamePage"
        )
    ),
    routes: [
      {
        path: ":campaignId",
        component: lazy(
          () =>
            import(
              "src/ui/pages/CreateEditDuplicateCampaignPage/EditCampaignNamePage"
            )
        ),
      },
    ],
  },
  {
    path: `${routePaths.campaignView}/:campaignId`,
    component: lazy(() => import("src/ui/pages/public/NewSpecsViewPage")),
    guard: AuthGuard,
    routes: [
      {
        path: ":version",
        guard: AuthGuard,
        component: lazy(() => import("src/ui/pages/public/NewSpecsViewPage")),
      },
    ],
  },
  {
    path: `${routePaths.campaignBuild}/:campaignId`,
    layout: PortalPageLayoutZeroPaddingNoOverflow,
    guard: AuthGuard,
    component: lazy(() => import("src/ui/pages/NewBuildCampaignPage")),
  },
  {
    path: `${routePaths.campaignDuplicate}/:campaignId`,
    layout: PortalPageLayoutBasic,
    guard: AuthGuard,
    component: lazy(
      () =>
        import(
          "src/ui/pages/CreateEditDuplicateCampaignPage/DuplicateCampaignPage"
        )
    ),
  },
  {
    path: `${routePaths.placementView}/:placementId`,
    guard: AuthGuard,
    component: lazy(() => import("src/ui/pages/public/PlacementViewPage")),
  },
  {
    path: `${routePaths.resetPassword}/:actionLinkToken`,
    layout: ActionPageLayout,
    component: lazy(() => import("src/ui/pages/public/PublicActionPage")),
  },
  {
    path: `${routePaths.welcome}/:actionLinkToken`,
    layout: ActionPageLayout,
    guard: GuestGuard,
    component: lazy(() => import("src/ui/pages/public/ActivateAccountPage")),
  },
  {
    path: `${routePaths.mediaOwner}`,
    guard: AuthGuard,
    layout: PortalPageLayoutBasic,
    component: lazy(() => import("src/ui/pages/MediaOwnerHomePage")),
  },
  {
    path: `${routePaths.logout}`,
    layout: NewPublicPageLayout,
    component: lazy(() => import("src/ui/pages/public/Logout")),
  },
  {
    path: `${routePaths.inactiveAccount}`,
    layout: ActionPageLayout,
    guard: ActiveAccountGuard,
    component: lazy(() => import("src/ui/pages/DeactivatedUserPage")),
  },
  {
    path: `${routePaths.setup}`,
    layout: ActionPageLayout,
    component: lazy(() => import("src/ui/pages/OnboardingPage")),
  },
  { path: "*", component: lazy(() => import("src/ui/pages/NotFoundPage")) },
];

export const TssRoutes = () => {
  const { page } = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    const url = location.pathname + location.search + location.hash;
    if (location.pathname.startsWith("/campaign")) {
      const paths = location.pathname.split("/");
      void page(url, { campaignId: paths[paths.length - 1] });
      return;
    }
    void page(location.pathname + location.search + location.hash, {});
  }, [location, page]);

  return <Routes>{renderRoutes(routes())}</Routes>;
};
