import { ConstantsMap, getEnv } from "./util";
interface Values {
  authURL: string;
  coreApiURL: string;
  legacyApiURL: string;
  socketIoURL: string;
  adminClientUrl: string;
  coreClientUrl: string;
  environment: string;
}
const data: ConstantsMap<Values> = {
  "app.develop": {
    authURL: "https://auth.develop.thespecsheet.com",
    coreApiURL: "https://coreapi.develop.thespecsheet.com",
    legacyApiURL: "https://legacyapi.develop.thespecsheet.com",
    socketIoURL: "https://ws.develop.thespecsheet.com",
    adminClientUrl: "https://ops.develop.thespecsheet.com",
    coreClientUrl: "https://app.develop.thespecsheet.com",
    environment: "develop",
  },
  "app.testing": {
    authURL: "https://auth.testing.thespecsheet.com",
    coreApiURL: "https://coreapi.testing.thespecsheet.com",
    legacyApiURL: "https://legacyapi.testing.thespecsheet.com",
    socketIoURL: "https://ws.testing.thespecsheet.com",
    adminClientUrl: "https://ops.testing.thespecsheet.com",
    coreClientUrl: "https://app.testing.thespecsheet.com",
    environment: "testing",
  },
  "app.staging": {
    authURL: "https://auth.staging.thespecsheet.com",
    coreApiURL: "https://coreapi.staging.thespecsheet.com",
    legacyApiURL: "https://legacyapi.staging.thespecsheet.com",
    socketIoURL: "https://ws.staging.thespecsheet.com",
    adminClientUrl: "https://ops.staging.thespecsheet.com",
    coreClientUrl: "https://app.staging.thespecsheet.com",
    environment: "staging",
  },
  "app.prod": {
    authURL: "https://auth.prod.thespecsheet.com",
    coreApiURL: "https://coreapi.prod.thespecsheet.com",
    legacyApiURL: "https://legacyapi.prod.thespecsheet.com",
    socketIoURL: "https://ws.prod.thespecsheet.com",
    adminClientUrl: "https://ops.prod.thespecsheet.com",
    coreClientUrl: "https://app.thespecsheet.com",
    environment: "prod",
  },
  prod: {
    authURL: "https://auth.thespecsheet.com",
    coreApiURL: "https://coreapi.thespecsheet.com",
    legacyApiURL: "https://legacyapi.thespecsheet.com",
    socketIoURL: "https://ws.thespecsheet.com",
    adminClientUrl: "https://ops.thespecsheet.com",
    coreClientUrl: "https://app.thespecsheet.com",
    environment: "prod",
  },
  local: {
    authURL: "http://localhost:3030",
    coreApiURL: "http://localhost:3010",
    legacyApiURL: "http://localhost:8081",
    socketIoURL: "http://localhost:3050",
    adminClientUrl: "http://localhost:3005",
    coreClientUrl: "http://localhost:3000",
    environment: "develop",
  },
};

const urls = data[getEnv()];

export const authURL = urls.authURL;
export const coreApiURL = urls.coreApiURL;
export const legacyApiURL = urls.legacyApiURL;
export const socketIoURL = urls.socketIoURL;
export const adminClientUrl = urls.adminClientUrl;
export const coreClientUrl = urls.coreClientUrl;
export const environment = urls.environment;
