import React, { createContext } from "react";
import {
  PlacementDetailsControl,
  usePlacementDetailsControl,
} from "src/hooks/utils/usePlacementDetailsControl";

export interface PlacementDetailsControlContextProps {
  placementDetailsControl: PlacementDetailsControl;
}
export const PlacementDetailsControlContext =
  createContext<PlacementDetailsControlContextProps | null>(null);

export const PlacementDetailsControlProvider: React.FC = ({ children }) => {
  const placementDetailsControl = usePlacementDetailsControl();

  return (
    <PlacementDetailsControlContext.Provider
      value={{
        placementDetailsControl,
      }}
    >
      {children}
    </PlacementDetailsControlContext.Provider>
  );
};
