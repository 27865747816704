import { useQueryClient } from "react-query";
import { sleep } from "@specsheet-common/shared-tools";
import {
  coreRoutes,
  CreateWorkspaceReq,
  CreateWorkspaceRes,
  idFlavor,
  urlBuilder,
  workspaceSubRoute,
} from "@specsheet-common/shared-types";
import { queryKeys } from "src/service/queryHooks/keys";
import { useMutation } from "../../../reactQuery";

interface UseCreateWorkspaceProps {
  organisationId: idFlavor<"Organisation">;
  onSuccess?(): void;
  onError?(): void;
  includeArchived: boolean;
  calculateStats: boolean;
}

export const useCreateWorkspace = ({
  organisationId,
  onError,
  onSuccess,
  includeArchived,
  calculateStats,
}: UseCreateWorkspaceProps) => {
  const queryClient = useQueryClient();
  return useMutation<CreateWorkspaceReq, CreateWorkspaceRes>({
    url: urlBuilder({
      root: coreRoutes.workspaces,
      sub: workspaceSubRoute.create,
    }),
    async responseFormatter(data: CreateWorkspaceRes) {
      //TODO replace this hack to avoid picture being unavailable after immediately
      await sleep(500);
      return data;
    },
    onError,
    successMessage: "Workspace successfully created",
    onSuccess: () => {
      void queryClient.invalidateQueries(
        queryKeys.workspaces.fetchWorkspaces({
          organisationId,
          includeArchived,
          calculateStats,
        })
      );
      onSuccess?.();
    },
  });
};
