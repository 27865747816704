import { ClientView } from "src/adl-gen/kachemedia/specsheet/campaigns";
import { Service } from "../service";
import { DbKey } from "src/adl-gen/common/db";
import { Workspace } from "src/adl-gen/kachemedia/specsheet/db";
import {
  debounceAsync,
  DEFAULT_DEBOUNCE_TIME,
} from "@specsheet-common/shared-tools";

/** Refresh the list of placements by search term and filters */
export const fetchClientsByTerm = debounceAsync(
  DEFAULT_DEBOUNCE_TIME,
  async (args: {
    workspaceId: DbKey<Workspace>;
    service: Pick<Service, "queryClients">;
    term: string;
  }): Promise<ClientView[]> => {
    const { service, term, workspaceId } = args;
    return service.queryClients({
      workspaceId,
      iLikeName: term.trim(),
    });
  }
);
