import {
  QueryKey,
  // eslint-disable-next-line no-restricted-imports
  useQuery as useReactQuery,
  UseQueryOptions,
  // eslint-disable-next-line no-restricted-imports
  useInfiniteQuery as useReactInfiniteQuery,
  UseInfiniteQueryOptions,
} from "react-query";
import { useApi } from "../../configuration/api";
import { AxiosError, Method } from "axios";
import { useState } from "react";

type Query<TVariables, TData> = {
  queryKey: QueryKey;
  url: string;
  method?: Method | string;
  data?: TVariables;
  params?: TVariables;
  responseFormatter?(data: unknown): TData;
} & Omit<UseQueryOptions<TData, unknown>, "queryFn">;

export const useQuery = <TVariables, TData>({
  queryKey,
  url,
  method = "GET",
  data,
  params,
  ...rest
}: Query<TVariables, TData>) => {
  const api = useApi();
  const [isError, setIsError] = useState(false);
  const [isMissing, setIsMissing] = useState(false);

  const query = useReactQuery<TData>({
    ...rest,
    queryKey,
    queryFn: async () => {
      const responseData = await api.request<TVariables, TData>({
        url,
        method,
        data,
        params,
      });

      if (responseData instanceof AxiosError) {
        if (responseData.response?.status === 500) {
          setIsError(true);
        }
        if (responseData.response?.status === 404) {
          setIsMissing(true);
        }
        throw responseData;
      }

      setIsError(false);
      setIsMissing(false);

      return rest.responseFormatter
        ? rest.responseFormatter(responseData)
        : responseData;
    },
  });
  return {
    ...query,
    meta: {
      isError,
      isMissing,
    },
  };
};

type InfiniteQuery<TVariables, TData> = {
  queryKey: QueryKey;
  url(pageParam: string): string;
  method?: Method | string;
  data?: TVariables;
  params?: TVariables;
  responseFormatter?(data: unknown): TData;
} & Omit<UseInfiniteQueryOptions<TData, unknown>, "queryFn">;

export const useInfiniteQuery = <TVariables, TData>({
  queryKey,
  url,
  method = "GET",
  data,
  params,
  ...rest
}: InfiniteQuery<TVariables, TData>) => {
  const api = useApi();
  const [isError, setIsError] = useState(false);
  const [isMissing, setIsMissing] = useState(false);

  const query = useReactInfiniteQuery<TData>({
    ...rest,
    queryKey,
    queryFn: async ({ pageParam }) => {
      const responseData = await api.request<TVariables, TData>({
        url: url(pageParam),
        method,
        data,
        params,
      });

      if (responseData instanceof AxiosError) {
        if (responseData.response?.status === 500) {
          setIsError(true);
        }
        if (responseData.response?.status === 404) {
          setIsMissing(true);
        }
        throw responseData;
      }

      setIsError(false);
      setIsMissing(false);

      return rest.responseFormatter
        ? rest.responseFormatter(responseData)
        : responseData;
    },
  });

  return {
    ...query,
    meta: {
      isError,
      isMissing,
    },
  };
};
