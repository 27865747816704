import { QueryClient } from "react-query";
import { AxiosError } from "axios";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10_000,
    },
    mutations: {
      retry: (failureCount, error) => {
        return (
          error instanceof AxiosError &&
          error.code === "ERR_NETWORK" &&
          failureCount < 3
        );
      },
    },
  },
});

export { useQuery } from "./query";
export { useMutation } from "./mutation";
