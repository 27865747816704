import {
  routePaths,
  WorkspaceViewWithStats,
} from "@specsheet-common/shared-types";
import React from "react";
import { useAuth } from "src/hooks/contexts/useAuth";
import { useLocationState } from "src/hooks/utils/useLocationState";
import { useQueryWorkspaces } from "src/service/queryHooks/workspaces/queries/useQueryWorkspaces";
import { WorkspaceTile } from "./WorkspaceTile";

interface MenuButtonProps {
  expanded: boolean;
}

export const WorkspaceList = ({ expanded }: MenuButtonProps) => {
  const { organisation } = useAuth();
  const { changeWorkspace } = useLocationState();
  const organisationId = organisation.id;

  const query = useQueryWorkspaces({
    organisationId,
    includeArchived: false,
    calculateStats: false,
    options: {
      staleTime: Number.POSITIVE_INFINITY,
    },
  });
  const workspaces = query.data || [];

  const handleNavToWorkspaceSettings = (ws: WorkspaceViewWithStats) => {
    changeWorkspace(
      routePaths.workspaceCampaigns,
      ws.workspaceAccess.workspace.workspaceId
    );
  };

  const handleNewCampaignInWorkspace = (
    e: React.MouseEvent,
    ws: WorkspaceViewWithStats
  ) => {
    changeWorkspace(
      routePaths.campaignCreate,
      ws.workspaceAccess.workspace.workspaceId
    );
    e.stopPropagation();
  };

  return (
    <>
      {workspaces.map((workspace: WorkspaceViewWithStats, i) => {
        return (
          <WorkspaceTile
            key={i}
            expanded={expanded}
            workspace={workspace}
            onClick={() => handleNavToWorkspaceSettings(workspace)}
            onIconClick={handleNewCampaignInWorkspace}
          />
        );
      })}
    </>
  );
};
