import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { ReactNode, useEffect, useState } from "react";
import React from "react";
import { useAuth } from "../../hooks/contexts/useAuth";
import { getEnv } from "../../constants/util";

const clientSideId: Record<string, string> = {
  prod: "65233fff85757f1341fa1208",
  "app.prod": "65233fff85757f1341fa1208",
  "app.staging": "6528a0247e8abe121bd0c4de",
  "app.testing": "65233fff85757f1341fa1207",
  "app.develop": "6528a0297e8abe121bd0c4e9",
  local: "6528a02e7e8abe121bd0c4f2",
};

export const FeatureFlagProvider = (
  props: React.PropsWithChildren<unknown>
) => {
  const [Provider, setProvider] = useState<
    (({ children }: { children: ReactNode }) => JSX.Element) | undefined
  >(undefined);
  const { profile } = useAuth();

  useEffect(() => {
    const env = getEnv();
    async function fetchData() {
      const provider = await asyncWithLDProvider({
        clientSideID: clientSideId[env],
        context: {
          kind: "multi",
          user: {
            key: profile.userId,
            name: profile.name,
            email: profile.email,
          },
        },
      });

      setProvider(() => provider);
    }

    void fetchData();
  }, [profile.userId, profile.name, profile.email]);

  if (Provider) {
    return <Provider>{props.children}</Provider>;
  }
  return null;
};
