import {
  InputBaseComponentProps,
  TextField,
  TextFieldProps,
  InputLabelProps,
  InputAdornment,
  Typography,
  useTheme,
  SxProps,
} from "@mui/material";
import { Controller, FieldValues, Path } from "react-hook-form";
import React from "react";
import { Control } from "react-hook-form/dist/types";

interface FormTextFieldProps<ControlDataType extends FieldValues> {
  control: Control<ControlDataType>;
  name: Path<ControlDataType>;
  textFieldProps?: TextFieldProps;
  inputProps?: InputBaseComponentProps;
  inputLabelProps?: InputLabelProps;
  placeholder?: string;
  labelAsPlaceholder?: boolean;
  label?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  type?: TextFieldProps["type"];
  size?: TextFieldProps["size"];
  sx?: SxProps;
  multiline?: TextFieldProps["multiline"];
  inputAdornmentText?: string;
  dataPw?: string;
  required?: boolean;
  characterLimit?: number;
  onFocus?(e: React.FocusEvent): void;
}

const getCharacterLimitText = (
  current: string | undefined,
  max: number | undefined
): string | undefined => {
  if (!max) {
    return undefined;
  }
  return `${current?.length ?? 0} / ${max} characters`;
};

export function FormTextField<ControlDataType extends FieldValues>({
  control,
  name,
  textFieldProps,
  placeholder,
  label,
  fullWidth,
  disabled,
  type,
  size,
  sx,
  labelAsPlaceholder,
  multiline,
  inputProps,
  required,
  inputLabelProps,
  inputAdornmentText,
  dataPw,
  characterLimit,
  onFocus,
}: FormTextFieldProps<ControlDataType>): JSX.Element {
  const theme = useTheme();
  const inputAdornmentProps = inputAdornmentText
    ? {
        startAdornment: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "end",
              backgroundColor: theme.newColors.neutral.gray100,
              height: theme.spacing(8),
              width: theme.spacing(21),
              margin: theme.spacing(-1, 0, -1, -2),
              borderRight: `1px solid ${theme.newColors.neutral.gray200}`,
            }}
          >
            <InputAdornment position="start">
              <Typography
                margin={theme.spacing(0, -2.5, 0, 2.5)}
                variant={"body2"}
                color={theme.newColors.neutral.primary}
              >
                {inputAdornmentText}
              </Typography>
            </InputAdornment>
          </div>
        ),
      }
    : undefined;
  return (
    <Controller
      render={({
        field: { value, onBlur, onChange, ref },
        fieldState: { error },
      }) => (
        <TextField
          onFocus={onFocus}
          id={label}
          required={required}
          label={label}
          placeholder={labelAsPlaceholder ? label : placeholder}
          fullWidth={fullWidth}
          disabled={disabled}
          inputRef={ref}
          value={value}
          onChange={(e) => {
            if (type === "number") {
              const transformedValue =
                e.target.value === ""
                  ? null
                  : Number(e.target.value).toString();
              onChange({
                type: e.type,
                target: {
                  name: e.target.name,
                  type: e.target.type,
                  value: transformedValue,
                },
              });
              return;
            }
            onChange(e);
          }}
          onBlur={onBlur}
          type={type}
          error={Boolean(error)}
          helperText={
            Boolean(error)
              ? error?.message
              : getCharacterLimitText(value, characterLimit)
          }
          size={size}
          sx={{
            ...sx,
            "& .MuiFormHelperText-root": {
              color:
                characterLimit && characterLimit < (value?.length ?? 0)
                  ? `${theme.newColors.utility.yellow} !important`
                  : undefined,
            },
          }}
          multiline={multiline}
          {...textFieldProps}
          inputProps={inputProps}
          InputLabelProps={inputLabelProps}
          InputProps={inputAdornmentProps}
          data-pw={dataPw}
        />
      )}
      name={name}
      control={control}
    />
  );
}
