import {
  N_CampaignChangesSummary,
  N_CampaignVersion,
  specSheetTabSpecial,
} from "@specsheet-common/shared-types";
import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import ReactModal from "react-modal";
import { useGenericTableStore } from "src/hooks/contexts/useGenericTableStore";
import { CampaignVersionChanges } from "../../components/campaign/CampaignChanges/CampaignVersionChanges";
import {
  PrimaryButton,
  PrimaryButtonProps,
  SecondaryButton,
  SecondaryButtonProps,
} from "../../components/shared/atoms/button/button";
import {
  ModalHeaderProps,
  ModalInstance,
} from "../../components/shared/organisms/ModalCanvas";
import { BUILD_CAMPAIGN_PAGE_TABLE_ID } from "../../pages/BuildCampaignPage/constants";
import styles from "./modal.css";
import {
  QuestionModalContent,
  QuestionModalHeader,
} from "./templates/Question";

/** Global modal store */
class Store {
  constructor() {
    makeObservable(this, {
      _instance: observable.ref,
      push: action,
      pop: action,
    });
  }

  private readonly modalStack: ModalInstance[] = [];

  /** Instance of single active modal */
  _instance?: ModalInstance = undefined;

  /** Return a readonly instance */
  get instance(): ModalInstance | undefined {
    return this._instance;
  }

  private setModal(instance: ModalInstance | undefined) {
    this._instance = instance;
  }

  /** Add a modal */
  push(instance: ModalInstance) {
    this.modalStack.push(instance);
    this.setModal(instance);
  }

  /** Close modal (return to previous modal if any) */
  pop() {
    this.modalStack.pop();
    const topModal: ModalInstance | undefined =
      this.modalStack[this.modalStack.length - 1];
    this.setModal(topModal);
  }
}

type ModalSize = "xs" | "sm" | "md" | "lg";

// Set root element for accessibility
// See: http://reactcommunity.org/react-modal/accessibility/
if (process.env.NODE_ENV !== "test") {
  ReactModal.setAppElement("#root");
}

/* Instantiate global modal store */
export const store = new Store();

/** Shows a modal by setting it on the global store - Adds another modal to stack */
function pushModal(instance: ModalInstance) {
  store.push(instance);
}

/** Close a modal */
export function closeModal() {
  store.pop();
}

/**
 *
 * Shows an informational modal
 */
export function showInfoModal(
  content: JSX.Element,
  a11yLabel: string,
  header?: ModalHeaderProps,
  size?: ModalSize,
  customise?: Partial<ModalInstance>
) {
  return new Promise((resolve: (val?: string) => void) => {
    const onClickButton = () => {
      resolve(undefined);
      store.pop();
    };
    const onRequestClose = () => {
      resolve(undefined);
      store.pop();
    };
    pushModal({
      content,
      header,
      a11yLabel,
      buttons: [
        <PrimaryButton key="ok" onClick={onClickButton}>
          OK
        </PrimaryButton>,
      ],
      onRequestClose,
      shouldCloseOnOverlayClick: true,
      className: size ? styles[size + "Modal"] : undefined,
      ...customise,
    });
  });
}

/** Renders the campaign version changes modal. */
export function showCampaignVersionChangesModal(
  changesSummary: N_CampaignChangesSummary,
  publishedStr: string,
  campaignVersion: N_CampaignVersion
) {
  return new Promise((resolve: (val?: string) => void) => {
    const onRequestClose = () => {
      resolve(undefined);
      store.pop();
    };

    const CampaignVersionChangesModalContent = observer(() => {
      const genericTableStore = useGenericTableStore(
        BUILD_CAMPAIGN_PAGE_TABLE_ID
      );

      useEffect(() => {
        genericTableStore.selectTab(specSheetTabSpecial.Overview);
      }, []);

      return (
        <div className={styles.campaignVersionChangesModalContent}>
          <div className={styles.campaignVersionInfo}>
            <b>Version {campaignVersion.version}</b>
            <span>{publishedStr}</span>
            <span>
              {campaignVersion.message && `"${campaignVersion.message}"`}
            </span>
          </div>
          <CampaignVersionChanges
            changesSummary={changesSummary}
            goToRowId={() => {
              onRequestClose();
            }}
          />
        </div>
      );
    });

    return pushModal({
      header: { el: <h3 className={styles.modalHeading}>What's changed</h3> },
      content: <CampaignVersionChangesModalContent />,
      a11yLabel: "Publish",
      buttons: [<PrimaryButton onClick={onRequestClose}>OK</PrimaryButton>],
      onRequestClose,
      className: styles.smModal,
    });
  });
}

/**
 *
 * Shows a request modal
 */
function showRequestModal(
  content: JSX.Element,
  a11yLabel: string,
  primaryButton: PrimaryButtonProps,
  secondaryButton?: SecondaryButtonProps,
  header?: ModalHeaderProps,
  size?: ModalSize,
  callback?: () => void
): Promise<{ kind: "ok" } | { kind: "cancel" }> {
  return new Promise((resolve) => {
    const onClickButton = () => {
      if (callback) {
        callback();
      }
      resolve({ kind: "ok" });
      store.pop();
    };
    const onRequestClose = () => {
      resolve({ kind: "cancel" });
      store.pop();
    };
    pushModal({
      content,
      header,
      a11yLabel,
      buttons: [
        <PrimaryButton
          key="ok"
          color={primaryButton.color}
          onClick={onClickButton}
          className={primaryButton.className}
        >
          {primaryButton.children || "Ok"}
        </PrimaryButton>,
        <SecondaryButton key="cancel" onClick={onRequestClose}>
          {secondaryButton?.children || "Cancel"}
        </SecondaryButton>,
      ],
      onRequestClose,
      shouldCloseOnOverlayClick: true,
      className: size ? styles[size + "Modal"] : styles.smModal,
    });
  });
}

/** Shows a simple question modal */
export function showCampaignWithJobIdQuestionModal(
  title: string,
  question: string
) {
  return showRequestModal(
    <QuestionModalContent content={question} />,
    question,
    {
      color: "blue",
      children: "Modify campaign",
      className: styles.modifyCampaignButton,
    },
    undefined,
    {
      el: <QuestionModalHeader title={title} />,
    },
    "xs"
  );
}
