import {
  // eslint-disable-next-line no-restricted-imports
  useMutation as useReactMutation,
  UseMutationOptions,
} from "react-query";
import { useApi } from "../../configuration/api";
import { AxiosError, AxiosRequestConfig, Method } from "axios";
import { SnackbarMessage, useSnackbar } from "notistack";
import { useError } from "../../hooks/utils/useError";

type Mutation<TVariables = unknown, TData = unknown, TContext = unknown> = {
  url: string | ((variables: TVariables) => string);
  method?: Method | string;
  onError?(
    error?: unknown,
    variables?: TVariables,
    context?: TContext | undefined
  ): void;
  successMessage?:
    | SnackbarMessage
    | ((data: TData, variables: TVariables) => SnackbarMessage);
  responseFormatter?(data: unknown): Promise<TData> | TData;
  requestFormatter?(data: TVariables): unknown;
  requestParams?(
    variables: TVariables
  ): Omit<AxiosRequestConfig, "url" | "method" | "data">;
} & Omit<
  UseMutationOptions<TData, unknown, TVariables, TContext>,
  "mutationFn"
>;

export const useMutation = <
  TVariables = unknown,
  TData = unknown,
  TContext = unknown
>({
  url,
  method = "POST",
  onError,
  requestParams,
  ...rest
}: Mutation<TVariables, TData, TContext>) => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { errorAlert } = useError();
  return useReactMutation<TData, unknown, TVariables, TContext>({
    ...rest,
    mutationFn: async (variables: TVariables): Promise<TData> => {
      const response = await api.request<TVariables, TData>({
        ...requestParams,
        url: typeof url === "function" ? url(variables) : url,
        method,
        data: rest.requestFormatter
          ? rest.requestFormatter(variables)
          : variables,
      });

      if (response instanceof AxiosError) {
        throw response;
      }
      return rest.responseFormatter
        ? rest.responseFormatter(response)
        : response;
    },
    onSuccess: async (...data) => {
      if (rest.successMessage) {
        enqueueSnackbar(
          typeof rest.successMessage === "function"
            ? rest.successMessage(data[0], data[1])
            : rest.successMessage,
          { variant: "success" }
        );
      }
      await rest?.onSuccess?.(...data);
    },
    onError: (e, variables, context) => {
      void onError?.(e, variables, context);
      errorAlert(undefined, e);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    },
  });
};
