import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useResendVerificationEmail } from "../../service/queryHooks/auth/mutations/useResendVerificationEmail";
import { Auth0Dialog } from "../components/auth0/auth0Dialog";
import { Auth0Button } from "../components/auth0/auth0Button";
import { theme as oldTheme } from "src/ui/common/Theme";

// eslint-disable-next-line import/no-default-export
export default ({
  userId,
  email,
  msg,
}: {
  userId: string;
  email: string;
  msg: string;
}) => {
  const [resent, setResent] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const resendVerificationEmailMutation = useResendVerificationEmail({
    onSuccess: () => {
      setResent(true);
      setSending(false);
    },
    onError: () => setSending(false),
  });

  const resendEmail = async () => {
    setSending(true);
    await resendVerificationEmailMutation.mutateAsync({ userId: userId });
  };

  return (
    <ThemeProvider theme={oldTheme}>
      <Auth0Dialog title="Verify Email Address">
        <Typography variant="body2" mt={1.6} fontSize={14} lineHeight={1.5}>
          An email has been sent to:
        </Typography>
        <Typography
          variant="body2"
          mb={1.6}
          fontSize={14}
          lineHeight={1.5}
          fontWeight={700}
        >
          {email}
        </Typography>
        {msg ? (
          <Typography
            variant={"base"}
            align={"center"}
            fontSize={14}
            lineHeight={1.5}
          >
            {msg}
          </Typography>
        ) : null}
        <Box mt={2.4}>
          {sending ? (
            <CircularProgress size={20}></CircularProgress>
          ) : (
            <Auth0Button
              variant="contained"
              fullWidth={true}
              size={"large"}
              onClick={resendEmail}
              disabled={resent}
            >
              Resend Email
            </Auth0Button>
          )}
        </Box>
      </Auth0Dialog>
    </ThemeProvider>
  );
};
