import {
  ChangelogCountsDto,
  idFlavor,
  N_PlacementCategory,
  PaginatedChangeLogDto,
  SearchExtCampaignReq,
  SearchExtClientReq,
} from "@specsheet-common/shared-types";
import { WithDbId } from "src/adl-gen/common/db";
import { Publisher } from "src/adl-gen/kachemedia/specsheet/db";
import {
  FetchInfoSchemaWithValuesReq,
  GenerateLinkPreviewReq,
} from "@specsheet-common/shared-types/dist/entities/infoSchema/req";

export const queryKeys = {
  actionLinks: {
    activationPageData: (actionLinkToken: idFlavor<"ActionLinkToken">) => [
      "fetchActivationDataForActionLink",
      { actionLinkToken },
    ],
  },
  campaigns: {
    fetchCampaignChanges: ({
      campaignId,
      version,
    }: {
      campaignId: idFlavor<"Campaign">;
      version?: number;
    }) => [`fetchCampaignChanges`, { campaignId, version }],
    fetchCampaignChangeLog: ({
      campaignId,
      filters,
    }: {
      campaignId: idFlavor<"Campaign">;
      filters?: Pick<
        PaginatedChangeLogDto,
        "intents" | "collaborator" | "search"
      >;
    }) => [`fetchCampaignChangeLog`, { campaignId, filters }],
    fetchCampaignChangeLogCounts: ({
      campaignId,
      filters,
    }: {
      campaignId: idFlavor<"Campaign">;
      filters?: Pick<ChangelogCountsDto, "intents" | "collaborator" | "search">;
    }) => [`fetchCampaignChangeLogCounts`, { campaignId, filters }],
    fetchCampaignChangeLogUsers: ({
      campaignId,
    }: {
      campaignId: idFlavor<"Campaign">;
    }) => [`fetchCampaignChangeLogUsers`, { campaignId }],
    fetchCampaignChangeLogTypes: ({
      campaignId,
    }: {
      campaignId: idFlavor<"Campaign">;
    }) => [`fetchCampaignChangeLogTypes`, { campaignId }],
    fetchCampaignForDuplication: (campaignId: idFlavor<"Campaign">) => [
      `fetchCampaignForDuplication`,
      { campaignId },
    ],
    fetchFilters: (cacheKey: string) => [`fetchFilters`, { cacheKey }],
    fetchCampaignWithBookings: (campaignId: idFlavor<"Campaign">) => [
      `fetchCampaignWithBookings`,
      { campaignId },
    ],
    fetchCampaignWithBookingsAsAtVersion: (
      campaignId: idFlavor<"Campaign">,
      campaignVersion: number
    ) => [
      `fetchCampaignWithBookingsAsAtVersion`,
      { campaignId, campaignVersion },
    ],
    fetchWorkspaceCampaigns: (workspaceId: idFlavor<"Workspace">) => [
      `fetchWorkspaceCampaigns`,
      { workspaceId },
    ],
    fetchViewOnlyCampaignWithBookings: ({
      campaignId,
      version,
    }: {
      campaignId: idFlavor<"Campaign">;
      version?: number;
    }) => [`fetchViewOnlyCampaignWithBookings`, { campaignId, version }],
    fetchSharedCampaigns: [`fetchSharedCampaigns`],
    fetchCampaignSharedVersionsCount: (campaignId: idFlavor<"Campaign">) => [
      "fetchCampaignSharedVersionsCount",
      { campaignId },
    ],
    fetchClaimedContracts: (campaignId: idFlavor<"Campaign">) => [
      "fetchClaimedContracts",
      { campaignId },
    ],
    fetchUnversionedChangesCount: (campaignId: idFlavor<"Campaign">) => [
      "fetchUnversionedChangesCount",
      { campaignId },
    ],
  },
  campaignUsers: {
    fetchCampaignUsers: (campaignId: idFlavor<"Campaign">) => [
      `fetchCampaignUsers`,
      { campaignId },
    ],
  },
  campaignTemplates: {
    fetchSingleCampaignTemplate: (
      campaignTemplateId: idFlavor<"CampaignTemplate">
    ) => ["fetchSingleCampaignTemplate", { campaignTemplateId }],
    fetchCampaignTemplates: (workspaceId: idFlavor<"Workspace">) => [
      "fetchCampaignTemplates",
      { workspaceId },
    ],
  },
  userGroups: {
    fetchUserGroups: (workspaceId: idFlavor<"Workspace">) => [
      "fetchUserGroups",
      { workspaceId },
    ],
    fetchUserGroupsWithProfiles: (
      campaignOrWorkspaceId?: idFlavor<"Campaign"> | idFlavor<"Workspace">
    ) => [`fetchUserGroupsWithProfiles`, { campaignOrWorkspaceId }],
  },
  clients: {
    fetchWorkspaceClients: (workspaceId: idFlavor<"Workspace">) => [
      `fetchWorkspaceClients`,
      { workspaceId },
    ],
  },
  extCampaigns: {
    fetchExtCampaigns: (req: SearchExtCampaignReq | null) => [
      "fetchExtCampaigns",
      req,
    ],
    fetchExtClients: (req: SearchExtClientReq | null) => [
      "fetchExtClients",
      req,
    ],
  },
  infoSchema: {
    generateLinkPreview: (req: GenerateLinkPreviewReq | null) => [
      "generateLinkPreview",
      req,
    ],
    withValues: (req: FetchInfoSchemaWithValuesReq | null) => [
      "withValues",
      req,
    ],
  },
  notifications: {
    fetchNotifications: ["fetchNotifications"],
  },
  organisations: {
    fetchOrganisations: ["fetchOrganisations"],
  },
  placements: {
    queryPlacement: (placementId: idFlavor<"Placement">) => [
      "queryPlacement",
      placementId,
    ],
    fetchPlacementsList: ({
      organisationId,
      searchParams,
    }: {
      organisationId: idFlavor<"Organisation">;
      searchParams: {
        term: string;
        selectedChannels: string[];
        selectedPublishers: WithDbId<Publisher>[];
        siteIds: string[] | null;
      };
    }) => [
      "fetchPlacementsList",
      JSON.stringify(searchParams),
      { organisationId },
    ],
    fetchPlacementViewsList: ({
      organisationId,
      searchParams,
      orderParams,
    }: {
      organisationId: idFlavor<"Organisation">;
      orderParams: {
        sortByMediaOwner?: "asc" | "desc";
        sortByCategory?: "asc" | "desc";
        sortByName?: "asc" | "desc";
      };
      searchParams: {
        searchTerms?: string[];
        publisherIds?: idFlavor<"Publisher">[];
        publicationIds?: idFlavor<"Publication">[];
        categories?: N_PlacementCategory[];
        placementNames?: string[];
        size: number;
        skip: number;
      };
    }) => [
      "fetchPlacementViewsList",
      JSON.stringify(searchParams),
      JSON.stringify(orderParams),
      { organisationId },
    ],
  },
  orgUsers: {
    fetchOrgUsers: (organisationId: idFlavor<"Organisation">) => [
      `fetchOrgUsers`,
      { organisationId },
    ],
  },
  workspaceUsers: {
    fetchWorkspaceUsers: (workspaceId: idFlavor<"Workspace">) => [
      `fetchWorkspaceUsers`,
      { workspaceId },
    ],
  },
  workspaces: {
    fetchWorkspaces: ({
      organisationId,
      includeArchived,
      calculateStats,
    }: {
      organisationId: idFlavor<"Organisation">;
      includeArchived?: boolean;
      calculateStats?: boolean;
    }) => [
      "fetchWorkspaces",
      { organisationId },
      { includeArchived, calculateStats },
    ],
  },
} as const;
