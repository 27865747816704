import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export const ImageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path d="M1.46935 3.96447C2.40703 3.02678 3.6788 2.5 5.00488 2.5H35.0049C36.331 2.5 37.6027 3.02678 38.5404 3.96447C39.4781 4.90215 40.0049 6.17392 40.0049 7.5V32.5C40.0049 33.8261 39.4781 35.0979 38.5404 36.0355C37.6027 36.9732 36.331 37.5 35.0049 37.5H5.00488C3.6788 37.5 2.40703 36.9732 1.46935 36.0355C0.531667 35.0979 0.00488281 33.8261 0.00488281 32.5V7.5C0.00488281 6.17392 0.531667 4.90215 1.46935 3.96447ZM36.7726 5.73223C36.3038 5.26339 35.6679 5 35.0049 5H5.00488C4.34184 5 3.70596 5.26339 3.23712 5.73223C2.76828 6.20107 2.50488 6.83696 2.50488 7.5V30L9.11988 24.115C9.32421 23.9114 9.59267 23.7848 9.87973 23.7565C10.1668 23.7283 10.4548 23.8001 10.6949 23.96L17.3449 28.39L26.6199 19.115C26.8056 18.9298 27.0448 18.8078 27.3036 18.766C27.5625 18.7243 27.8279 18.7651 28.0624 18.8825L37.5049 23.75V7.5C37.5049 6.83696 37.2415 6.20107 36.7726 5.73223ZM13.9065 16.4017C14.6098 15.6984 15.0049 14.7446 15.0049 13.75C15.0049 12.7554 14.6098 11.8016 13.9065 11.0983C13.2033 10.3951 12.2494 10 11.2549 10C10.2603 10 9.30649 10.3951 8.60323 11.0983C7.89997 11.8016 7.50488 12.7554 7.50488 13.75C7.50488 14.7446 7.89997 15.6984 8.60323 16.4017C9.30649 17.1049 10.2603 17.5 11.2549 17.5C12.2494 17.5 13.2033 17.1049 13.9065 16.4017Z" />
      </g>
      <defs>
        <clipPath id="clip0_833_19390">
          <rect width="40" height="40" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
