import { Components, Theme } from "@mui/material";

export const MuiAlert: Components<Theme>["MuiAlert"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.spacing(2),
    }),
    standardError: ({ theme }) => ({
      border: `${theme.spacing(0.25)} solid ${theme.newColors.utility.red}`,
      background: theme.newColors.utility.redLight,
    }),
    standardInfo: ({ theme }) => ({
      border: `${theme.spacing(0.25)} solid ${theme.newColors.utility.blue}`,
      background: theme.newColors.utility.blueLight,
    }),
  },
};
