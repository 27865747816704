export const getExternalMediaOwnerName = (id: string | undefined) => {
  switch (id) {
    case "OOH": {
      return "oOh!media";
    }
    case "QMS": {
      return "QMS";
    }
    default: {
      return "";
    }
  }
};
