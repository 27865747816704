import { Box, Link, List, ListItem, Stack, Typography } from "@mui/material";
import { toJS } from "mobx";
import React from "react";
import { WYSIWYG } from "../../shared/organisms/material-slate/WYSIWYG";
import { assertNever } from "@specsheet-common/shared-tools";
import { N_CampaignDetailsChange } from "@specsheet-common/shared-types";

interface CampaignDetailUpdatesListProps {
  campaignDetailChanges: N_CampaignDetailsChange[];
}

export const CampaignDetailUpdatesList = ({
  campaignDetailChanges,
}: CampaignDetailUpdatesListProps) => {
  const renderChange = (campaignChange: N_CampaignDetailsChange) => {
    const getFieldName = (): string => {
      switch (campaignChange.kind) {
        case "title": {
          return "Campaign Title";
        }
        case "liveDate": {
          return "Panel Live";
        }
        case "endDate": {
          return "Panel End";
        }
        case "notes": {
          return "Notes";
        }
        case "documents": {
          return "Documents";
        }
      }
    };

    switch (campaignChange.kind) {
      case "title":
      case "liveDate":
      case "endDate": {
        return (
          <Stack width="100%">
            <Typography variant="smallBold">{getFieldName()}</Typography>
            <Stack pl={1}>
              <Typography variant="small" sx={{ fontStyle: "italic" }} noWrap>
                <span css={{ fontWeight: 500 }}>{`Before: `}</span>
                {campaignChange.value.valueBefore}
              </Typography>
              <Typography variant="small" sx={{ fontStyle: "italic" }} noWrap>
                <span css={{ fontWeight: 500 }}>{`After: `}</span>
                {campaignChange.value.valueAfter}
              </Typography>
            </Stack>
          </Stack>
        );
      }
      case "notes": {
        return (
          <Stack width="100%">
            <Typography variant="smallBold">{getFieldName()}</Typography>
            <Stack pl={1}>
              <Typography variant="small" sx={{ fontStyle: "italic" }} noWrap>
                <span css={{ fontWeight: 500 }}>{`Before: `}</span>
              </Typography>
              <Box
                bgcolor={(theme) => theme.colors.colorGray}
                p={0.2}
                border={(theme) =>
                  `${theme.metrics.borderWidth}px solid ${theme.colors.colorBorder}`
                }
              >
                <WYSIWYG
                  value={toJS(campaignChange.value.valueBefore)}
                  readonly
                />
              </Box>
              <Typography
                mt={1}
                variant="small"
                sx={{ fontStyle: "italic" }}
                noWrap
              >
                <span css={{ fontWeight: 500 }}>{`After: `}</span>
              </Typography>

              <Box
                bgcolor={(theme) => theme.colors.colorGray}
                p={0.2}
                border={(theme) =>
                  `${theme.metrics.borderWidth}px solid ${theme.colors.colorBorder}`
                }
              >
                <WYSIWYG
                  value={toJS(campaignChange.value.valueAfter)}
                  readonly
                />
              </Box>
            </Stack>
          </Stack>
        );
      }
      case "documents": {
        return (
          <Stack width="100%">
            <Typography variant="smallBold">{getFieldName()}</Typography>
            <Stack pl={1}>
              <Typography variant="small" sx={{ fontStyle: "italic" }} noWrap>
                <span css={{ fontWeight: 500 }}>{`Before: `}</span>
              </Typography>
              {campaignChange.value.valueBefore.map((doc) => {
                const url = doc.data.value;
                return (
                  <Link
                    ml={1}
                    key={`before-${url}`}
                    href={url}
                    target="_blank"
                    sx={{ textDecoration: "none" }}
                  >
                    {doc.name || url}
                  </Link>
                );
              })}
              <Typography variant="small" sx={{ fontStyle: "italic" }} noWrap>
                <span css={{ fontWeight: 500 }}>{`After: `}</span>
              </Typography>
              {campaignChange.value.valueAfter.map((doc) => {
                const url = doc.data.value;
                return (
                  <Link
                    ml={1}
                    key={`after-${url}`}
                    href={url}
                    target="_blank"
                    sx={{ textDecoration: "none" }}
                  >
                    {doc.name || url}
                  </Link>
                );
              })}
            </Stack>
          </Stack>
        );
      }
      default: {
        assertNever(campaignChange);
      }
    }
  };

  return (
    <List>
      {campaignDetailChanges.map((campaignChange) => (
        <ListItem key={campaignChange.kind}>
          {renderChange(campaignChange)}
        </ListItem>
      ))}
    </List>
  );
};
