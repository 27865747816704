import useResizeObserver from "@react-hook/resize-observer";
import React, { RefObject } from "react";

export const useSize = (
  target: RefObject<HTMLDivElement | HTMLSpanElement>
) => {
  const [size, setSize] = React.useState<DOMRect | null>(null);

  React.useLayoutEffect(() => {
    if (target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);

  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};
