import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";

export const AccordionStyled = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.colors.colorBorder}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export const AccordionSummaryStyled = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  padding: "0 10px",
  borderBottom: `1px dotted ${theme.colors.colorBorder}`,
}));

export const AccordionPlainStyled = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({}) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  backgroundColor: "inherit",
  "&:before": {
    display: "none",
  },
  "& .MuiButtonBase": { minHeight: 10 },
}));

export const AccordionPlainSummaryStyled = styled(
  (props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />
)(({}) => ({
  padding: 0,
  minHeight: 0,
  "& .MuiAccordionSummary-content": { margin: 0 },
}));
