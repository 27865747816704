import { SS_SUPPORT_EMAIL } from "src/constants/supportEmail";

export const helpMenuItems = [
  {
    label: "Help Centre",
    onClick: () => window.open("https://help.thespecsheet.com"),
  },
  {
    label: "Email TSS support",
    onClick: () => (location.href = `mailto: ${SS_SUPPORT_EMAIL}`),
  },
];
