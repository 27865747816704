import { Service } from "src/service/service";
import { UiStore } from "./UiStore";
import { action, makeObservable, observable } from "mobx";

export class NotificationCenterStore {
  selectedId: string | null = null;

  constructor(
    private readonly uiStore: UiStore,
    private readonly service: Service
  ) {
    this.uiStore = uiStore;
    this.service = service;

    makeObservable(this, {
      selectedId: observable,
      setSelectedId: action,
      clearSelection: action,
    });
  }

  setSelectedId = (notificationId: string | null) => {
    this.selectedId = notificationId;
  };

  clearSelection = () => {
    this.selectedId = null;
  };
}
