import React, { useRef } from "react";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { coreApiURL } from "../constants/url";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import { useAccessToken } from "../hooks/utils/useAccessToken";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from "crypto-hash";
import { RetryLink } from "@apollo/client/link/retry";

const httpLink = createHttpLink({
  uri: `${coreApiURL}/graphql`,
});

// this reduces the request payload by caching the graphql body server side
// and then only sending a hash instead on every request
const persistedQueriesLink = createPersistedQueryLink({
  sha256,
});

const link = new RetryLink();

export const GraphqlProvider: React.FC = ({ children }) => {
  const { getAccessToken } = useAccessToken();
  const { isAuthenticated } = useAuth0();

  const authLink = setContext(async (_, { headers }) => {
    const authorizationToken = isAuthenticated
      ? await getAccessToken()
      : undefined;
    return {
      headers: {
        ...headers,
        authorization: authorizationToken,
      },
    };
  });

  const client = useRef<ApolloClient<NormalizedCacheObject>>();
  if (!client.current) {
    client.current = new ApolloClient({
      // concat is an object function so this error makes no sense here
      // eslint-disable-next-line unicorn/prefer-spread
      link: authLink.concat(link).concat(persistedQueriesLink).concat(httpLink),
      cache: new InMemoryCache(),
    });
  }

  return <ApolloProvider client={client.current}>{children}</ApolloProvider>;
};
