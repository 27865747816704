import React from "react";
import { Dialog, ThemeProvider } from "@mui/material";
import { useDialogContext } from "src/hooks/contexts/useDialogContext";
import { DialogTitleStyled } from "src/ui/components/shared/mui-styled/Dialog";
import { theme as newTheme } from "src/ui/common/NewTheme";

export const DialogWithContext: React.FC = () => {
  const { dialogProps, onClose } = useDialogContext();

  return (
    <ThemeProvider theme={newTheme}>
      <Dialog
        fullWidth={dialogProps?.fullWidth}
        open={Boolean(dialogProps)}
        onClose={onClose}
        scroll="paper"
        maxWidth={dialogProps?.maxWidth}
        sx={dialogProps?.sx}
        disableEscapeKeyDown={dialogProps?.disableEscapeKeyDown}
      >
        {dialogProps?.title && (
          <>
            <DialogTitleStyled
              onClose={onClose}
              title={dialogProps.title}
              subTitle={dialogProps.subTitle}
            />
          </>
        )}
        {dialogProps && dialogProps.body}
      </Dialog>
    </ThemeProvider>
  );
};
