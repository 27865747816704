import { AccordionDetails, Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  AccordionStyled,
  AccordionSummaryStyled,
} from "src/ui/components/shared/mui-styled/Accordion";
import { BookingFieldUpdatesList } from "./BookingFieldUpdatesList";
import { ChangesSummaryList } from "./ChangesSummaryList";
import { PublicationUpdatesList } from "./PublicationUpdatesList";
import { CampaignDetailUpdatesList } from "./CampaignDetailUpdatesList";
import { N_CampaignChangesSummary } from "@specsheet-common/shared-types";
import { Id } from "../../shared/organisms/Table";

interface CampaignVersionChangesProps {
  changesSummary: N_CampaignChangesSummary;
  goToRowId: ((id: Id) => void) | null;
}

interface ChangeToRender {
  id:
    | "addedPlacements"
    | "removedPlacements"
    | "updatedPlacements"
    | "updatedPublications"
    | "updatedBookingFields"
    | "updatedCampaignDetails";
  title: string;
  numberOfChanges: number | null;
  details: JSX.Element | null;
}

export const CampaignVersionChanges = ({
  changesSummary,
  goToRowId,
}: CampaignVersionChangesProps) => {
  const [expanded, setExpanded] = useState<ChangeToRender["id"] | false>(false);

  const noChanges =
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    Object.values(changesSummary).reduce((acc, cur) => acc + cur.length, 0) ===
    0;

  const handleChange =
    (panel: ChangeToRender["id"]) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const changesInfoToRender: ChangeToRender[] = [
    {
      id: "updatedCampaignDetails",
      title: "Updated Campaign Details",
      numberOfChanges: changesSummary.campaignDetailsUpdated.length ? null : 0,
      details: (
        <CampaignDetailUpdatesList
          campaignDetailChanges={changesSummary.campaignDetailsUpdated}
        />
      ),
    },
    {
      id: "addedPlacements",
      title: "Added placements",
      numberOfChanges: changesSummary.bookingsAdded.length,
      details: (
        <ChangesSummaryList
          goToRowId={goToRowId}
          changeViews={changesSummary.bookingsAdded}
        />
      ),
    },
    {
      id: "removedPlacements",
      title: "Removed placements",
      numberOfChanges: changesSummary.bookingsRemoved.length,
      details: (
        <ChangesSummaryList changeViews={changesSummary.bookingsRemoved} />
      ),
    },
    {
      id: "updatedBookingFields",
      title: "Updated placements",
      numberOfChanges: changesSummary.bookingsUpdated.length,
      details: (
        <BookingFieldUpdatesList
          bookingFieldChanges={changesSummary.bookingsUpdated}
          goToRowId={goToRowId}
        />
      ),
    },
    {
      id: "updatedPublications",
      title: "Updated publications",
      numberOfChanges: changesSummary.bookingsPublicationsUpdated.length,
      details: (
        <PublicationUpdatesList
          pubChanges={changesSummary.bookingsPublicationsUpdated}
        />
      ),
    },
  ];

  return (
    <Box>
      {changesInfoToRender.map((change) => {
        if (change.numberOfChanges === 0) {
          return null;
        }
        return (
          <AccordionStyled
            key={change.id}
            expanded={expanded === change.id}
            onChange={handleChange(change.id)}
          >
            <AccordionSummaryStyled>
              <Stack direction="row" spacing={1}>
                <Typography variant="baseBold" flexShrink={0}>
                  {change.title}
                </Typography>
                {change.numberOfChanges && (
                  <Typography sx={{ color: "text.secondary" }} variant="base">
                    {`${change.numberOfChanges} changes`}
                  </Typography>
                )}
              </Stack>
            </AccordionSummaryStyled>
            <AccordionDetails sx={{ py: 0, px: 0.5 }}>
              {change.details}
            </AccordionDetails>
          </AccordionStyled>
        );
      })}

      {noChanges && <Box p={2}>No changes since the last version</Box>}
    </Box>
  );
};
