import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export const PencilSquareIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_833_10252)">
        <path d="M13.6919 2.00672C13.6919 1.89092 13.646 1.77985 13.5642 1.69784V1.69697L12.4328 0.565594C12.3508 0.483576 12.2395 0.4375 12.1235 0.4375C12.0075 0.4375 11.8963 0.483576 11.8142 0.565594L10.9016 1.47909L12.6516 3.22909L13.5642 2.31559C13.646 2.23359 13.6919 2.12251 13.6919 2.00672ZM10.283 2.09684L12.033 3.84684V3.84772L6.07159 9.80997C6.02335 9.85781 5.96463 9.89376 5.90009 9.91497L3.78784 10.6193C3.74933 10.6321 3.70803 10.6339 3.66855 10.6246C3.62907 10.6152 3.59297 10.5951 3.56428 10.5664C3.5356 10.5377 3.51545 10.5016 3.5061 10.4621C3.49675 10.4227 3.49857 10.3814 3.51134 10.3428L4.21571 8.23059C4.23718 8.16597 4.27343 8.10724 4.32159 8.05909L10.283 2.09684ZM1.25942 12.7406C1.01328 12.4944 0.875 12.1606 0.875 11.8125V2.1875C0.875 1.8394 1.01328 1.50556 1.25942 1.25942C1.50556 1.01328 1.8394 0.875 2.1875 0.875H7.875C7.99103 0.875 8.10231 0.921094 8.18436 1.00314C8.26641 1.08519 8.3125 1.19647 8.3125 1.3125C8.3125 1.42853 8.26641 1.53981 8.18436 1.62186C8.10231 1.70391 7.99103 1.75 7.875 1.75H2.1875C2.07147 1.75 1.96019 1.79609 1.87814 1.87814C1.79609 1.96019 1.75 2.07147 1.75 2.1875V11.8125C1.75 11.9285 1.79609 12.0398 1.87814 12.1219C1.96019 12.2039 2.07147 12.25 2.1875 12.25H11.8125C11.9285 12.25 12.0398 12.2039 12.1219 12.1219C12.2039 12.0398 12.25 11.9285 12.25 11.8125V6.5625C12.25 6.44647 12.2961 6.33519 12.3781 6.25314C12.4602 6.17109 12.5715 6.125 12.6875 6.125C12.8035 6.125 12.9148 6.17109 12.9969 6.25314C13.0789 6.33519 13.125 6.44647 13.125 6.5625V11.8125C13.125 12.1606 12.9867 12.4944 12.7406 12.7406C12.4944 12.9867 12.1606 13.125 11.8125 13.125H2.1875C1.8394 13.125 1.50556 12.9867 1.25942 12.7406Z" />
      </g>
      <defs>
        <clipPath id="clip0_833_10252">
          <rect width="14" height="14" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
