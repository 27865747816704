import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
} from "@mui/material";
import React from "react";
import { ArchiveWorkplace } from "./forms/ArchiveWorkplace";
import { CreateWorkplaceForm } from "./forms/CreateWorkplaceForm";
import { EditWorkplaceForm } from "./forms/EditWorkspaceForm";
import { WorkspaceView } from "src/adl-gen/kachemedia/specsheet/users";
import { assertNever } from "@specsheet-common/shared-tools";
import { idFlavor } from "@specsheet-common/shared-types";

interface WorkspaceCreateDialogProps {
  kind: "create";
}
interface WorkspaceEditDialogProps {
  kind: "edit";
  workspace: WorkspaceView;
}
interface WorkspaceArchiveDialogProps {
  kind: "archive";
  workspace: WorkspaceView;
}

export type WorkspaceControlDialogContext =
  | WorkspaceCreateDialogProps
  | WorkspaceEditDialogProps
  | WorkspaceArchiveDialogProps
  | null;

interface WorkspaceControlDialogProps {
  onClose(): void;
  isOpen: boolean;
  context: WorkspaceControlDialogContext;
  organisationId: idFlavor<"Organisation">;
  variant?: "default" | "newDashboard";
}

interface DialogProps extends MuiDialogProps {
  paperMaxWidth?: 480;
}

const Dialog = ({ paperMaxWidth, ...props }: DialogProps) => {
  return (
    <MuiDialog
      {...props}
      {...(paperMaxWidth
        ? {
            maxWidth: "xs",
            sx: { "& .MuiDialog-paperWidthXs": { maxWidth: paperMaxWidth } },
          }
        : {})}
    >
      {props.children}
    </MuiDialog>
  );
};

export const WorkspaceControlDialog = ({
  context,
  onClose,
  isOpen,
  organisationId,
  variant = "default",
}: WorkspaceControlDialogProps) => {
  if (!context) {
    return null;
  }

  const getDialogForm = () => {
    switch (context.kind) {
      case "create": {
        return (
          <CreateWorkplaceForm
            organisationId={organisationId}
            onClose={onClose}
            variant={variant}
          />
        );
      }
      case "edit": {
        return (
          <EditWorkplaceForm
            workspace={context.workspace}
            organisationId={organisationId}
            onClose={onClose}
            variant={variant}
          />
        );
      }
      case "archive": {
        return (
          <ArchiveWorkplace
            workspace={context.workspace}
            onClose={onClose}
            variant={variant}
          />
        );
      }
      default: {
        assertNever(context);
      }
    }
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={onClose}
      scroll="paper"
      paperMaxWidth={variant === "newDashboard" ? 480 : undefined}
    >
      {getDialogForm()}
    </Dialog>
  );
};
