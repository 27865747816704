import { MenuItemAction, SsMenuItemAction } from "./SsMenuItemAction";
import { Divider, ListItem, Typography } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";

export interface MenuItemGroup {
  kind: "group";
  icon?: JSX.Element;
  children: MenuItemAction[];
  label: string;
  disabled?: boolean;
}

export const SsMenuItemGroup = ({
  children,
  icon,
  label,
  disabled,
}: MenuItemGroup): JSX.Element => {
  return (
    <>
      <ListItem dense>
        {icon && <ListItemIcon sx={{ minWidth: "15px" }}>{icon}</ListItemIcon>}
        <ListItemText disableTypography>
          <Typography
            variant="subtitle3"
            color="gray"
            fontWeight={(theme) => theme.typography.fontWeightBold}
          >
            {label}
          </Typography>
        </ListItemText>
      </ListItem>
      <Divider />

      {children.map((ch) => (
        <SsMenuItemAction {...ch} disabled={ch.disabled || disabled} />
      ))}
    </>
  );
};
