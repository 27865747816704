import { colors } from "./colors";

export const palette = {
  primary: {
    main: colors.theme.primary,
    light: colors.theme.blue200,
    dark: colors.theme.primary,
    contrastText: colors.neutral.white,
  },
  secondary: {
    main: colors.neutral.gray200,
    contrastText: colors.neutral.white,
  },
  lightBlue: {
    main: colors.utility.blueLight,
    contrastText: colors.neutral.white,
  },
  gray: {
    main: colors.neutral.gray500,
    dark: colors.neutral.primary,
    light: colors.neutral.gray200,
    contrastText: colors.neutral.white,
  },
  lightGray: {
    main: colors.neutral.gray300,
    light: colors.neutral.gray200,
  },
  success: {
    main: colors.utility.green,
    contrastText: colors.neutral.white,
    dark: colors.utility.green,
    light: colors.utility.greenLight,
  },
  error: {
    main: colors.utility.red,
    light: colors.utility.redLight,
    dark: colors.utility.red,
    contrastText: colors.neutral.white,
  },
  text: {
    primary: colors.neutral.primary,
    secondary: colors.neutral.gray500,
  },
  green: {
    main: colors.utility.green,
    light: colors.utility.greenLight,
    dark: colors.utility.green,
    contrastText: colors.utility.green,
  },
  navy: {
    main: colors.theme.primary,
    contrastText: colors.neutral.white,
  },
  fadedBlue: {
    main: colors.theme.blue200,
    contrastText: colors.theme.primary,
  },
  purple: {
    main: colors.utility.purple,
    light: colors.utility.purpleLight,
    dark: colors.utility.purple,
    contrastText: colors.neutral.white,
  },
  turquoise: {
    main: colors.utility.turquoise,
    light: colors.utility.turquoiseLight,
    dark: colors.utility.turquoise,
    contrastText: colors.neutral.white,
  },
  pink: {
    main: colors.utility.pink,
    light: colors.utility.pinkLight,
    dark: colors.utility.pink,
    contrastText: colors.neutral.white,
  },
  yellow: {
    main: colors.utility.yellow,
    light: colors.utility.yellowLight,
    dark: colors.utility.yellow,
    contrastText: colors.neutral.white,
  },
  neutral: {
    main: colors.neutral.primary,
    light: colors.neutral.gray200,
    dark: colors.neutral.primary,
    contrastText: colors.neutral.white,
  },
  blue: {
    main: colors.theme.blue500,
    light: colors.theme.blue200,
    dark: colors.theme.blue500,
    contrastText: colors.neutral.white,
  },
  azure: {
    main: colors.utility.azure,
    light: colors.utility.azureLight,
    dark: colors.utility.azure,
    contrastText: colors.neutral.white,
  },
  orange: {
    main: colors.utility.orange,
    light: colors.utility.orangeLight,
    dark: colors.utility.orange,
    contrastText: colors.neutral.white,
  },
  neutralGray: {
    main: colors.neutral.gray100,
    light: colors.neutral.gray200,
    dark: colors.neutral.gray100,
    contrastText: colors.neutral.primary,
  },
};
