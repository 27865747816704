import { HttpFetch, HttpRequest, HttpResponse } from "./http";
import * as datadog from "src/util/datadog";
import { R } from "@specsheet-common/shared-tools";

/**
 * An http service wrapper that retries get and put requests with exponential backoff
 */
export class HttpWithRetries implements HttpFetch {
  constructor(
    readonly http: HttpFetch,
    readonly maxRetries: number,
    readonly firstDelayMs: number
  ) {}

  async fetch(request: HttpRequest): Promise<HttpResponse> {
    if (request.method === "get" || request.method === "put") {
      return this.fetchWithRetries(request);
    }
    return this.http.fetch(request);
  }

  async fetchWithRetries(request: HttpRequest): Promise<HttpResponse> {
    let retries = this.maxRetries;
    let delayMs = this.firstDelayMs;
    while (true) {
      const resp = await this.http.fetch(request);

      if (this.is5xxOr0(resp.status)) {
        if (retries === 0) {
          datadog.error(
            new Error("fetchWithRetries failed"),
            Object.assign(
              R.pick(request, ["method", "url"]),
              R.pick(resp, ["status"]),
              {
                maxRetries: this.maxRetries,
              }
            )
          );
          return resp;
        } else {
          await sleep(delayMs * (0.5 + 0.5 * Math.random()));
          retries = retries - 1;
          delayMs = delayMs * 1.5;
        }
      } else {
        return resp;
      }
    }
  }

  is5xxOr0(c: number): boolean {
    // zero means network error
    return c === 0 || (c >= 500 && c < 600);
  }
}

function sleep(millis: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, millis);
  });
}
