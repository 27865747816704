import { useQueryClient } from "react-query";
import { sleep } from "@specsheet-common/shared-tools";
import {
  coreRoutes,
  idFlavor,
  UpdateWorkspaceReq,
  UpdateWorkspaceRes,
  urlBuilder,
  workspaceSubRoute,
} from "@specsheet-common/shared-types";
import { queryKeys } from "src/service/queryHooks/keys";
import { useMutation } from "src/service/reactQuery";

interface UseUpdateWorkspaceProps {
  organisationId: idFlavor<"Organisation">;
  onSuccess?(): void;
  onError?(): void;
}

export const useUpdateWorkspace = ({
  organisationId,
  onError,
  onSuccess,
}: UseUpdateWorkspaceProps) => {
  const queryClient = useQueryClient();
  return useMutation<UpdateWorkspaceReq, UpdateWorkspaceRes>({
    url: urlBuilder({
      root: coreRoutes.workspaces,
      sub: workspaceSubRoute.update,
    }),
    async responseFormatter(data: UpdateWorkspaceRes) {
      //TODO replace this hack to avoid picture being unavailable after immediately
      await sleep(500);
      return data;
    },
    method: "PATCH",
    successMessage: "Workspace successfully updated",
    onError,
    onSuccess: () => {
      void queryClient.invalidateQueries(
        queryKeys.workspaces.fetchWorkspaces({
          organisationId,
          includeArchived: false,
          calculateStats: false,
        })
      );
      void queryClient.invalidateQueries(
        queryKeys.orgUsers.fetchOrgUsers(organisationId)
      );
      onSuccess?.();
    },
  });
};
