import { useEffect } from "react";
import { bucketKind } from "@specsheet-common/shared-types";
import { environment } from "./constants/url";
import { getFullBucketPath } from "@specsheet-common/shared-tools";

const setResourceLink = (selector: string, url: string) => {
  const resource = document.querySelector<HTMLLinkElement>(selector);
  if (resource) {
    resource.href = url;
  }
};

export const useFavicons = () => {
  useEffect(() => {
    const baseUrl = getFullBucketPath({
      kind: bucketKind.CORE_CLIENT,
      bucketPrefix: environment,
    });

    setResourceLink(
      "link[rel~='icon'][rel~='shortcut']",
      `${baseUrl}/favicon/favicon.ico`
    );
    setResourceLink(
      "link[rel~='icon'][sizes~='32x32']",
      `${baseUrl}/favicon/favicon-32x32.png`
    );
    setResourceLink(
      "link[rel~='icon'][sizes~='16x16']",
      `${baseUrl}/favicon/favicon-16x16.png`
    );
    setResourceLink(
      "link[rel~='apple-touch-icon']",
      `${baseUrl}/favicon/apple-touch-icon.png`
    );
  }, []);
};
