import { uploadFile, z } from "@specsheet-common/shared-tools";
import { legacyApiURL } from "src/constants/url";
import { useAccessToken } from "src/hooks/utils/useAccessToken";
import { makeUrlResource } from "../adl-gen/kachemedia/specsheet/common";
import {
  PresignedUrlReq,
  PresignedUrlResp,
} from "../adl-gen/kachemedia/specsheet/requests";

const presignedUrlResp = z.object({
  fileName: z.string(),
  url: z.string().url(),
});

export const useGenerateS3PresignedUrl = () => {
  const { getAccessToken } = useAccessToken();

  const getS3PresignedUrl = async (
    req: PresignedUrlReq
  ): Promise<PresignedUrlResp> => {
    const url = `${legacyApiURL}/_a/upload/presigned/url`;
    const authToken = await getAccessToken();

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        public: req.public,
        fileName: req.fileName,
      }),
      headers: {
        "X-Auth-Token": authToken,
      },
    });

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const responseJson = await response.json();
    return presignedUrlResp.parse(responseJson);
  };

  return getS3PresignedUrl;
};

/**
 * Generates a presigned URL for the given file and uploads file
 * using the presigned URL.
 */

export const presignedUrlUpload = async ({
  file,
  presignedUrl,
}: {
  file: File;
  presignedUrl: PresignedUrlResp;
}): Promise<string> => {
  await uploadFile(presignedUrl.url, file);
  return presignedUrl.fileName;
};

export const useUploadFileReturnUrlResource = () => {
  const generateUrl = useGenerateS3PresignedUrl();

  return async ({
    file,
    fileName,
    isPrivate,
    notes,
  }: {
    file: File;
    fileName: string;
    isPrivate: boolean;
    notes?: string;
  }) => {
    const fileParts = fileName.split(".");
    const relativeUrl = await presignedUrlUpload({
      file,
      presignedUrl: await generateUrl({
        fileName: fileParts.length === 2 ? fileParts[0] : fileName,
        public: !isPrivate,
      }),
    });

    return makeUrlResource({
      name: file.name,
      data: {
        kind: "url",
        value: relativeUrl,
      },
      notes: notes || null,
      fileSize: file.size,
    });
  };
};
