import {
  Box,
  Button,
  Divider,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import {
  N_NotificationView,
  ORGANISATION_PERMISSIONS,
  orgMemberRole,
  routePaths,
} from "@specsheet-common/shared-types";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SS_HELP_WEBSITE } from "src/constants/externalLinks";
import { useAuth } from "src/hooks/contexts/useAuth";
import { useDialogContext } from "src/hooks/contexts/useDialogContext";
import { useQueryNotifications } from "src/service/queryHooks/notifications/queries/useQueryNotifications";
import { theme as newTheme } from "src/ui/common/NewTheme";
import { NotificationsPopover } from "src/ui/components/notifications/NotificationsPopover";
import { LogOutDialog } from "../auth/logOutDialog";
import {
  ButtonPlusIcon,
  MenuCollapseIcon,
  MenuExpandIcon,
  MenuHelpIcon,
  MenuLivechatIcon,
  MenuNotificationsIcon,
  MenuShareIcon,
  MenuWhatsnewIcon,
} from "../shared/atoms/icon/icons";
import { MenuAvatar } from "./Avatar/MenuAvatar";
import { MenuLogoIcon } from "./Logo/MenuLogoIcon";
import { MenuButton } from "./MenuItems/MenuButton";
import { MenuInlineText } from "./MenuItems/MenuInlineText";
import { OrganisationList } from "./Organisations/OrganisationList";
import { AddWorkspace } from "./Workspaces/AddWorkspace";
import { WorkspaceList } from "./Workspaces/WorkspaceList";
import { ContactSalesDialog } from "../campaignUsers/dialogs/ContactSalesDialog";
import { CalendlyDialog } from "../campaignUsers/dialogs/CalendlyDialog";
import { MenuContext } from "./MenuContext";

interface MenuProps {
  isExpanded: boolean;
  expanderFunction(): void;
}

export const SpecsheetMenu = ({ isExpanded, expanderFunction }: MenuProps) => {
  const { profile, organisation, workspace, allOrgs } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { onOpen: onDialogOpen, onClose: onDialogClose } = useDialogContext();

  const [contextOpen, setContextOpen] = useState<boolean>(false);
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLDivElement | null>(
    null
  );

  const [headwayWidgetReady, setHeadwayWidgetReady] = useState<boolean>(false);

  const handleNavToSharedDashboard = () => {
    navigate(routePaths.sharedCampaigns);
  };

  const popoverPositionAnchor = React.useRef<HTMLDivElement>(null);

  const handleLaunchNotificationsPopover = () => {
    setPopoverAnchor(popoverPositionAnchor.current);
  };

  const handleNavToHelpWebsite = () => {
    window.open(SS_HELP_WEBSITE);
  };

  const handleLogout = () => {
    onDialogOpen({
      body: <LogOutDialog onClose={onDialogClose} />,
      title: "Log Out",
    });
  };

  const handleAddOrg = () => {
    onDialogOpen({
      title: "Contact Sales",
      body: (
        <ContactSalesDialog
          chatButtonProps={{ id: "ss-intercom-launcher" }}
          onClose={onDialogClose}
          onBookCall={() =>
            onDialogOpen({
              title: "Contact Sales",
              body: <CalendlyDialog onClose={onDialogClose} />,
              maxWidth: "md",
              fullWidth: true,
            })
          }
        />
      ),
      maxWidth: "md",
      fullWidth: true,
    });
    // /* TODO: Nav to Create Org when merged */
    // window.open("mailto:sales@thespecsheet.com?subject=Sign me up Scotty");
  };

  const query = useQueryNotifications({
    staleTime: Number.POSITIVE_INFINITY,
  });

  const notifications = query.data ?? [];
  const [unreadNotifications] = notifications.reduce<N_NotificationView[][]>(
    ([unread, read], n) => {
      if (n.isRead) {
        return [unread, [...read, n]];
      }
      return [[...unread, n], read];
    },
    [[], []]
  );
  const newNotificationsAmount = unreadNotifications.length;
  // External popover for release notes, see: https://headwayapp.co/
  React.useEffect(() => {
    if (headwayWidgetReady) {
      return;
    }
    /* @ts-expect-error headway provided by import of headway in index.ts */
    window.Headway?.init({
      selector: "#ss-headway-selector",
      account: "72Qaex",
      trigger: "#ss-headway-trigger",
      callbacks: {
        onWidgetReady: () => {
          setHeadwayWidgetReady(true);
        },
      },
    });
  }, [headwayWidgetReady]);

  const headwaySelector = (
    <Grid item xs="auto">
      <Box
        sx={{ width: "32px", overflow: "hidden" }}
        id={"ss-headway-selector"}
      ></Box>
    </Grid>
  );

  const youAreHereSharedDash =
    location.pathname.slice(0, routePaths.shared.length) === routePaths.shared;

  return (
    <MenuContext.Provider
      value={{
        isExpanded: isExpanded,
        selectedOrganisation: organisation.id,
        selectedWorkspace: workspace.id,
      }}
    >
      <ThemeProvider theme={newTheme}>
        <Box
          data-pw="side-panel"
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: isExpanded ? "256px" : "64px",
            padding: newTheme.spacing(2),
            paddingBottom: newTheme.spacing(4),
          }}
        >
          <Grid
            container
            direction="column"
            item
            sx={{ height: "100%" }}
            wrap="nowrap"
          >
            <Grid
              item
              xs
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Box>
                <Box sx={{ padding: newTheme.spacing(2) }}>
                  <Grid container>
                    {isExpanded && (
                      <>
                        <Grid item xs="auto">
                          <Box sx={{ height: "32px", width: "32px" }}></Box>
                        </Grid>
                        <Grid
                          item
                          xs
                          onClick={() => {
                            navigate(routePaths.dashboard);
                          }}
                          sx={{ cursor: "pointer" }}
                        >
                          <MenuLogoIcon />
                        </Grid>
                      </>
                    )}
                    <Grid item xs="auto">
                      <Box
                        sx={{
                          height: "32px",
                          width: "32px",
                          padding: newTheme.spacing(1),
                        }}
                      >
                        {isExpanded ? (
                          <MenuCollapseIcon
                            title="Collapse side panel"
                            size="24px"
                            onClick={expanderFunction}
                          />
                        ) : (
                          <MenuExpandIcon
                            title="Expand side panel"
                            size="24px"
                            onClick={expanderFunction}
                          />
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    height: "18px",
                    paddingTop: isExpanded ? "0px" : "9px",
                  }}
                >
                  <Divider sx={{ backgroundColor: "white" }}>
                    {isExpanded ? (
                      <Typography variant="body3" sx={{ color: "lightgray" }}>
                        Organisation
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Divider>
                </Box>
                {organisation.hidden ||
                !allOrgs.some((o) => o.role !== orgMemberRole.GUEST) ? (
                  <Box sx={{ padding: "11px" }}>
                    <Button
                      onClick={handleAddOrg}
                      color="gray"
                      sx={{ padding: "4px", width: "100%", minWidth: "26px" }}
                    >
                      {isExpanded && (
                        <Typography variant="body3">
                          Create Organisation
                        </Typography>
                      )}
                      <div
                        style={{
                          marginLeft: isExpanded ? "8px" : "0px",
                          marginTop: "-1px",
                          marginBottom: "-5px",
                        }}
                      >
                        <ButtonPlusIcon size="16px" />
                      </div>
                    </Button>
                  </Box>
                ) : (
                  <>
                    <OrganisationList
                      expanded={isExpanded}
                      onContextOpen={() => setContextOpen(true)}
                      onContextClose={() => setContextOpen(false)}
                    />
                    <Box
                      sx={{
                        height: "18px",
                        paddingTop: isExpanded ? "0px" : "9px",
                      }}
                    >
                      <Divider sx={{ backgroundColor: "white" }}>
                        {isExpanded ? (
                          <Typography
                            variant="body3"
                            sx={{ color: "lightgray" }}
                          >
                            Workspaces
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Divider>
                    </Box>
                    <Box
                      sx={{
                        height: contextOpen ? "calc(100vh - 555px)" : "auto",
                        maxHeight: "calc(100vh - 555px)",
                        overflowY: "auto",
                      }}
                    >
                      <WorkspaceList expanded={isExpanded} />
                      {organisation.permissions.has(
                        ORGANISATION_PERMISSIONS.WORKSPACE_CREATE
                      ) && <AddWorkspace expanded={isExpanded} />}
                    </Box>
                  </>
                )}
                <Box sx={{ height: "18px", paddingTop: "9px" }}>
                  <Divider />
                </Box>
                <Box title={"Enter Shared With Me"}>
                  <MenuButton
                    expanded={isExpanded}
                    icon={<MenuShareIcon size="24px" />}
                    name={"Shared with me "}
                    onClick={handleNavToSharedDashboard}
                    isActiveState={youAreHereSharedDash}
                  />
                </Box>
                <MenuInlineText
                  expanded={isExpanded}
                  text="You are collaborating on these campaigns as a guest outside the organisations of which you are a member."
                />
              </Box>
            </Grid>
            <Grid
              item
              xs
              container
              direction="column"
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Box sx={{ height: "18px", paddingTop: "9px" }}>
                <Divider />
              </Box>
              <Box>
                <MenuButton
                  expanded={isExpanded}
                  icon={<MenuNotificationsIcon size="24px" />}
                  name={"Notifications"}
                  onClick={handleLaunchNotificationsPopover}
                  alertCount={newNotificationsAmount}
                  typographyVariant="body2"
                />
                <MenuButton
                  expanded={isExpanded}
                  icon={<MenuLivechatIcon size="24px" />}
                  name={"Live Chat"}
                  id={"ss-intercom-launcher"}
                  typographyVariant="body2"
                />
                <MenuButton
                  expanded={isExpanded}
                  icon={<MenuWhatsnewIcon size="24px" />}
                  name={"What's New"}
                  id={"ss-headway-trigger"}
                  typographyVariant="body2"
                >
                  {headwaySelector}
                </MenuButton>
                <MenuButton
                  expanded={isExpanded}
                  icon={<MenuHelpIcon size="24px" />}
                  name={"Help Centre"}
                  onClick={handleNavToHelpWebsite}
                  typographyVariant="body2"
                />
                <MenuAvatar
                  expanded={isExpanded}
                  userProfile={profile}
                  handleLogout={handleLogout}
                />
              </Box>
            </Grid>
          </Grid>
          <NotificationsPopover
            anchorElement={popoverAnchor}
            handleClose={() => setPopoverAnchor(null)}
          />
          <Box ref={popoverPositionAnchor}></Box>
        </Box>
      </ThemeProvider>
    </MenuContext.Provider>
  );
};
