// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/require-await,@typescript-eslint/no-unused-vars */
import { GetAccessToken } from "src/hooks/utils/useAccessToken";
import { Paginated, Unit } from "../adl-gen/common";
import { DbKey, WithDbId } from "../adl-gen/common/db";
import { StringNE } from "../adl-gen/common/strings";
import {
  ClientView,
  MoveCampaignToNewWorkspaceReq,
  PlacementView,
} from "../adl-gen/kachemedia/specsheet/campaigns";
import {
  ChangeClientIsActiveStatus,
  UpdateClientReq,
} from "../adl-gen/kachemedia/specsheet/clients";
import {
  AppUser,
  Client,
  Organisation,
  Placement,
  Publisher,
  WorkspaceMember,
} from "../adl-gen/kachemedia/specsheet/db";
import {
  AddCustomBookingReq,
  ClientReq,
  CreateUserReqOpsManager,
  ImportPlacementsReq,
  ImportPlacementsResp,
  OpsCreateNewOrg,
  PresignedUrlReq,
  PresignedUrlResp,
  QueryClientsReq,
  QueryPublishersReq,
  SearchPlacementsWithFiltersReq,
  SuccessOrError,
  UpdateCampaignIsArchivedStatusReq,
  UpdateCampaignSharesReq,
  UpdateCampaignSharesResp,
} from "../adl-gen/kachemedia/specsheet/requests";
import {
  UpdateSelfReq,
  UpdateWorkspaceMemberUserGroupsReq,
  UserAccessProfile,
} from "../adl-gen/kachemedia/specsheet/users";
import { DeclResolver } from "../adl-gen/runtime/adl";
import { Result } from "../adl-gen/sys/types";
import { HttpFetch } from "./http";
import { HttpServiceBase, PostFn } from "./http-service-base";
import { HttpServiceError } from "./http-service-error";
import { Service } from "./service";

export class HttpService extends HttpServiceBase implements Service {
  postMoveCampaign: PostFn<
    MoveCampaignToNewWorkspaceReq,
    Result<string, string>
  >;
  postUpdateCampaignIsArchivedStatus: PostFn<
    UpdateCampaignIsArchivedStatusReq,
    Unit
  >;
  postCreateClient: PostFn<ClientReq, DbKey<Client>>;
  postUpdateClient: PostFn<UpdateClientReq, Result<DbKey<Client>, StringNE>>;
  postChangeClientIsActiveStatus: PostFn<
    ChangeClientIsActiveStatus,
    Result<DbKey<Client>, StringNE>
  >;
  postQueryClients: PostFn<QueryClientsReq, ClientView[]>;
  postQueryPublishers: PostFn<
    QueryPublishersReq,
    Paginated<WithDbId<Publisher>>
  >;
  postFilterSearchPlacements: PostFn<
    SearchPlacementsWithFiltersReq,
    Paginated<PlacementView>
  >;
  postQueryPlacement: PostFn<DbKey<Placement>, Result<PlacementView, Unit>>;
  postGetS3PresignedUrl: PostFn<PresignedUrlReq, PresignedUrlResp>;
  postOpsCreateUser: PostFn<CreateUserReqOpsManager, DbKey<AppUser>>;
  postOpsCreateOrganisation: PostFn<OpsCreateNewOrg, DbKey<Organisation>>;
  postUpdateSelf: PostFn<UpdateSelfReq, Result<UserAccessProfile, StringNE>>;
  postUpdateWorkspaceMemberUserGroups: PostFn<
    UpdateWorkspaceMemberUserGroupsReq,
    Result<DbKey<WorkspaceMember>, StringNE>
  >;
  postAddCustomBooking: PostFn<AddCustomBookingReq, DbKey<Placement>>;
  postUpdateCampaignShares: PostFn<
    UpdateCampaignSharesReq,
    UpdateCampaignSharesResp
  >;
  postImportPlacements: PostFn<ImportPlacementsReq, ImportPlacementsResp>;
  postActualImportPlacements: PostFn<ImportPlacementsResp, SuccessOrError>;
  constructor(
    /** Fetcher over HTTP */
    http: HttpFetch,
    /** Base URL of the API endpoints */
    baseUrl: string,
    /** Resolver for ADL types */
    resolver: DeclResolver,
    /** Token manager storing the authentication tokens */
    getAccessToken: GetAccessToken["getAccessToken"],
    /** Error handler to allow for cross cutting concerns, e.g. authorization errors */
    handleError: (error: HttpServiceError) => void
  ) {
    super(http, baseUrl, resolver, getAccessToken, handleError);
    this.postMoveCampaign = this.mkPostFn(this.requests.moveCampaign);
    this.postUpdateCampaignIsArchivedStatus = this.mkPostFn(
      this.requests.updateCampaignIsArchivedStatus
    );
    this.postCreateClient = this.mkPostFn(this.requests.createClient);
    this.postUpdateClient = this.mkPostFn(this.requests.updateClient);
    this.postChangeClientIsActiveStatus = this.mkPostFn(
      this.requests.changeClientIsActiveStatus
    );
    this.postQueryClients = this.mkPostFn(this.requests.queryClients);
    this.postQueryPublishers = this.mkPostFn(this.requests.queryPublishers);
    this.postFilterSearchPlacements = this.mkPostFn(
      this.requests.filterSearchPlacements
    );
    this.postQueryPlacement = this.mkPostFn(this.requests.queryPlacement);
    this.postGetS3PresignedUrl = this.mkPostFn(this.requests.getS3PresignedUrl);
    this.postOpsCreateUser = this.mkPostFn(this.requests.opsCreateUser);
    this.postOpsCreateOrganisation = this.mkPostFn(
      this.requests.opsCreateOrganisation
    );
    this.postUpdateSelf = this.mkPostFn(this.requests.updateSelf);
    this.postUpdateWorkspaceMemberUserGroups = this.mkPostFn(
      this.requests.updateWorkspaceMemberUserGroups
    );
    this.postAddCustomBooking = this.mkPostFn(this.requests.addCustomBooking);

    this.postUpdateCampaignShares = this.mkPostFn(
      this.requests.updateCampaignShares
    );

    this.postImportPlacements = this.mkPostFn(this.requests.importPlacements);
    this.postActualImportPlacements = this.mkPostFn(
      this.requests.actualImportPlacements
    );
  }

  /** Duplicate campaign */
  async moveCampaign(
    req: MoveCampaignToNewWorkspaceReq
  ): Promise<Result<string, string>> {
    return this.postMoveCampaign.call(req);
  }

  /** Query Campaigns created between two dates Update an existing campaign isArchived status */
  async updateCampaignIsArchivedStatus(
    req: UpdateCampaignIsArchivedStatusReq
  ): Promise<Unit> {
    return this.postUpdateCampaignIsArchivedStatus.call(req);
  }

  /** Create a new client for a workspace */
  async createClient(req: ClientReq): Promise<DbKey<Client>> {
    return this.postCreateClient.call(req);
  }

  async updateClient(
    req: UpdateClientReq
  ): Promise<Result<DbKey<Client>, StringNE>> {
    return this.postUpdateClient.call(req);
  }

  async changeClientIsActiveStatus(
    req: ChangeClientIsActiveStatus
  ): Promise<Result<DbKey<Client>, StringNE>> {
    return this.postChangeClientIsActiveStatus.call(req);
  }

  /** Query existing clients */
  async queryClients(req: QueryClientsReq): Promise<ClientView[]> {
    return this.postQueryClients.call(req);
  }

  /** Query for publishers */
  async queryPublishers(
    req: QueryPublishersReq
  ): Promise<Paginated<WithDbId<Publisher>>> {
    return this.postQueryPublishers.call(req);
  }

  /** Text search for placement with query filter */
  async filterSearchPlacements(
    req: SearchPlacementsWithFiltersReq
  ): Promise<Paginated<PlacementView>> {
    return this.postFilterSearchPlacements.call(req);
  }

  /** Query for a placement */
  async queryPlacement(
    req: DbKey<Placement>
  ): Promise<Result<PlacementView, Unit>> {
    return this.postQueryPlacement.call(req);
  }

  /** Retrieves a presigned url to upload to S3 */
  async getS3PresignedUrl(req: PresignedUrlReq): Promise<PresignedUrlResp> {
    return this.postGetS3PresignedUrl.call(req);
  }

  /** Create a new user as Ops Manager */
  async opsCreateUser(req: CreateUserReqOpsManager): Promise<DbKey<AppUser>> {
    return this.postOpsCreateUser.call(req);
  }

  /** Create a new Organisation as Ops Manager */
  async opsCreateOrganisation(
    req: OpsCreateNewOrg
  ): Promise<DbKey<Organisation>> {
    return this.postOpsCreateOrganisation.call(req);
  }

  async updateSelf(
    req: UpdateSelfReq
  ): Promise<Result<UserAccessProfile, StringNE>> {
    return this.postUpdateSelf.call(req);
  }

  async updateWorkspaceMemberUserGroups(
    req: UpdateWorkspaceMemberUserGroupsReq
  ): Promise<Result<DbKey<WorkspaceMember>, StringNE>> {
    return this.postUpdateWorkspaceMemberUserGroups.call(req);
  }

  /** Adds a custom booking to a campaign. */
  async addCustomBooking(req: AddCustomBookingReq): Promise<DbKey<Placement>> {
    return this.postAddCustomBooking.call(req);
  }

  /** Updates the emails who are receiving campaign email updates to this campaign. */
  async updateCampaignShares(
    req: UpdateCampaignSharesReq
  ): Promise<UpdateCampaignSharesResp> {
    return this.postUpdateCampaignShares.call(req);
  }

  /** Request to process (a previously uploaded sheet) for importing placements */
  async importPlacements(
    req: ImportPlacementsReq
  ): Promise<ImportPlacementsResp> {
    return this.postImportPlacements.call(req);
  }

  /** Request to process (a previously uploaded sheet) for importing placements */
  async actualImportPlacements(
    req: ImportPlacementsResp
  ): Promise<SuccessOrError> {
    return this.postActualImportPlacements.call(req);
  }
}
