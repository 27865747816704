import { UseQueryOptions } from "react-query";
import {
  coreRoutes,
  N_OrganisationView,
  urlBuilder,
  organisationSubRoute,
} from "@specsheet-common/shared-types";
import { queryKeys } from "src/service/queryHooks/keys";
import { useAuth } from "src/hooks/contexts/useAuth";
import { useQuery } from "src/service/reactQuery";

interface HookProps {
  staleTime?: UseQueryOptions["staleTime"];
}

export const useQueryOrganisations = (props?: HookProps) => {
  const { isAuthenticated } = useAuth();
  return useQuery<never, N_OrganisationView[]>({
    queryKey: queryKeys.organisations.fetchOrganisations,
    url: urlBuilder({
      root: coreRoutes.organisations,
      sub: organisationSubRoute.list,
    }),
    method: "post",
    responseFormatter(data: N_OrganisationView[]) {
      return data.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
    },
    enabled: isAuthenticated,
    staleTime: props?.staleTime,
  });
};
