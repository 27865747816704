import { useContext } from "react";
import { AnalyticsContext } from "src/contexts/AnalyticsContext";

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error("No analytics context provided");
  }

  return context;
};
