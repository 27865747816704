import {
  idFlavor,
  NotificationByCategoryViewData,
  notificationCategory,
  routePaths,
} from "@specsheet-common/shared-types";
import { assertNever } from "@specsheet-common/shared-tools";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../hooks/contexts/useAuth";
import { useLocationState } from "src/hooks/utils/useLocationState";

const DEFAULT_TITLE = "View Your Spec Sheet Now";

export const useCtaLinkData = (
  notificationData: NotificationByCategoryViewData
): { label: string; onClick(): void } | undefined => {
  const navigate = useNavigate();
  const { workspace } = useAuth();
  const { changeWorkspace } = useLocationState();

  const changeWorkspaceIfNecessary = (
    toChangeWorkspaceId: idFlavor<"Workspace">
  ) => {
    if (toChangeWorkspaceId === workspace.id) {
      return;
    }
    changeWorkspace(routePaths.campaignBuild, toChangeWorkspaceId);
  };

  const navigateToBuildPage = ({
    campaignId,
    workspaceId,
  }: {
    campaignId: idFlavor<"Campaign">;
    workspaceId: idFlavor<"Workspace">;
  }) => {
    changeWorkspaceIfNecessary(workspaceId);
    navigate(`${routePaths.campaignBuild}/${campaignId}`);
  };

  switch (notificationData.category) {
    case notificationCategory.EXTERNAL_NEW:
    case notificationCategory.EXTERNAL: {
      return {
        label: DEFAULT_TITLE,
        onClick: () => {
          navigateToBuildPage({
            workspaceId: notificationData.workspaceId,
            campaignId: notificationData.campaignId,
          });
        },
      };
    }
    case notificationCategory.TEAM_MEMBER_ADDED: {
      return {
        label: DEFAULT_TITLE,
        onClick: () => {
          navigateToBuildPage({
            workspaceId: notificationData.workspaceId,
            campaignId: notificationData.campaignId,
          });
        },
      };
    }
    case notificationCategory.TEAM_MEMBER_REMOVED: {
      return undefined;
    }
    case notificationCategory.CAMPAIGN_SHARED: {
      return {
        label: DEFAULT_TITLE,
        onClick: () => {
          navigateToBuildPage({
            workspaceId: notificationData.workspaceId,
            campaignId: notificationData.campaignId,
          });
        },
      };
    }
    case notificationCategory.ANNOUNCEMENT: {
      const campaignId = notificationData.campaignId;
      const workspaceId = notificationData.workspaceId;

      if (campaignId && workspaceId) {
        return {
          label: DEFAULT_TITLE,
          onClick: () => {
            navigateToBuildPage({
              workspaceId,
              campaignId,
            });
          },
        };
      }

      return undefined;
    }
    default: {
      assertNever(notificationData);
    }
  }
};
