import { Components, Theme } from "@mui/material";

export const MuiFormControlLabel: Components<Theme>["MuiFormControlLabel"] = {
  styleOverrides: {
    root: { transformOrigin: "center" },
    label: ({ theme }) => ({
      color: theme.newColors.neutral.gray500,
      ...theme.typography.body3,
    }),
  },
};
