import { Components, Theme } from "@mui/material";

export const MuiChip: Components<Theme>["MuiChip"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.subtitle3,
      height: theme.spacing(6),
      padding: theme.spacing(1),
      borderColor: theme.newColors.neutral.gray200,
      "& .MuiChip-deleteIcon": {
        fontSize: theme.typography.subtitle1.fontSize,
        margin: 0,
      },
      "& .MuiChip-label": {
        padding: 0,
        margin: theme.spacing(0, 1),
      },
      "& .MuiChip-deleteIcon:hover": {
        fontSize: theme.typography.subtitle2.fontSize,
        margin: theme.spacing(0, 0.25),
        backgroundColor: theme.newColors.neutral.white,
        borderRadius: "50%",
      },
      "& .MuiChip-outlined": {
        background: "red",
      },
    }),
    label: ({ theme }) => ({
      padding: theme.spacing(1, 2),
    }),
    labelSmall: ({ theme }) => ({
      padding: theme.spacing(1, 1),
    }),
    sizeSmall: ({ theme }) => ({
      height: theme.spacing(4),
    }),
  },
};
