import React from "react";
import { Controller, FieldValues, Path } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import { ImgUpload } from "src/ui/components/shared/molecules/ImgUpload";

interface FormImgUploadControlProps<ControlDataType extends FieldValues> {
  control: Control<ControlDataType>;
  name: Path<ControlDataType>;
  currentSrc?: string | null;
  variant?: "default" | "newDashboard";
}

export const FormImgUploadControl = <ControlDataType extends FieldValues>({
  control,
  name,
  currentSrc,
  variant = "default",
}: FormImgUploadControlProps<ControlDataType>) => {
  return (
    <Controller
      render={({ field: { value, onChange } }) => {
        return (
          <ImgUpload
            currentSrc={currentSrc}
            currentFile={value}
            onUpdate={onChange}
            onRemove={() => onChange("empty")}
            variant={variant}
          />
        );
      }}
      name={name}
      control={control}
    />
  );
};
